import apiRequest from "@/configs/axiosConfig";
import { drivingFeesTypes } from "@/redux/reducers/drivingFees";

export const fetchSelectOptionCustomerList = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/point/box',
      rootState: getState(),
      dispatch,
    })
      .then(res => res.data.response)
  }
}

export const fetchGoodsList = (params) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/goods',
      rootState: getState(),
      dispatch,
    })
      .then(res => res.data.response)
  }
}

export const addNewDrivingFee = ({upPoint, downPoint, itemProduct, price, operationBaseDate, client}) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/operation/price',
      data: { upPoint, downPoint, itemProduct, price, operationBaseDate, client },
      rootState: getState(),
      dispatch,
    })
      .then(res => res.data.success)
  }
}

export const updateDrivingFee = ({seq, price, operationBaseDate, client}) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: `/api/operation/${seq}/price`,
      data: { price, operationBaseDate, client },
      rootState: getState(),
      dispatch,
    })
      .then(res => res.data.success)
  }
}

export const deleteDrivingFee = (seq) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'delete',
      url: `/api/operation/${seq}/price`,
      rootState: getState(),
      dispatch,
    })
      .then(res => res.data.success)
  }
}

export const fetchDrivingFeesList = (params) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/operation/price',
      rootState: getState(),
      dispatch,
    })
      .then(res => res.data.response)
  }
}

export const toggleViewedOnDrivingFees = (seq, viewed) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: `/api/operation/${seq}/view`,
      rootState: getState(),
      data: { viewed },
      dispatch,
    })
      .then(res => res.data.response)
  }
}


export const exportPriceExcel = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/excel/price/download',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        return response.data.response
      })
  }
}
