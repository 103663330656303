import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import { X } from "react-feather"
import { Label, Input, FormGroup, Button } from "reactstrap"
import moment from "moment";
import Flatpickr from "react-flatpickr";

import '@/assets/scss/baecha-admin-style/baecha-style-page.scss'

class FirstDispatchSidebar extends Component {

  state = {
    show: false,
    time: ["12:00"],
    hours: '',
    mins: '',
    btnFocus: false,
  }

  componentDidMount() {
    this.setState({ show: true })
  }

  onChangeHours = h => {
    if(h.length >=3) return
    if(isNaN(h)) return

    this.setState({ hours: h })
  }

  onChangeMins = m => {
    if(m.length >=3) return
    if(isNaN(m)) return

    this.setState({ mins: m })
  }

  onUpdateData = () => {
    const { hours, mins } = this.state
    const { updateData } = this.props

    if(hours === '' || mins === '') {
      window.alert('시, 분단위를 모두 입력해주세요.')
      return
    }

    const h = parseInt(hours)
    if(h < 0 || h > 23) {
      window.alert('시 단위는 0-23까지 입력 가능합니다.')
      return
    }

    const m = parseInt(mins)
    if(m < 0 || m > 59) {
      window.alert('분 단위는 0-59까지 입력 가능합니다.')
      return
    }
    const time = `${h > 9 ? h : `0${h}`}:${m > 9 ? m : `0${m}`}`
    updateData({time: [time]})
  }

  render() {
    let { show, time, hours, mins } = this.state
    let { handleSidebar, updateData } = this.props

    return (
      <div
        style={{height: `${window.innerHeight}px`}}
        className={classnames("data-list-sidebar", { show })}>
        <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
          <h4>첫배차 등록</h4>
          <X className="cursor-pointer" size={20} onClick={() => handleSidebar(false)} />
        </div>
        <PerfectScrollbar
          className="data-list-fields px-2 mt-3"
          options={{ wheelPropagation: false }}
        >
          <FormGroup>
            <Label for="data-principal">첫 배차 시간 (00:00 - 23:59)</Label>
            <div className={'d-flex'}>
              <Input
                className={'width-15-per'}
                type="number"
                min="1"
                value={hours}
                placeholder="시"
                onChange={e => this.onChangeHours(e.target.value)}
                id="data-time-hours"
              />
              <span className={'padding-0d7-rem'}>:</span>
              <Input
                className={'width-15-per'}
                type="number"
                min="1"
                value={mins}
                placeholder="분"
                onChange={e => this.onChangeMins(e.target.value)}
                id="data-time-mins"
              />
            </div>
          </FormGroup>
        </PerfectScrollbar>
        <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
          <Button
            // color="primary"
            className={`ok-btn fc ${this.state.btnFocus ? 'focusing' : ''}`}
            onFocus={() => this.setState({ btnFocus: true })}
            onBlur={() => this.setState({ btnFocus: false })}
            onClick={this.onUpdateData}
          >
            등록
          </Button>
          <Button
            className="ml-1"
            color="danger"
            outline
            onClick={() => handleSidebar(false)}
          >
            취소
          </Button>
        </div>
      </div>
    )
  }
}

export default FirstDispatchSidebar

FirstDispatchSidebar.propTypes = {
  handleSidebar: PropTypes.func,
  createCustomer: PropTypes.func,
}
