import produce from 'immer'
import { DEFAULT_ROWS_PER_PAGE } from '@/views/pages/CarAreaList/DataListConfig'

export const carAreaTypes = {
  FETCH_CAR_AREA_LIST: 'carArea/FETCH_CAR_AREA_LIST',
  CHANGE_LIST_OPTION: 'carArea/CHANGE_LIST_OPTION',
  CHANGE_STATUS: 'carArea/CHANGE_STATUS',
  UPDATE_CAR: 'carArea/UPDATE_CAR',
  FILTER_LIST: 'carArea/FILTER_LIST',
  UPDATE_MEMO: 'carArea/UPDATE_MEMO',
}

const initialState = {
  list: [], // [{accountId, accountType, companyName, companyTel, createdAt, name, phone, ton, volume}, ...]
  displayList: [],
  params: null,
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: []
}

const carArea = (state = initialState, action) => {
  switch (action.type) {
    case carAreaTypes.FETCH_CAR_AREA_LIST:
      return produce(state, draftState => {
        const { list, totalRecords, params } = action.payload
        const { page = 1, perPage = DEFAULT_ROWS_PER_PAGE } = params
        draftState.displayList = list.slice(page - 1, perPage)
        draftState.list = list
        draftState.totalRecords = totalRecords
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / (params.perPage || DEFAULT_ROWS_PER_PAGE))
      })
    case carAreaTypes.CHANGE_LIST_OPTION:
      return produce(state, draftState => {
        const { list, totalRecords } = state
        const { params } = action.payload
        const { page = 1, perPage = DEFAULT_ROWS_PER_PAGE } = params
        const perPageNum = Number(perPage)
        const startIdx = (page - 1) * perPageNum
        const endIdx = startIdx + perPageNum
        draftState.displayList = list.slice(startIdx, endIdx)
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / perPage)
        draftState.sortIndex = [startIdx + 1, endIdx > totalRecords ? totalRecords : endIdx]
      })
    case carAreaTypes.CHANGE_STATUS:
      return produce(state, draftState => {
        const { seq, enabled } = action.payload
        draftState.displayList.find(item => item.seq === seq).enabled = enabled
      })
    case carAreaTypes.UPDATE_CAR:
      return produce(state, draftState => {
        const { seq, name, phone, ton, volume, carType, carYear, affiliation } = action.payload
        const item = draftState.displayList.find(item => item.seq === seq)
        item.name = name
        item.phone = phone
        item.ton = ton
        item.volume = volume
        item.carType = carType
        item.carYear = carYear
        item.affiliation = affiliation
      })
    case carAreaTypes.UPDATE_MEMO:
      return produce(state, draftState => {
        const { seq, memo } = action.payload
        const item = draftState.displayList.find(item => item.seq === seq)
        item.memo = memo

        const item2 = draftState.filteredData.find(item => item.seq === seq)
        item2.memo = memo
      })
    case carAreaTypes.FILTER_LIST:
      return produce(state, draftState => {
        const { value } = action
        // console.log({action})
        if (!value) {
          draftState.filteredData = draftState.displayList
        } else {
          // draftState.filteredData = state.list
          draftState.filteredData = state.displayList
            .filter(item => {
              let startsWithCondition =
                (item.name || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.phone || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.companyName || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.accountId || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.ton || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.volume || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.carType || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.carType2 || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.carYear || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.affiliation || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.carBrand || '').toLowerCase().startsWith(value.toLowerCase())

              let includesCondition =
                (item.name || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.phone || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.companyName || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.accountId || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.ton || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.volume || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.carType || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.carType2 || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.carYear || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.affiliation || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.carBrand || '').toLowerCase().startsWith(value.toLowerCase())

              if (startsWithCondition) {
                return startsWithCondition
              } else if (!startsWithCondition && includesCondition) {
                return includesCondition
              } else {
                return null
              }
            })
            .slice((state.params.page || 1) - 1, (state.params.perPage || DEFAULT_ROWS_PER_PAGE))
        }
      })
    default:
      return state
  }
}

export default carArea
