import React from "react"
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import DataTable from "react-data-table-component"
import classnames from "classnames"
import { history } from '@/history'
import ReactPaginate from "react-paginate"
import {
  Input,
  Button,
  Spinner,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem, CustomInput,
} from 'reactstrap'
import {
  Edit,
  Trash,
  ChevronDown,
  Plus,
  Check,
  ChevronLeft,
  ChevronRight
} from "react-feather"
import Checkbox from "@/components/@vuexy/checkbox/CheckboxesVuexy"
import MemoSidebar from './MemoSidebar'
import AddNewSidebar from './AddNewSidebar'
import EditSidebar from './EditSidebar'
import {
  fetchCustomerList,
  changeStatusCustomer,
  updateMemoCustomer,
  addNewCustomer,
  filterList,
  changeListOption,
  deleteCustomer,
  editCustomer,
  exportPointExcel,
} from '@/redux/actions/customer'

import "@/assets/scss/plugins/extensions/react-paginate.scss"
import '@/assets/scss/pages/data-list.scss'

export const DEFAULT_ROWS_PER_PAGE = 10

const selectedStyle = {
  rows: {
    selectedHighlighStyle: {
      backgroundColor: "rgba(115,103,240,.05)",
      color: "#7367F0 !important",
      boxShadow: "0 0 1px 0 #7367F0 !important",
      "&:hover": {
        transform: "translateY(0px) !important"
      }
    }
  }
}

const CustomHeader = props => {
  return (
    <div className="data-list-header d-flex justify-content-between flex-wrap width-100-per mb-1">
      <div className="actions-left d-flex flex-wrap">
        <Button
          className="add-new-btn"
          color="primary"
          onClick={() => props.handleAddNewSidebar(true)}
          outline>
          <Plus size={15} />
          <span className="align-middle">등록</span>
        </Button>
        <Button
          className="add-new-btn ml-1"
          color="primary"
          onClick={props.handleExportExcel}
          outline>
          <span className="align-middle">Excel</span>
        </Button>
      </div>
      <div className="actions-right d-flex flex-wrap mt-sm-0 mt-2">
        <div className="filter-section mr-1">
          <Input type="text" placeholder="검색어를 입력하세요." onChange={e => props.handleFilter(e)} />
        </div>
        <UncontrolledDropdown className="data-list-rows-dropdown d-md-block d-none">
          <DropdownToggle color="" className="sort-dropdown">
            <span className="align-middle mx-50">
              {`${props.index[0]} - ${props.index[1]} of ${props.total}`}
            </span>
            <ChevronDown size={15} />
          </DropdownToggle>
          <DropdownMenu tag="div" right>
            <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(10)}>
              10
            </DropdownItem>
            <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(20)}>
              20
            </DropdownItem>
            <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(props.total)}>
              모두보기
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  )
}

class DataListConfig extends React.Component{
  static getDerivedStateFromProps(props, state) {
    if (
      props.customer.displayList.length !== state.data.length ||
      state.currentPage !== props.parsedFilter.page
    ) {
      return {
        data: props.customer.displayList,
        allData: props.customer.filteredData,
        totalPages: props.customer.totalPages,
        currentPage: parseInt(props.parsedFilter.page) - 1,
        rowsPerPage: parseInt(props.parsedFilter.perPage),
        totalRecords: props.customer.totalRecords,
        sortIndex: props.customer.sortIndex
      }
    }

    // Return null if the state hasn't changed
    return null
  }

  state = {
    apiLoaded: false,
    data: [],
    allData: [],
    totalPages: 0,
    currentPage: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    totalRecords: 0,
    sortIndex: [],
    columns: [
      // {
      //   name: "No",
      //   selector: "rowNum",
      //   sortable: true,
      //   width: "60px",
      //   cell: row => (
      //     <span className={'width-100-per text-align-center row-font-size'}>{row.rowNum}</span>
      //   )
      // },
      {
        name: "사용",
        selector: "enabled",
        sortable: false,
        width: "70px",
        cell: row => (
          <CustomInput
            type="switch"
            id={`switch-${row.seq}`}
            name="customSwitch"
            inline
            checked={row.enabled}
            onChange={e => this.handleChangeStatus(row)(e)}
          />
        )
      },
      {
        name: "간편회사명",
        selector: "shortName",
        sortable: true,
        minWidth: "191.6px",
        center: true,
        cell: row => (
          <p title={row.shortName} className="text-bold-500 mb-0 row-font-size">
            {row.shortName}
          </p>
        )
      },
      {
        name: "회사명",
        selector: "companyName",
        sortable: true,
        minWidth: "191.6px",
        center: true,
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.companyName}</span>
        )
      },
      {
        name: "주소",
        selector: "address",
        sortable: true,
        center: true,
        minWidth: "500px",
        cell: row => (
          <p className="text-truncate text-bold-500 mb-0 text-align-left width-100-per row-font-size">
            {row.address}
          </p>
        )
      },
      {
        name: "대표자명",
        selector: "companyOwnerName",
        sortable: true,
        minWidth: "191.6px",
        center: true,
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.companyOwnerName}</span>
        )
      },
      {
        name: "담당자명",
        selector: "companyPersonName",
        sortable: true,
        minWidth: "191.6px",
        center: true,
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.companyPersonName}</span>
        )
      },
      {
        name: "사업자등록번호",
        selector: "taxAddress",
        sortable: true,
        minWidth: "191.6px",
        center: true,
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.taxAddress}</span>
        )
      },
      {
        name: "전화번호",
        selector: "companyTel",
        sortable: true,
        minWidth: "191.6px",
        center: true,
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.companyTel}</span>
        )
      },
      {
        name: "팩스번호",
        selector: "companyFax",
        sortable: true,
        minWidth: "191.6px",
        center: true,
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.companyFax}</span>
        )
      },
      {
        name: "사업자구분",
        selector: "companyType",
        sortable: true,
        minWidth: "191.6px",
        center: true,
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.companyType}</span>
        )
      },
      {
        name: "메일",
        selector: "companyEmail",
        sortable: true,
        minWidth: "190px",
        center: true,
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.companyEmail}</span>
        )
      },
      {
        name: "메모",
        selector: "enabled",
        sortable: false,
        width: "70px",
        center: true,
        cell: row => (
          <Edit
            className={classnames('cursor-pointer', { primary: row.memo })}
            size={24}
            onClick={() => this.handleCurrentData(row)}
          />
        )
      },
      {
        name: "수정",
        selector: "enabled",
        sortable: false,
        width: "100px",
        center: true,
        cell: row => (
          <Button.Ripple
            size="sm"
            color="primary"
            onClick={() => this.handleCurrentData4Edit(row)}
          >
            수정
          </Button.Ripple>
        )
      },
      {
        name: '삭제',
        sortable: false,
        width: "70px",
        cell: row => (
          <Trash
            className="cursor-pointer"
            size={24}
            onClick={() => {
              this.handleDelete(row)
            }}
          />
        )
      }
    ],
    value: '',
    isShow: {
      memo: false,
      addNew: false,
      edit: false,
    },
    currentData: null,
    selected: [],
  }

  async componentDidMount() {
    await this.props.fetchCustomerList(this.props.parsedFilter)
    await this.handleRowsPerPage(this.state.totalRecords)
    this.setState({ apiLoaded: true })
    this.changeButtonSize()
    this.changeRowSize()
  }

  componentDidUpdate() {
    this.changeButtonSize()
    this.changeRowSize()
  }

  changeButtonSize = () => {
    const $els = document.querySelectorAll('button.btn.btn-sm')
    $els.forEach($el => $el.setAttribute("style", "padding: 0.5rem 1rem;"));
  }

  changeRowSize = () => {
    const $els = document.querySelectorAll('[role="row"]')
    $els.forEach($el => $el.setAttribute("style", "min-height: 30px;"));
    this.changeHeaderFontSize()
  }

  changeHeaderFontSize = () => {
    const $els = document.querySelectorAll('.rdt_TableCol_Sortable')
    $els.forEach($el => $el.setAttribute("style", "font-size: 12px;"));
  }

  handleDelete = async obj => {
    if (window.confirm('삭제 하겠습니까?')) {
      await this.props.deleteCustomer(obj.seq)
      await this.props.fetchCustomerList(this.props.parsedFilter)
      this.props.filterList(this.state.value)
    }
  }

  handleCurrentData = obj => {
    this.setState({ currentData: obj })
    this.handleMemoSidebar(true)
  }

  handleFilter = e => {
    this.setState({ value: e.target.value })
    this.props.filterList(e.target.value)
  }

  handleRowsPerPage = value => {
    let { parsedFilter, changeListOption } = this.props
    // let page = parsedFilter.page !== undefined ? parsedFilter.page : 1
    // history.replace(`/customer/list?page=${page}&perPage=${value}`)
    history.replace(`/customer/list?page=1&perPage=${value}`)
    this.setState({ rowsPerPage: value, currentPage: 0 })
    changeListOption({ page: 1, perPage: value })
  }

  handlePagination = page => {
    let { parsedFilter, changeListOption } = this.props
    let perPage = parsedFilter.perPage !== undefined ? parsedFilter.perPage : DEFAULT_ROWS_PER_PAGE
    history.replace(`/customer/list?page=${page.selected + 1}&perPage=${perPage}`)
    changeListOption({ page: page.selected + 1, perPage: perPage })
    this.setState({ currentPage: page.selected })
    this.changeButtonSize()
  }

  handleChangeStatus = (row) => async (event) => {
    const ele = event.target
    const enabled = ele.checked
    try {
      await this.props.changeStatusCustomer({ seq: row.seq, enabled })
      this.props.filterList(this.state.value)
    } catch (e) {
      ele.checked = !enabled
    }
  }

  updateMemo = async (row, memo) => {
    const { seq } = row
    await this.props.updateMemoCustomer({ seq, memo })
    this.props.filterList(this.state.value)
    this.handleMemoSidebar(false)
  }

  handleHideSidebar = (boolean) => {
    this.setState({ isShow: { addNew: boolean, memo: boolean, edit: boolean } })
  }

  handleMemoSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, memo: boolean } })
  }

  handleAddNewSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, addNew: boolean }, apiLoaded: true })
  }

  handleEditSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, edit: boolean } })
  }

  handleCurrentData4Edit = obj => {
    this.setState({ currentData: obj })
    this.handleEditSidebar(true)
  }

  createCustomer = async (addNewData) => {
    this.setState({ apiLoaded: false })
    await this.props.addNewCustomer(addNewData)
    // await this.props.fetchCustomerList(this.props.parsedFilter)
    // await this.props.fetchCustomerList({page: 1, perPage: this.props.carArea.displayList.length+1})
    // this.setState({ apiLoaded: true })
    this.handleAddNewSidebar(false)
  }

  editCustomer = async (seq, data) => {
    this.setState({ apiLoaded: false })
    await this.props.editCustomer(seq, data)
    await this.props.fetchCustomerList(this.props.parsedFilter)
    this.props.filterList(this.state.value)
    this.setState({ apiLoaded: true })
    this.handleEditSidebar(false)
  }

  handleExportExcel = async () => {
    const href = await this.props.exportPointExcel()
    const link = document.createElement('a')
    link.href = href
    document.body.appendChild(link)
    link.click()
  }

  render(){
    let {
      apiLoaded, columns, data, allData, isShow, currentData, rowsPerPage,
      totalRecords, sortIndex, totalPages, value, selected
    } = this.state

    const dataList = (value.length ? allData : data).map((d, idx) => ({...d, rowNum: idx+1}))

    const selectedMap = selected
      .map(s => ({[s.seq]: s}))
      .reduce((a, b) => ({...a, ...b}), {})

    return !apiLoaded ? (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    ) : (
      <div className="list-grid-style-small m-2">
        <CustomHeader
          handleSidebar={this.handleAddNewSidebar}
          handleFilter={this.handleFilter}
          handleRowsPerPage={this.handleRowsPerPage}
          handleAddNewSidebar={this.handleAddNewSidebar}
          handleExportExcel={this.handleExportExcel}
          rowsPerPage={rowsPerPage}
          total={totalRecords}
          index={sortIndex}
        />
        <DataTable
          columns={columns}
          // data={value.length ? allData : data}
          data={dataList}
          noHeader
          selectableRows
          responsive
          //pointerOnHover
          selectableRowsHighlight
          selectableRowSelected={row => !!selectedMap[row.seq]}
          onSelectedRowsChange={data => this.setState({ selected: data.selectedRows })}
          customStyles={selectedStyle}
          sortIcon={<ChevronDown />}
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={{
            color: "primary",
            icon: <Check className="vx-icon" size={12} />,
            label: "",
            size: "sm"
          }}
          noDataComponent={<div style={{padding: '24px'}}>데이터가 없습니다.</div>}
        />
        <ReactPaginate
          previousLabel={<ChevronLeft size={15} />}
          nextLabel={<ChevronRight size={15} />}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={totalPages}
          containerClassName="vx-pagination separated-pagination pagination-center pagination-sm mb-0 mt-2 list-style-pagination"
          activeClassName="active"
          forcePage={
            this.props.parsedFilter.page
              ? parseInt(this.props.parsedFilter.page - 1)
              : 0
          }
          onPageChange={page => this.handlePagination(page)}
        />
        <div className="data-list list-view">
          { isShow.memo && (
            <MemoSidebar
              data={currentData}
              handleSidebar={this.handleMemoSidebar}
              updateMemo={this.updateMemo}
            />
          )}
          { isShow.addNew && (
            <AddNewSidebar
              handleSidebar={this.handleAddNewSidebar}
              createCustomer={this.createCustomer}
            />
          )}
          { isShow.edit && (
            <EditSidebar
              data={currentData}
              handleSidebar={this.handleEditSidebar}
              editCustomer={this.editCustomer}
            />
          )}
          <div
            className={classnames("data-list-overlay", {
              show: isShow.memo || isShow.addNew || isShow.edit
            })}
            onClick={() => this.handleHideSidebar(false)}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    customer: state.customer
  }),
  {
    fetchCustomerList,
    changeStatusCustomer,
    updateMemoCustomer,
    addNewCustomer,
    filterList,
    changeListOption,
    deleteCustomer,
    editCustomer,
    exportPointExcel,
  },
)(DataListConfig)

DataListConfig.propTypes = {
  parsedFilter: PropTypes.object
}
