import React, { Component } from "react"
import PropTypes from "prop-types"
import { Input, Button, FormGroup, Label } from 'reactstrap'
import { X } from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import classnames from "classnames"
import Select from 'react-select'

import _ from "fxjs/Strict"
import L from "fxjs/Lazy"

class DailyEditSidebar extends Component {

  state = {
    show: false,
    target: this.props.data.length > 1 ? '' : this.props.data[0].target,
    downPoint: this.props.data.length > 1 ? '' : (this.props.upDownPointList.find(a => a.shortName === this.props.data[0].downPointName) || {}).seq,
    products: this.props.data.length > 1 ? '' : this.props.data[0].itemProductName,
    upPoint: this.props.data.length > 1 ? '' : (this.props.upDownPointList.find(a => a.shortName === this.props.data[0].upPointName) || {}).seq,
    areaList: this.props.upDownPointList.map(item => ({...item, value: item.seq, label: item.shortName })),
    goodsList: this.props.goodsList.map(item => ({...item, value: item.itemName, label: item.itemName })),
    btnFocus: false,
  }

  componentDidMount() {
    this.setState({ show: true })
  }

  handleSubmit = () => {

    const { downPoint, products, upPoint } = this.state
    const itemProduct = _.go(this.props.goodsList,
      L.filter(g => g.itemName === products),
      L.map(g => g.seq),
      _.take(1),
      _.head)

    const { data, updateData } = this.props
    if (downPoint && products && upPoint && itemProduct) {
      updateData(data, {...this.state, itemProduct})
    } else {
      alert('모두 작성해 주세요,')
    }
  }

  render() {
    let {
      // carNumberList
      show, target, downPoint, products, ton, upPoint, volume, areaList, goodsList,
    } = this.state
    let { handleSidebar } = this.props

    return (
      <div
        style={{height: `${window.innerHeight}px`}}
        className={classnames("data-list-sidebar", { show })}>
        <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
          <h4>일일배차 상세 수정</h4>
          <X className="cursor-pointer" size={20} onClick={() => handleSidebar(false)} />
        </div>
        <PerfectScrollbar
          className="data-list-fields px-2 mt-3"
          options={{ wheelPropagation: true }}
        >
          { target && (
            <FormGroup>
              <Label for="data-target">차량번호</Label>
              <Input
                type="text"
                value={target}
                placeholder="차량번호"
                //onChange={e => this.setState({ accountId: e.target.value })}
                id="data-target"
                //readOnly
                disabled
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label for="data-upPoint">상차지</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={areaList}
              //isClearable={true}
              defaultValue={areaList.find(a => a.value === upPoint)}
              onChange={(value) => this.setState({ upPoint: value?.seq })}
              id="data-upPoint"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-downPoint">하차지</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={areaList}
              //isClearable={true}
              defaultValue={areaList.find(a => a.value === downPoint)}
              onChange={(value) => this.setState({ downPoint: value?.seq })}
              id="data-downPoint"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-products">운송물품</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={goodsList}
              //isClearable={true}
              defaultValue={goodsList.find(c => c.itemName === products)}
              onChange={(value) => this.setState({ products: value?.itemName })}
              id="data-products"
            />
          </FormGroup>
        </PerfectScrollbar>
        <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
          <Button
            // color="primary"
            className={`ok-btn fc ${this.state.btnFocus ? 'focusing' : ''}`}
            onFocus={() => this.setState({ btnFocus: true })}
            onBlur={() => this.setState({ btnFocus: false })}
            onClick={() => this.handleSubmit(this.state)}
          >
            수정
          </Button>
          <Button
            className="ml-1"
            color="danger"
            outline
            onClick={() => handleSidebar(false)}
          >
            취소
          </Button>
        </div>
      </div>
    )
  }
}

export default DailyEditSidebar

DailyEditSidebar.propTypes = {
  data: PropTypes.array,
  updateData: PropTypes.func,
  handleSidebar: PropTypes.func,
}
