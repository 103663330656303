import React, { Component } from "react"
import PropTypes from "prop-types"
import { Input, Button } from "reactstrap"
import { X } from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import classnames from "classnames"

class MemoSidebar extends Component {

  state = {
    show: false,
    memo: this.props.data.memo,
  }

  componentDidMount() {
    this.setState({ show: true })
  }

  handleSubmit = () => {
    const { memo } = this.state
    const { data, updateMemo, handleSidebar } = this.props
    if (memo === data.memo) return handleSidebar(false)
    updateMemo(data, memo)
  }

  render() {
    let { show } = this.state
    let { handleSidebar } = this.props
    return (
      <div
        style={{height: `${window.innerHeight}px`}}
        className={classnames("data-list-sidebar", { show })}>
        <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
          <h4>메모</h4>
          <X className="cursor-pointer" size={20} onClick={() => handleSidebar(false)} />
        </div>
        <PerfectScrollbar
          className="data-list-fields px-2 mt-3"
          options={{ wheelPropagation: false }}
        >
          <Input
            type="textarea"
            name="text"
            id="exampleText"
            rows="3"
            placeholder="메모"
            value={this.state.memo}
            onChange={e => this.setState({ memo: e.target.value })}
          />
        </PerfectScrollbar>
        <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
          <Button color="primary" onClick={() => this.handleSubmit(this.state)}>
            수정
          </Button>
          <Button
            className="ml-1"
            color="danger"
            outline
            onClick={() => handleSidebar(false)}
          >
            취소
          </Button>
        </div>
      </div>
    )
  }
}

export default MemoSidebar

MemoSidebar.propTypes = {
  data: PropTypes.object,
  updateMemo: PropTypes.func,
  handleSidebar: PropTypes.func,
}
