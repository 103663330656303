import apiRequest from '@/configs/axiosConfig'
import { goodsTypes } from '@/redux/reducers/goods'
//import {carAreaTypes} from "@/redux/reducers/carArea";

export const fetchGoodsList = (params) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/goods',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
        const numberingList = list.map((item, idx) => ({...item, rowNum: idx + 1}))
        dispatch({
          type: goodsTypes.FETCH_GOODS_LIST,
          payload: { list:numberingList, params, totalRecords: list.length },
        })
        dispatch(changeListOption(params))
        return response
      })
  }
}

export const fetchGoodsListGrid = (params) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/goods',
      rootState: getState(),
      dispatch,
    })
      .then(res => res.data.response)
  }
}

export const changeListOption = (params) => {
  return (dispatch) => dispatch({
    type: goodsTypes.CHANGE_LIST_OPTION,
    payload: { params },
  })
}

export const changeStatusGoods = ({ seq, enabled }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: `/api/point/${seq}/status`,
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        if (response.data.response === true) {
          dispatch({
            type: goodsTypes.CHANGE_STATUS,
            payload: { seq, enabled },
          })
        }
        return response
      })
  }
}

export const updateGoods = ({ goodsSeq, goodsName, goodsDesc, carType }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: '/api/goods',
      data: [{ goodsSeq, goodsName, goodsDesc, carType }],
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        if (response.data.response === true) {
          dispatch({
            type: goodsTypes.UPDATE_GOODS,
            payload: { goodsSeq, goodsName, goodsDesc },
          })
        }
        return response
      })
  }
}

export const updateGoodsGrid = ({ goodsSeq, goodsName, goodsDesc, carType }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: '/api/goods',
      data: [{ goodsSeq, goodsName, goodsDesc, carType }],
      rootState: getState(),
      dispatch,
    })
      .then(response => response.data.response)
      .catch(res => false)
  }
}

export const updateGoodsSortingGrid = ({ itemProductSeq, sorting }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: '/api/goods/sorting',
      data: [{ itemProductSeq, sorting }],
      rootState: getState(),
      dispatch,
    })
      .then(response => response.data.response)
      .catch(res => false)
  }
}

export const addNewGoods = ({ goodsName, goodsDesc, carType }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/goods',
      data: [{ goodsName, goodsDesc, carType }],
      rootState: getState(),
      dispatch,
    }).then(res => {
      if (res.data.response === true) {
        console.log("등록되었습니다.");
        window.location.reload()
      }
    })
  }
}

export const addNewGoodsGrid = ({ goodsName, goodsDesc, carType }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/goods',
      data: [{ goodsName, goodsDesc, carType }],
      rootState: getState(),
      dispatch,
    }).then(res => res.data.response)
  }
}

export const deleteGoods = (goodsSeq) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'delete',
      url: '/api/goods',
      data: [{ goodsSeq }],
      rootState: getState(),
      dispatch,
    })
  }
}

export const deleteGoodsGrid = (goodsSeq) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'delete',
      url: '/api/goods',
      data: [{ goodsSeq }],
      rootState: getState(),
      dispatch,
    })
      .then(response => response.data.response)
  }
}

export const filterList = value => {
  return dispatch => dispatch({ type: goodsTypes.FILTER_LIST, value })
}

export const changeEnabled = (seq, enabled) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: `/api/goods/${seq}/status`,
      data: { enabled },
      rootState: getState(),
      dispatch,
    }).then(response => {
      if (response.data.response === true) {
        dispatch({
          type: goodsTypes.CHANGE_STATUS,
          payload: { seq, enabled },
        })
      }
      return response
    })
  }
}

export const changeEnabledGrid = (seq, enabled) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: `/api/goods/${seq}/status`,
      data: { enabled },
      rootState: getState(),
      dispatch,
    }).then(res => res.data.response)
  }
}


export const exportGoodsExcel = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/excel/goods/download',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        return response.data.response
      })
  }
}
