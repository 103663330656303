export const alertTypes = {
  SHOW_ALERT: 'alert/SHOW_ALERT',
  HIDE_ALERT: 'alert/HIDE_ALERT',
}

const ALLOW_TYPES = ['success', 'error', 'info', 'warning']

const initialState = {
  type: null,
  title: null,
  message: null,
  api: '',
}

const alert = (state = initialState, action) => {
  switch (action.type) {
    case alertTypes.SHOW_ALERT:
      let { type, title, message, api } = action.payload
      if (!ALLOW_TYPES.some(t => t === type)) {
        type = ALLOW_TYPES[0]
        console.warn(`allow alert types: ${JSON.stringify(ALLOW_TYPES)}`)
      }
      return { type, title, message, api }
    case alertTypes.HIDE_ALERT:
      return {
        type: null,
        title: null,
        message: null,
        api: '',
      }
    default:
      return state
  }
}

export default alert
