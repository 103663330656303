import React from "react"
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import DataTable from "react-data-table-component"
import classnames from "classnames"
import { history } from '@/history'
import ReactPaginate from "react-paginate"
import {
  Input,
  Button,
  Spinner,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  CustomInput,
} from "reactstrap"
import {
  ChevronDown,
  Plus,
  Check,
  ChevronLeft,
  ChevronRight,
  Trash, Edit,
} from 'react-feather'
import Checkbox from "@/components/@vuexy/checkbox/CheckboxesVuexy"
import EditCarSidebar from './EditCarSidebar'
import AddNewSidebar from './AddNewSidebar'
import MemoSidebar from './MemoSidebar'
import {
  fetchCarArea,
  addNewCarManage,
  updateCarArea,
  updateMemoArea,
  filterList,
  changeListOption,
  deleteCar,
  changeEnabled,
  exportDriverExcel,
} from '@/redux/actions/carArea'

import "@/assets/scss/plugins/extensions/react-paginate.scss"
import '@/assets/scss/pages/data-list.scss'

export const DEFAULT_ROWS_PER_PAGE = 10

const selectedStyle = {
  rows: {
    selectedHighlighStyle: {
      backgroundColor: "rgba(115,103,240,.05)",
      color: "#7367F0 !important",
      boxShadow: "0 0 1px 0 #7367F0 !important",
      "&:hover": {
        transform: "translateY(0px) !important"
      }
    }
  }
}

const CustomHeader = props => {
  return (
    <div className="data-list-header d-flex justify-content-between flex-wrap width-100-per mb-1">
      <div className="actions-left d-flex flex-wrap">
        <Button
          className="add-new-btn"
          color="primary"
          onClick={() => props.handleAddNewSidebar(true)}
          outline>
          <Plus size={15} />
          <span className="align-middle">등록</span>
        </Button>
        <Button
          className="add-new-btn ml-1"
          color="primary"
          onClick={props.handleExportExcel}
          outline>
          <span className="align-middle">Excel</span>
        </Button>
      </div>
      <div className="actions-right d-flex flex-wrap mt-sm-0 mt-2">
        <div className="filter-section mr-1">
          <Input type="text" placeholder="검색어를 입력하세요." onChange={e => props.handleFilter(e)} />
        </div>
        <UncontrolledDropdown className="data-list-rows-dropdown d-md-block d-none">
          <DropdownToggle color="" className="sort-dropdown">
            <span className="align-middle mx-50">
              {`${props.index[0]} - ${props.index[1]} of ${props.total}`}
            </span>
            <ChevronDown size={15} />
          </DropdownToggle>
          <DropdownMenu tag="div" right>
            <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(10)}>
              10
            </DropdownItem>
            <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(20)}>
              20
            </DropdownItem>
            <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(props.total)}>
              모두보기
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  )
}

class DataListConfig extends React.Component{
  static getDerivedStateFromProps(props, state) {
    if (
      props.carArea.displayList.length !== state.data.length ||
      state.currentPage !== props.parsedFilter.page
    ) {
      return {
        data: props.carArea.displayList,
        allData: props.carArea.filteredData,
        totalPages: props.carArea.totalPages,
        currentPage: parseInt(props.parsedFilter.page) - 1,
        rowsPerPage: parseInt(props.parsedFilter.perPage),
        totalRecords: props.carArea.totalRecords,
        sortIndex: props.carArea.sortIndex
      }
    }

    // Return null if the state hasn't changed
    return null
  }

  state = {
    apiLoaded: false,
    data: [],
    allData: [],
    totalPages: 0,
    currentPage: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    totalRecords: 0,
    sortIndex: [],
    columns: [
      {
        name: "No",
        selector: "rowNum",
        sortable: true,
        width: "60px",
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.rowNum}</span>
        )
      },
      {
        name: "로그인 ID",
        selector: "loginId",
        sortable: true,
        width: "120px",
        center: true,
        cell: row => (
          <p title={row.loginId} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.loginId}
          </p>
        )
      },
      {
        name: "차량번호",
        selector: "accountId",
        width: "120px",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.accountId} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.accountId}
          </p>
        )
      },
      {
        name: "소속",
        selector: "affiliation",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.affiliation} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.affiliation}
          </p>
        )
      },
      {
        name: "이름",
        selector: "name",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.name} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.name}
          </p>
        )
      },
      {
        name: "연락처",
        selector: "phone",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.phone} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.phone}
          </p>
        ),
        minWidth: "150px"
      },
      {
        name: "차량구분",
        selector: "carType2",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.carType2} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.carType2}
          </p>
        ),
      },
      {
        name: "운송구분",
        selector: "carType",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.carType} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.carType}
          </p>
        ),
      },
      {
        name: "톤수",
        selector: "ton",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.ton} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.ton} t
          </p>
        )
      },
      {
        name: "용적(루베)",
        selector: "volume",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.volume} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.volume} m³
          </p>
        ),
        minWidth: "120px"
      },
      {
        name: "차량연식",
        selector: "carYear",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.carYear} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.carYear}
          </p>
        )
      },
      {
        name: "모델",
        selector: "carBrand",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.carBrand} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.carBrand}
          </p>
        )
      },
      {
        name: "사용",
        selector: "enabled",
        sortable: false,
        width: "70px",
        cell: row => (
          <CustomInput
            type="switch"
            id={`switch-${row.seq}`}
            name="customSwitch"
            inline
            checked={row.enabled}
            onChange={this.handleChangeEnabled(row)}
          />
        )
      },
      {
        name: "메모",
        selector: "enabled",
        sortable: false,
        width: "70px",
        center: true,
        cell: row => (
          <Edit
            className={classnames('cursor-pointer', { primary: row.memo })}
            size={24}
            onClick={() => {this.handleMemoData(row)}}
          />
        )
      },
      {
        name: "수정",
        sortable: false,
        width: "100px",
        center: true,
        cell: row => (
          <Button.Ripple
            color="primary"
            size="sm"
            onClick={() => this.handleCurrentData(row)}
          >
            수정
          </Button.Ripple>
        )
      },
      {
        name: "삭제",
        sortable: false,
        width: "70px",
        cell: row => (
          <Trash
            className="cursor-pointer"
            size={24}
            onClick={() => {
              this.handleDelete(row)
            }}
          />
        )
      }
    ],
    value: '',
    isShow: {
      editCar: false,
      addNew: false,
    },
    currentData: null,
    selected: [],
  }

  async componentDidMount() {
    await this.props.fetchCarArea(this.props.parsedFilter)
    await this.handleRowsPerPage(this.state.totalRecords)
    this.setState({ apiLoaded: true })
    this.changeButtonSize()
    this.changeRowSize()
  }

  componentDidUpdate() {
    this.changeButtonSize()
    this.changeRowSize()
  }

  changeButtonSize = () => {
    const $els = document.querySelectorAll('button.btn.btn-sm')
    $els.forEach($el => $el.setAttribute("style", "padding: 0.5rem 1rem;"));
  }

  changeRowSize = () => {
    const $els = document.querySelectorAll('[role="row"]')
    $els.forEach($el => $el.setAttribute("style", "min-height: 30px;"));
    this.changeHeaderFontSize();
  }

  changeHeaderFontSize = () => {
    const $els = document.querySelectorAll('.rdt_TableCol_Sortable')
    $els.forEach($el => $el.setAttribute("style", "font-size: 12px;"));
  }

  handleDelete = async obj => {
    if (window.confirm('삭제 하겠습니까?')) {
      await this.props.deleteCar(obj.seq)
      await this.props.fetchCarArea(this.props.parsedFilter)
      this.props.filterList(this.state.value)
    }
  }

  handleCurrentData = obj => {
    this.setState({ currentData: obj })
    this.handleEditSidebar(true)
  }

  handleMemoData = e => {
    this.setState({ memo: e.memo, seq: e.seq })
    this.handleMemoSidebar(true)
  }

  handleFilter = e => {
    this.setState({ value: e.target.value })
    this.props.filterList(e.target.value)
  }

  handleRowsPerPage = value => {
    let { parsedFilter, changeListOption } = this.props
    // let page = parsedFilter.page !== undefined ? parsedFilter.page : 1
    // history.replace(`/carArea/list?page=${page}&perPage=${value}`)
    history.replace(`/carArea/list?page=1&perPage=${value}`)
    this.setState({ rowsPerPage: value, currentPage: 0 })
    changeListOption({ page: 1, perPage: value })
  }

  handlePagination = page => {
    let { parsedFilter, changeListOption } = this.props
    let perPage = parsedFilter.perPage !== undefined ? parsedFilter.perPage : DEFAULT_ROWS_PER_PAGE
    history.replace(`/carArea/list?page=${page.selected + 1}&perPage=${perPage}`)
    changeListOption({ page: page.selected + 1, perPage: perPage })
    this.setState({ currentPage: page.selected })
  }

  updateEditCar = async (row, { name, phone, principal, ton, volume, carYear, carType, carType2, affiliation, target, password, pwdChecked, carBrand }) => {
    this.setState({ apiLoaded: false })
    const { seq } = row
    await this.props.updateCarArea({ seq, name, phone, principal, ton, volume,carYear, carType, carType2, affiliation, target, password, pwdChecked, carBrand })
    await this.props.fetchCarArea(this.props.parsedFilter)
    await this.props.filterList(this.state.value)
    this.handleEditSidebar(false)
   }

  updateMemoCar = ({ seq, memo }) => {
    // const { seq } = row
    // this.props.memoCar({ seq, memo })
    this.props.updateMemoArea({seq, memo})
    this.handleMemoSidebar(false)
  }

  handleHideSidebar = (boolean) => {
    this.setState({ isShow: { addNew: boolean, editCar: boolean } })
  }

  handleEditSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, editCar: boolean }, apiLoaded: true })
  }

  handleMemoSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, memoCar: boolean } })
  }

  handleAddNewSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, addNew: boolean }, apiLoaded: true })
  }

   createCarArea = async (addNewData) => {
     this.setState({ apiLoaded: false })
     await this.props.addNewCarManage(addNewData).catch(e => e)
     // await this.props.fetchCarArea({page: 1, perPage: this.props.carArea.displayList.length+1})
     this.handleAddNewSidebar(false)
   }

  handleChangeEnabled = (row) => async (event) => {
    const ele = event.target
    const enabled = ele.checked
    try {
      await this.props.changeEnabled(row.seq, enabled)
      await this.props.filterList(this.state.value)
    } catch (e) {
      ele.checked = !enabled
    }
  }

  handleExportExcel = async () => {
    const href = await this.props.exportDriverExcel()
    const link = document.createElement('a')
    link.href = href
    document.body.appendChild(link)
    link.click()
  }

  render(){
    let {
      apiLoaded, columns, data, allData, isShow, currentData, rowsPerPage,
      totalRecords, sortIndex, totalPages, value
    } = this.state

    const dataList = (value.length ? allData : data).map((d, idx) => ({...d, rowNum: idx+1}))

    return !apiLoaded ? (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    ) : (
      <div className="list-grid-style-small m-2">
        <CustomHeader
          handleSidebar={this.handleAddNewSidebar}
          handleFilter={this.handleFilter}
          handleRowsPerPage={this.handleRowsPerPage}
          handleAddNewSidebar={this.handleAddNewSidebar}
          handleExportExcel={this.handleExportExcel}
          rowsPerPage={rowsPerPage}
          total={totalRecords}
          index={sortIndex}
        />
        <DataTable
          columns={columns}
          // data={value.length ? allData : data}
          data={dataList}
          noHeader
          //selectableRows
          responsive
          //pointerOnHover
          selectableRowsHighlight
          onSelectedRowsChange={data =>
            this.setState({ selected: data.selectedRows })
          }
          customStyles={selectedStyle}
          sortIcon={<ChevronDown />}
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={{
            color: "primary",
            icon: <Check className="vx-icon" size={12} />,
            label: "",
            size: "sm"
          }}
          noDataComponent={<div style={{padding: '24px'}}>데이터가 없습니다.</div>}
        />
        <ReactPaginate
          previousLabel={<ChevronLeft size={15} />}
          nextLabel={<ChevronRight size={15} />}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={totalPages}
          containerClassName="vx-pagination separated-pagination pagination-center pagination-sm mb-0 mt-2 list-style-pagination"
          activeClassName="active"
          forcePage={
            this.props.parsedFilter.page
              ? parseInt(this.props.parsedFilter.page - 1)
              : 0
          }
          onPageChange={page => this.handlePagination(page)}
        />
        <div className="data-list">
          { isShow.editCar && (
            <EditCarSidebar
              data={currentData}
              handleSidebar={this.handleEditSidebar}
              updateEditCar={this.updateEditCar}
            />
          )}
          { isShow.addNew && (
            <AddNewSidebar
              handleSidebar={this.handleAddNewSidebar}
              createCarArea={this.createCarArea}
            />
          )}
          { isShow.memoCar && (
            <MemoSidebar
              handleSidebar={this.handleMemoSidebar}
              updateMemo={this.updateMemoCar}
              memo={this.state.memo}
              seq={this.state.seq}
            />
          )}
          <div
            className={classnames("data-list-overlay", {
              show: isShow.addNew || isShow.editCar || isShow.memoCar
            })}
            onClick={() => this.handleHideSidebar(false)}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    carArea: state.carArea
  }),
  {
    fetchCarArea,
    addNewCarManage,
    updateCarArea,
    updateMemoArea,
    filterList,
    changeListOption,
    deleteCar,
    changeEnabled,
    exportDriverExcel,
  },
)(DataListConfig)

DataListConfig.propTypes = {
  parsedFilter: PropTypes.object
}
