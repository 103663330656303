import React from "react"
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import DataTable from "react-data-table-component"
import classnames from "classnames"
import { history } from '@/history'
import ReactPaginate from "react-paginate"
import {
  Input,
  Spinner,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem, CustomInput, Button,
} from 'reactstrap'
import {
  ChevronDown,
  Check,
  ChevronLeft,
  ChevronRight,
  RefreshCw,
} from 'react-feather'
import Checkbox from "@/components/@vuexy/checkbox/CheckboxesVuexy"
import EditCarSidebar from './EditCarSidebar'
import AddNewSidebar from './AddNewSidebar'
import {
  fetchCallHistory,
  addNewCarManage,
  updateCarArea,
  filterList,
  changeListOption,
  deleteCallHistory,
  changeEnabled,
} from '@/redux/actions/callHistory'

import "@/assets/scss/plugins/extensions/react-paginate.scss"
import '@/assets/scss/pages/data-list.scss'

export const DEFAULT_ROWS_PER_PAGE = 10

const selectedStyle = {
  rows: {
    selectedHighlighStyle: {
      backgroundColor: "rgba(115,103,240,.05)",
      color: "#7367F0 !important",
      boxShadow: "0 0 1px 0 #7367F0 !important",
      "&:hover": {
        transform: "translateY(0px) !important"
      }
    }
  }
}

const CustomHeader = props => {
  return (
    <div className="data-list-header d-flex justify-content-between align-items-center flex-wrap mb-1">
      <div className="actions-left d-flex flex-wrap">
        <div className="pl-1 cursor-pointer" onClick={props.handleRefresh}>
          <RefreshCw className="vx-icon" size={20} />
        </div>
      </div>
      <div className="actions-right d-flex flex-wrap mt-sm-0 mt-2">
        <div className="filter-section mr-1">
          <Input type="text" placeholder="검색어를 입력하세요." onChange={e => props.handleFilter(e)} />
        </div>
        <UncontrolledDropdown className="data-list-rows-dropdown d-md-block d-none">
          <DropdownToggle color="" className="sort-dropdown">
            <span className="align-middle mx-50">
              {`${props.index[0]} - ${props.index[1]} of ${props.total}`}
            </span>
            <ChevronDown size={15} />
          </DropdownToggle>
          <DropdownMenu tag="div" right>
            <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(10)}>
              10
            </DropdownItem>
            <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(20)}>
              20
            </DropdownItem>
            <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(props.total)}>
              모두보기
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  )
}

class DataListConfig extends React.Component{
  static getDerivedStateFromProps(props, state) {
    if (
      props.callHistory.displayList.length !== state.data.length ||
      state.currentPage !== props.parsedFilter.page
    ) {
      return {
        data: props.callHistory.displayList,
        allData: props.callHistory.filteredData,
        totalPages: props.callHistory.totalPages,
        currentPage: parseInt(props.parsedFilter.page) - 1,
        rowsPerPage: parseInt(props.parsedFilter.perPage),
        totalRecords: props.callHistory.totalRecords,
        sortIndex: props.callHistory.sortIndex
      }
    }

    // Return null if the state hasn't changed
    return null
  }

  state = {
    apiLoaded: false,
    data: [],
    allData: [],
    totalPages: 0,
    currentPage: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    totalRecords: 0,
    sortIndex: [],
    columns: [
      {
        name: "No",
        selector: "rowNum",
        sortable: true,
        width: "55px",
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.rowNum}</span>
        )
      },
      {
        name: "관리자확인",
        selector: "adminCheck",
        sortable: false,
        width: "100px",
        center: true,
        cell: row => (
          <CustomInput
            type="switch"
            id={`switch-${row.seq}`}
            className={'margin0auto'}
            name="customSwitch"
            inline
            checked={row.adminCheck}
            onChange={this.handleChangeEnabled(row)}
          />
        )
      },
      {
        name: "차량번호",
        selector: "accountId",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.accountId} className="text-truncate text-bold-100 mb-0 row-font-size">
            {row.accountId}
          </p>
        )
      },
      {
        name: "호출시간",
        selector: "createdAt",
        sortable: true,
        width: "160px",
        center: true,
        cell: row => (
          <p title={row.createdAt} className="text-truncate text-bold-100 mb-0 row-font-size">
            {row.createdAt}
          </p>
        )
      },
      {
        name: "이름",
        selector: "name",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.name} className="text-truncate text-bold-100 mb-0 row-font-size">
            {row.name}
          </p>
        )
      },
      {
        name: "연락처",
        selector: "phone",
        sortable: true,
        width: "140px",
        center: true,
        cell: row => (
          <p title={row.phone} className="text-truncate text-bold-100 mb-0 row-font-size">
            {row.phone}
          </p>
        )
      },
      {
        name: "호출 메시지",
        selector: "message",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.message} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.message}
          </p>
        ),
        minWidth: '300px'
      }
    ],
    value: '',
    isShow: {
      editCar: false,
      addNew: false,
    },
    currentData: null,
    selected: [],
  }

  async componentDidMount() {
    await this.props.fetchCallHistory(this.props.parsedFilter)
    await this.handleRowsPerPage(this.state.totalRecords)
    this.setState({ apiLoaded: true })
    this.changeRowSize()
  }

  componentDidUpdate() {
    this.changeRowSize()
  }

  changeRowSize = () => {
    const $els = document.querySelectorAll('[role="row"]')
    $els.forEach($el => $el.setAttribute("style", "min-height: 30px;"));
    this.changeHeaderFontSize();
  }

  changeHeaderFontSize = () => {
    const $els = document.querySelectorAll('.rdt_TableCol_Sortable')
    $els.forEach($el => $el.setAttribute("style", "font-size: 12px;"));
  }

  handleDelete = async obj => {
    if (window.confirm('삭제 하겠습니까?')) {
      await this.props.deleteCallHistory(obj.seq)
      await this.props.fetchCallHistory(this.props.parsedFilter)
    }
  }

  handleChangeEnabled = (row) => async (event) => {
    const ele = event.target
    const enabled = ele.checked
    try {
      await this.props.changeEnabled(row.seq, enabled)
    } catch (e) {
      ele.checked = !enabled
    }
  }

  handleCurrentData = obj => {
    this.setState({ currentData: obj })
    this.handleEditSidebar(true)
  }

  handleFilter = e => {
    this.setState({ value: e.target.value })
    this.props.filterList(e.target.value)
  }

  handleRowsPerPage = value => {
    let { parsedFilter, changeListOption } = this.props
    // let page = parsedFilter.page !== undefined ? parsedFilter.page : 1
    // history.replace(`/callHistory/list?page=${page}&perPage=${value}`)
    history.replace(`/callHistory/list?page=1&perPage=${value}`)
    this.setState({ rowsPerPage: value, currentPage: 0 })
    changeListOption({ page: 1, perPage: value })
  }

  handlePagination = page => {
    let { parsedFilter, changeListOption } = this.props
    let perPage = parsedFilter.perPage !== undefined ? parsedFilter.perPage : DEFAULT_ROWS_PER_PAGE
    history.replace(`/callHistory/list?page=${page.selected + 1}&perPage=${perPage}`)
    changeListOption({ page: page.selected + 1, perPage: perPage })
    this.setState({ currentPage: page.selected })
  }

  updateEditCar = (row, { name, phone, principal, ton, volume }) => {
     const { seq } = row
     this.props.updateCarArea({ seq, name, phone, principal, ton, volume })
     this.handleEditSidebar(false)
   }

  handleHideSidebar = (boolean) => {
    this.setState({ isShow: { addNew: boolean, editCar: boolean } })
  }

  handleEditSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, editCar: boolean } })
  }

  handleAddNewSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, addNew: boolean } })
  }

   createCarArea = async (addNewData) => {
     this.setState({ apiLoaded: false })
     await this.props.addNewCarManage(addNewData)
     await this.props.fetchCallHistory(this.props.parsedFilter)
     this.setState({ apiLoaded: true })
     this.handleAddNewSidebar(false)
   }

   handleRefresh = async () => {
    // this.setState({ apiLoaded: false })
    await this.props.fetchCallHistory(this.props.parsedFilter)
    // this.setState({ apiLoaded: true })
   }

  render(){
    let {
      apiLoaded, columns, data, allData, isShow, currentData, rowsPerPage,
      totalRecords, sortIndex, totalPages, value
    } = this.state

    const dataList = (value.length ? allData : data).map((d, idx) => ({...d, rowNum: idx+1}))

    return !apiLoaded ? (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    ) : (
      <div className="list-grid-style-small m-2">
        <CustomHeader
          handleSidebar={this.handleAddNewSidebar}
          handleFilter={this.handleFilter}
          handleRowsPerPage={this.handleRowsPerPage}
          handleAddNewSidebar={this.handleAddNewSidebar}
          handleRefresh={this.handleRefresh}
          rowsPerPage={rowsPerPage}
          total={totalRecords}
          index={sortIndex}
        />
        <DataTable
          columns={columns}
          // data={value.length ? allData : data}
          data={dataList}
          noHeader
          //selectableRows
          responsive
          //pointerOnHover
          selectableRowsHighlight
          onSelectedRowsChange={data =>
            this.setState({ selected: data.selectedRows })
          }
          customStyles={selectedStyle}
          sortIcon={<ChevronDown />}
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={{
            color: "primary",
            icon: <Check className="vx-icon" size={12} />,
            label: "",
            size: "sm"
          }}
          noDataComponent={<div style={{padding: '24px'}}>데이터가 없습니다.</div>}
        />
        <ReactPaginate
          previousLabel={<ChevronLeft size={15} />}
          nextLabel={<ChevronRight size={15} />}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={totalPages}
          containerClassName="vx-pagination separated-pagination pagination-center pagination-sm mb-0 mt-2 list-style-pagination"
          activeClassName="active"
          forcePage={
            this.props.parsedFilter.page
              ? parseInt(this.props.parsedFilter.page - 1)
              : 0
          }
          onPageChange={page => this.handlePagination(page)}
        />
        <div className="data-list list-view">
          { isShow.editCar && (
            <EditCarSidebar
              data={currentData}
              handleSidebar={this.handleEditSidebar}
              updateEditCar={this.updateEditCar}
            />
          )}
          { isShow.addNew && (
            <AddNewSidebar
              handleSidebar={this.handleAddNewSidebar}
              createCarArea={this.createCarArea}
            />
          )}
          <div
            className={classnames("data-list-overlay", {
              show: isShow.addNew || isShow.editCar
            })}
            onClick={() => this.handleHideSidebar(false)}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    callHistory: state.callHistory
  }),
  {
    fetchCallHistory,
    addNewCarManage,
    updateCarArea,
    filterList,
    changeListOption,
    deleteCallHistory,
    changeEnabled,
  },
)(DataListConfig)

DataListConfig.propTypes = {
  parsedFilter: PropTypes.object
}
