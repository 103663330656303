const addPhoneHypen = (str='') => {
  str = str.replace(/[^0-9]/g, '');
  let tmp = '';
  if(str.length < 4) {
    return str;
  } else if (str.length < 7) {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3);
    return tmp;
  } else if(str.length < 11) {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3, 3);
    tmp += '-';
    tmp += str.substr(6);
    return tmp;
  } else {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3, 4);
    tmp += '-';
    tmp += str.substr(7);
    return tmp;
  }

  return str;
}

const addDateHypen = (str='') => {
  str = str.replace(/[^0-9]/g, '');
  let tmp = '';
  if(str.length < 3) {
    return str;
  } else if(str.length < 5) {
    tmp += str.substr(0, 2);
    tmp += '-';
    tmp += str.substr(2);
    return tmp;
  } else {
    tmp += str.substr(0, 2);
    tmp += '-';
    tmp += str.substr(2, 2);
    tmp += '-';
    tmp += str.substr(4, 2);
    return tmp;
  }

  return str;
}

const jhComma = function (inputNumber=0) {
  return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export { addPhoneHypen, addDateHypen, jhComma }
