import apiRequest from "@/configs/axiosConfig";
import { customerTypes } from "@/redux/reducers/customer";

export const fetchCustomerList = (params) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/point',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
        const numberingList = list.map((item, idx) => ({...item, rowNum: idx + 1}))
        dispatch({
          type: customerTypes.FETCH_CUSTOMER_LIST,
          payload: { list:numberingList, params, totalRecords: list.length }
        })
        dispatch(changeListOption(params))
        return numberingList
      })
  }
}

export const fetchCustomerListGrid = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/point',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
        const numberingList = list.map((item, idx) => ({...item, rowNum: idx + 1}))

        return numberingList
      })
  }
}

export const getCustomerList = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/point',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
        const numberingList = list.map((item, idx) => ({...item, rowNum: idx + 1}))
        return numberingList
      })
  }
}

export const changeListOption = (params) => {
  return (dispatch) => dispatch({
    type: customerTypes.CHANGE_LIST_OPTION,
    payload: { params }
  })
}

export const changeStatusCustomer = ({ seq, enabled }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: `/api/point/${seq}/status`,
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        if (response.data.response === true) {
          dispatch({
            type: customerTypes.CHANGE_STATUS,
            payload: { seq, enabled },
          })
        }
        return response
      })
  }
}

export const changeStatusCustomerGrid = ({ seq, enabled }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: `/api/point/${seq}/status`,
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        return response.data.response
      })
  }
}

export const updateMemoCustomer = ({ seq, memo }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/point/memo',
      data: { target: seq, memo },
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        if (response.data.response === true) {
          dispatch({
            type: customerTypes.UPDATE_MEMO,
            payload: { seq, memo },
          })
        }
        return response
      })
  }
}

export const updateMemoCustomerGrid = ({ seq, memo }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/point/memo',
      data: { target: seq, memo },
      rootState: getState(),
      dispatch,
    })
      .then(response => response)
  }
}

export const addNewCustomer = ({ shortName, address, memo, companyName, companyTel, taxAddress, companyEmail, companyOwnerName, companyPersonName, companyFax, companyType }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/point',
      data: { shortName, address, memo, companyName, companyTel, taxAddress, companyEmail, companyOwnerName, companyPersonName, companyFax, companyType },
      rootState: getState(),
      dispatch,
    }).then(res=>{
      if (res.data.response === true) {
        console.log("등록되었습니다.");
        window.location.reload()
      }
    })
  }
}

export const deleteCustomer = (target) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'delete',
      url: `/api/point/${target}`,
      rootState: getState(),
      dispatch,
    })
  }
}

export const editCustomer = (seq, { shortName, address, memo, companyName, companyTel, taxAddress, companyEmail,
  companyOwnerName, companyPersonName, companyFax, companyType
}) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: '/api/point',
      data: { target: seq, shortName, address, memo, companyName, companyTel, taxAddress, companyEmail, companyOwnerName, companyPersonName, companyFax, companyType },
      rootState: getState(),
      dispatch,
    }).then(()=>{
      window.alert("수정 되었습니다.");
    })
  }
}

export const editCustomerGrid = (seq, { shortName, address, memo, companyName, companyTel, taxAddress, companyEmail,
  companyOwnerName, companyPersonName, companyFax, companyType
}) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: '/api/point',
      data: { target: seq, shortName, address, memo, companyName, companyTel, taxAddress, companyEmail, companyOwnerName, companyPersonName, companyFax, companyType },
      rootState: getState(),
      dispatch,
    })
      .then((res)=> res)
      .catch(res => res)
  }
}

export const filterList = value => {
  return dispatch => dispatch({ type: customerTypes.FILTER_LIST, value })
}


export const exportPointExcel = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/excel/point/download',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        return response.data.response
      })
  }
}
