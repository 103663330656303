import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import { X } from "react-feather"
import { Label, Input, FormGroup, Button } from "reactstrap"
import Select from "react-select"

class AddNewSidebar extends Component {

  state = {
    show: false,
    target: undefined,
    downPoint: undefined,
    upPoint: undefined,
    ton: '',
    volume: '',
    products: '',
    itemProduct: null,
    iterateCount: 1,
    carNumberList: this.props.carList.map(item => ({...item, value: item.seq, label: item.accountId })),
    areaList: this.props.upDownPointList.map(item => ({...item, value: item.seq, label: item.shortName })),
    goodsList: this.props.goodsList.map(item => ({...item, value: item.itemName, label: item.itemName })),
  }

  componentDidMount() {
    this.setState({ show: true })
  }

  render() {
    const { show, target, downPoint, products, ton, upPoint, volume, iterateCount, carNumberList, areaList, goodsList, itemProduct } = this.state
    const { handleSidebar, createData, hideCarNumber, popupTitle } = this.props

    return (
      <div
        style={{height: `${window.innerHeight}px`}}
        className={classnames("data-list-sidebar", { show })}>
        <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
          <h4>{ popupTitle }</h4>
          <X className="cursor-pointer" size={20} onClick={() => handleSidebar(false)} />
        </div>
        <PerfectScrollbar
          className="data-list-fields px-2 mt-3"
          options={{ wheelPropagation:  true }}
        >
          { !hideCarNumber && (
            <FormGroup>
              <Label for="data-accountId">차량번호</Label>
              <Select
                className="React"
                classNamePrefix="select"
                name="clear"
                placeholder={'선택'}
                options={carNumberList}
                //isClearable={true}
                defaultValue={carNumberList.find(c => c.accountId === target)}
                onChange={(value) => this.setState({ target: value?.seq })}
                id="data-accountId"
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label for="data-upPoint">상차지</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={areaList}
              //isClearable={true}
              defaultValue={areaList.find(a => a.seq === upPoint)}
              onChange={(value) => this.setState({ upPoint: value?.seq })}
              id="data-upPoint"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-downPoint">하차지</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={areaList}
              //isClearable={true}
              defaultValue={areaList.find(a => a.seq === downPoint)}
              onChange={(value) => this.setState({ downPoint: value?.seq })}
              id="data-downPoint"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-products">운송물품</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={goodsList}
              //isClearable={true}
              defaultValue={goodsList.find(c => c.itemName === products)}
              onChange={(value) => this.setState({ products: value?.itemName, itemProduct: value?.seq })}
              id="data-products"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-iterate-count">반복횟수</Label>
            <Input
              type="number"
              min="1"
              value={iterateCount}
              placeholder="반복횟수"
              onChange={e => this.setState({ iterateCount: e.target.value })}
              id="data-iterate-count"
            />
          </FormGroup>
        </PerfectScrollbar>
        <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
          <Button color="primary" onClick={() => createData({ target, downPoint, products, ton, upPoint, volume, iterateCount, itemProduct })}>
            등록
          </Button>
          <Button
            className="ml-1"
            color="danger"
            outline
            onClick={() => handleSidebar(false)}
          >
            취소
          </Button>
        </div>
      </div>
    )
  }
}

export default AddNewSidebar

AddNewSidebar.propTypes = {
  handleSidebar: PropTypes.func,
  createData: PropTypes.func,
  upDownPointList: PropTypes.array,
  carList: PropTypes.array,
}
