import produce from 'immer'
import { DEFAULT_ROWS_PER_PAGE } from '@/views/pages/DmGroup/DataListConfig'

export const dmGroupExpressTypes = {
  FETCH_DM_GROUP_LIST: 'dmGroup/EXPRESS/FETCH_DM_GROUP_LIST',
  CHANGE_LIST_OPTION: 'dmGroup/EXPRESS/CHANGE_LIST_OPTION',
  FILTER_LIST: 'dmGroup/EXPRESS/FILTER_LIST',
}

const initialState = {
  list: [], // [{address, companySeq, devMemo, enabled, memo, remove, seq, shortName, writer}, ...]
  displayList: [],
  params: null,
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: []
}

const dmGroupExpress = (state = initialState, action) => {
  switch (action.type) {
    case dmGroupExpressTypes.FETCH_DM_GROUP_LIST:
      return produce(state, draftState => {
        const { list, totalRecords, params } = action.payload
        const { page = 1, perPage = DEFAULT_ROWS_PER_PAGE } = params
        draftState.displayList = list.slice(page - 1, perPage)
        draftState.list = list
        draftState.totalRecords = totalRecords
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / (params.perPage || DEFAULT_ROWS_PER_PAGE))
      })
    case dmGroupExpressTypes.CHANGE_LIST_OPTION:
      return produce(state, draftState => {
        const { list, totalRecords } = state
        const { params } = action.payload
        const { page = 1, perPage = DEFAULT_ROWS_PER_PAGE } = params
        const perPageNum = Number(perPage)
        const startIdx = (page - 1) * perPageNum
        const endIdx = startIdx + perPageNum
        draftState.displayList = list.slice(startIdx, endIdx)
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / perPage)
        draftState.sortIndex = [startIdx + 1, endIdx > totalRecords ? totalRecords : endIdx]
      })
    case dmGroupExpressTypes.FILTER_LIST:
      return produce(state, draftState => {
        const { value } = action
        if (!value) {
          draftState.filteredData = draftState.displayList
        } else {
          draftState.filteredData = state.list
            .filter(item => {
              let startsWithCondition =
                String((item.dailyTotalSize || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.downPoint || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.products || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.status || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.target || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.ton || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.upPoint || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.volume || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.affiliation || '')).toLowerCase().startsWith(value.toLowerCase())

              let includesCondition =
                String((item.dailyTotalSize || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.downPoint || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.products || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.status || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.target || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.ton || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.upPoint || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.volume || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.affiliation || '')).toLowerCase().includes(value.toLowerCase())

              if (startsWithCondition) {
                return startsWithCondition
              } else if (!startsWithCondition && includesCondition) {
                return includesCondition
              } else {
                return null
              }
            })
            .slice((state.params.page || 1) - 1, (state.params.perPage || DEFAULT_ROWS_PER_PAGE))
        }
      })
    default:
      return state
  }
}

export default dmGroupExpress
