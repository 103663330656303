import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react'
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { store, persistor } from "./redux/storeConfig/store"
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
import "./index.scss"
import "./@fake-db"

import { LicenseManager } from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey(`CompanyName=wisdomh,LicensedApplication=baecha,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-012742,ExpiryDate=6_January_2022_[v2]_MTY0MTQyNzIwMDAwMA==5c99296ce6cb6919d50caf635fcee0c3`);

const LazyApp = lazy(() => import("./App"))


// configureDatabase()
ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<div />}>
          <Layout>
              <LazyApp />
          </Layout>
        </Suspense>
      </PersistGate>
    </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
