import produce from 'immer'
import { DEFAULT_ROWS_PER_PAGE } from '@/views/pages/DmGroup/DataListConfig'

export const dmGroupTypes = {
  FETCH_DM_GROUP_LIST: 'dmGroup/FETCH_DM_GROUP_LIST',
  CHANGE_LIST_OPTION: 'dmGroup/CHANGE_LIST_OPTION',
  FILTER_LIST: 'dmGroup/FILTER_LIST',
  ADD_CHECKBOX_LIST: 'dmGroup/ADD_CHECKBOX_LIST',
  REMOVE_CHECKBOX_LIST: 'dmGroup/REMOVE_CHECKBOX_LIST',
  REMOVE_CHECKBOX_LIST_BY_TARGET_SEQ: 'dmGroup/REMOVE_CHECKBOX_LIST_BY_TARGET_SEQ',
  RESET_CHECKBOX_LIST: 'dmGroup/RESET_CHECKBOX_LIST',
  SET_GRID_ROW_DATA: 'dmGroup/SET_GRID_ROW_DATA',

  SET_GRID_RESERVATION_DATA: 'dmGroup/SET_GRID_RESERVATION_DATA',
  ADD_RESERVATION_CHECKBOX_LIST: 'dmGroup/ADD_RESERVATION_CHECKBOX_LIST',
  REMOVE_RESERVATION_CHECKBOX_LIST: 'dmGroup/REMOVE_RESERVATION_CHECKBOX_LIST',
  REMOVE_RESERVATION_CHECKBOX_LIST_BY_TARGET_SEQ: 'dmGroup/REMOVE_RESERVATION_CHECKBOX_LIST_BY_TARGET_SEQ',
  RESET_RESERVATION_CHECKBOX_LIST: 'dmGroup/RESET_RESERVATION_CHECKBOX_LIST',
}

const initialState = {
  list: [], // [{address, companySeq, devMemo, enabled, memo, remove, seq, shortName, writer}, ...]
  displayList: [],
  params: null,
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  checkedList: [], // FOR GRID
  gridRowData: [],
  gridReservationData: [], // For Reservation
  reservationCheckedList: [], // For Reservation
}

const dailyCarDispatching = (state = initialState, action) => {
  switch (action.type) {
    case dmGroupTypes.FETCH_DM_GROUP_LIST:
      return produce(state, draftState => {
        const { list, totalRecords, params } = action.payload
        const { page = 1, perPage = DEFAULT_ROWS_PER_PAGE } = params
        draftState.displayList = list.slice(page - 1, perPage)
        draftState.list = list
        draftState.totalRecords = totalRecords
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / (params.perPage || DEFAULT_ROWS_PER_PAGE))
      })
    case dmGroupTypes.CHANGE_LIST_OPTION:
      return produce(state, draftState => {
        const { list, totalRecords } = state
        const { params } = action.payload
        const { page = 1, perPage = DEFAULT_ROWS_PER_PAGE } = params
        const perPageNum = Number(perPage)
        const startIdx = (page - 1) * perPageNum
        const endIdx = startIdx + perPageNum
        draftState.displayList = list.slice(startIdx, endIdx)
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / perPage)
        draftState.sortIndex = [startIdx + 1, endIdx > totalRecords ? totalRecords : endIdx]
      })
    case dmGroupTypes.FILTER_LIST:
      return produce(state, draftState => {
        const { value } = action
        if (!value) {
          draftState.filteredData = draftState.displayList
        } else {
          draftState.filteredData = state.list
            .filter(item => {
              let startsWithCondition =
                String((item.dailyTotalSize || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.downPoint || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.products || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.status || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.target || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.ton || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.upPoint || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.volume || '')).toLowerCase().startsWith(value.toLowerCase()) ||
                String((item.affiliation || '')).toLowerCase().startsWith(value.toLowerCase())

              let includesCondition =
                String((item.dailyTotalSize || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.downPoint || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.products || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.status || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.target || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.ton || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.upPoint || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.volume || '')).toLowerCase().includes(value.toLowerCase()) ||
                String((item.affiliation || '')).toLowerCase().includes(value.toLowerCase())

              if (startsWithCondition) {
                return startsWithCondition
              } else if (!startsWithCondition && includesCondition) {
                return includesCondition
              } else {
                return null
              }
            })
            .slice((state.params.page || 1) - 1, (state.params.perPage || DEFAULT_ROWS_PER_PAGE))
        }
      })
    case dmGroupTypes.ADD_CHECKBOX_LIST:
      return produce(state, draftState => {
        const { item } = action.payload
        const filteredList = draftState.checkedList.filter(l => l.seq !== item.seq)
        draftState.checkedList = [...filteredList, item]
      })
    case dmGroupTypes.REMOVE_CHECKBOX_LIST:
      return produce(state, draftState => {
        const { item } = action.payload
        draftState.checkedList = draftState.checkedList.filter(l => l.seq !== item.seq)
      })
    case dmGroupTypes.REMOVE_CHECKBOX_LIST_BY_TARGET_SEQ:
      // console.loog('REMOVE_CHECKBOX_LIST_BY_TARGET_SEQ')
      // TODO: Memo(220630) - 하위 데이터의 상태값 변경(도착 등) 으로 인하 체크가 풀리는 현상.
      //  filter 조건을 === 으로 하면 해결 되지만, 다른 기능 확인 필요.
      //  !== 인 경우(기존), Expand 후 체크, 다시 접을 경우 체크가 리셋됨. (기존의 의도한 내용인듯.)
      //  조건을 === 로 변경할 경우, 현내 문제 해결되지만, 다른쪽에 영향이 발생할 수 있음. 검토 필요.
      return produce(state, draftState => {
        const { targetSeq } = action.payload
        const filteredList = draftState.checkedList.filter(l => l.targetSeq !== targetSeq)
        draftState.checkedList = [...filteredList]
      })
    case dmGroupTypes.RESET_CHECKBOX_LIST:
      return produce(state, draftState => {
        draftState.checkedList = []
      })

    case dmGroupTypes.SET_GRID_ROW_DATA:
      return produce(state, draftState => {
        const { gridRowData } = action.payload
        draftState.gridRowData = gridRowData
      })

    /** Reservation **/
    case dmGroupTypes.SET_GRID_RESERVATION_DATA:
      return produce(state, draftState => {
        const { gridReservationData } = action.payload
        draftState.gridReservationData = gridReservationData
      })

    case dmGroupTypes.ADD_RESERVATION_CHECKBOX_LIST:
      return produce(state, draftState => {
        const { item } = action.payload
        const filteredList = draftState.reservationCheckedList.filter(l => l.seq !== item.seq)
        draftState.reservationCheckedList = [...filteredList, item]
      })
    case dmGroupTypes.REMOVE_RESERVATION_CHECKBOX_LIST:
      return produce(state, draftState => {
        const { item } = action.payload
        draftState.reservationCheckedList = draftState.reservationCheckedList.filter(l => l.seq !== item.seq)
      })
    case dmGroupTypes.REMOVE_RESERVATION_CHECKBOX_LIST_BY_TARGET_SEQ:
      return produce(state, draftState => {
        const { targetSeq } = action.payload
        draftState.reservationCheckedList = draftState.reservationCheckedList.filter(l => l.targetSeq !== targetSeq)
      })
    case dmGroupTypes.RESET_RESERVATION_CHECKBOX_LIST:
      return produce(state, draftState => {
        draftState.reservationCheckedList = []
      })

    default:
      return state
  }
}

export default dailyCarDispatching
