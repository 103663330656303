import React, { Component } from "react"
import PropTypes from "prop-types"
import { Input, Button, FormGroup, Label } from 'reactstrap'
import { X } from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import classnames from "classnames"

class EditCarSidebar extends Component {

  state = {
    show: false,
    name: this.props.data.name,
    phone: this.props.data.phone,
    principal: this.props.data.accountId,
    ton: this.props.data.ton,
    volume: this.props.data.volume,
  }

  componentDidMount() {
    this.setState({ show: true })
  }

  handleSubmit = () => {
    const { data, updateEditCar } = this.props
    updateEditCar(data, this.state)
  }

  render() {
    const { show, name, phone, principal, ton, volume } = this.state
    const { handleSidebar } = this.props
    return (
      <div
        className={classnames("data-list-sidebar", { show })}>
        <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
          <h4>메모</h4>
          <X className="cursor-pointer" size={20} onClick={() => handleSidebar(false)} />
        </div>
        <PerfectScrollbar
          className="data-list-fields px-2 mt-3"
          options={{ wheelPropagation: false }}
        >
          <FormGroup>
            <Label for="data-principal">차량번호</Label>
            <Input
              type="text"
              value={principal}
              placeholder="차량번호"
              onChange={e => this.setState({ principal: e.target.value })}
              id="data-principal"
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-name">이름</Label>
            <Input
              type="text"
              value={name}
              placeholder="이름"
              onChange={e => this.setState({ name: e.target.value })}
              id="data-name"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-phone">연락처</Label>
            <Input
              type="text"
              value={phone}
              placeholder="연락처"
              onChange={e => this.setState({ phone: e.target.value })}
              id="data-phone"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-ton">톤수</Label>
            <Input
              type="text"
              value={ton}
              placeholder="톤수"
              onChange={e => this.setState({ ton: e.target.value })}
              id="data-ton"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-volume">용적</Label>
            <Input
              type="text"
              value={volume}
              placeholder="용적"
              onChange={e => this.setState({ volume: e.target.value })}
              id="data-volume"
            />
          </FormGroup>
        </PerfectScrollbar>
        <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
          <Button color="primary" onClick={() => this.handleSubmit(this.state)}>
            수정
          </Button>
          <Button
            className="ml-1"
            color="danger"
            outline
            onClick={() => handleSidebar(false)}
          >
            취소
          </Button>
        </div>
      </div>
    )
  }
}

export default EditCarSidebar

EditCarSidebar.propTypes = {
  data: PropTypes.object,
  updateEditCar: PropTypes.func,
  handleSidebar: PropTypes.func,
}
