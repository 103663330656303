import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import { X } from "react-feather"
import { Label, Input, FormGroup, Button } from "reactstrap"
import Select from 'react-select'

import { addPhoneHypen } from '@/libs/phoneLib'

export const CAR_TYPE_LIST = [
  { value: '일당', label: '일당' },
  { value: '횟수', label: '횟수' },
  { value: '루베', label: '루베' },
]

export const CAR_TYPE2_LIST = [
  { value: '용차', label: '용차' },
  { value: '자차', label: '자차' },
  { value: '분할', label: '분할' },
  { value: '지입', label: '지입' },
]

class MemoSidebar extends Component {

  state = {
    show: false,
    name: '',
    phone: '',
    principal: '',
    ton: '',
    volume: '',
    carType: CAR_TYPE_LIST[0].value,
    carType2: CAR_TYPE2_LIST[0].value,
    carYear: '',
    carBrand: '',
    affiliation: '',
    target: '',
    password: '',
  }

  componentDidMount() {
    this.setState({ show: true })
  }

  render() {
    let { show, name, phone, principal, ton, volume, carType, carType2, carYear, affiliation, password, target, carBrand } = this.state
    let { handleSidebar, createCarArea } = this.props
    return (
      <div
        style={{height: `${window.innerHeight}px`}}
        className={classnames("data-list-sidebar", { show })}>
        <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
          <h4>등록</h4>
          <X className="cursor-pointer" size={20} onClick={() => handleSidebar(false)} />
        </div>
        <PerfectScrollbar
          className="data-list-fields px-2 mt-3"
          options={{ wheelPropagation: false }}
        >
          <FormGroup>
            <Label for="data-affiliation">아이디 <span className={'required-mark'}>*</span></Label>
            <Input
              type="text"
              value={principal}
              placeholder="아이디"
              onChange={e => this.setState({ principal: e.target.value })}
              id="data-login-id"
            />
          </FormGroup>
          {/*<FormGroup>*/}
          {/*  <Label for="data-affiliation">패스워드</Label>*/}
          {/*  <Input*/}
          {/*    type="text"*/}
          {/*    value={password}*/}
          {/*    placeholder="패스워드"*/}
          {/*    onChange={e => this.setState({ password: e.target.value })}*/}
          {/*    id="data-password"*/}
          {/*  />*/}
          {/*</FormGroup>*/}
          <FormGroup>
            <Label for="data-principal">차량번호 <span className={'required-mark'}>*</span></Label>
            <Input
              type="text"
              value={target}
              placeholder="차량번호"
              onChange={e => this.setState({ target: e.target.value })}
              id="data-principal"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-affiliation">소속</Label>
            <Input
              type="text"
              value={affiliation}
              placeholder="소속"
              onChange={e => this.setState({ affiliation: e.target.value })}
              id="data-affiliation"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-name">이름</Label>
            <Input
              type="text"
              value={name}
              placeholder="이름"
              onChange={e => this.setState({ name: e.target.value })}
              id="data-name"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-phone">연락처</Label>
            <Input
              type="text"
              value={phone}
              placeholder="연락처"
              onChange={e => this.setState({ phone: addPhoneHypen(e.target.value) })}
              id="data-phone"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-carType">차량구분</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={CAR_TYPE2_LIST}
              //isClearable={true}
              defaultValue={CAR_TYPE2_LIST.find(c => c.value === carType2)}
              onChange={(value) => this.setState({ carType2: value?.value })}
              id="data-carType"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-carType">운송구분</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={CAR_TYPE_LIST}
              //isClearable={true}
              defaultValue={CAR_TYPE_LIST.find(c => c.value === carType)}
              onChange={(value) => this.setState({ carType: value?.value })}
              id="data-carType"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-ton">톤수</Label>
            <Input
              type="text"
              value={ton}
              placeholder="톤수"
              onChange={e => this.setState({ ton: e.target.value })}
              id="data-ton"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-volume">용적(루베)</Label>
            <Input
              type="text"
              value={volume}
              placeholder="용적"
              onChange={e => this.setState({ volume: e.target.value })}
              id="data-volume"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-carYear">차량연식</Label>
            <Input
              type="text"
              value={carYear}
              placeholder="차량연식"
              onChange={e => this.setState({ carYear: e.target.value })}
              id="data-carYear"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-car-brand">모델</Label>
            <Input
              type="text"
              value={carBrand}
              placeholder="모델"
              onChange={e => this.setState({ carBrand: e.target.value })}
              id="data-car-brand"
            />
          </FormGroup>
        </PerfectScrollbar>
        <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
          <Button color="primary" onClick={() => createCarArea(this.state)}>
            등록
          </Button>
          <Button
            className="ml-1"
            color="danger"
            outline
            onClick={() => handleSidebar(false)}
          >
            취소
          </Button>
        </div>
      </div>
    )
  }
}

export default MemoSidebar

MemoSidebar.propTypes = {
  handleSidebar: PropTypes.func,
  createCustomer: PropTypes.func,
}
