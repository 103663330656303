import React, { Component, Fragment } from "react"
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import { Input, Button, FormGroup, Label, Card, CardBody } from 'reactstrap'
import {Check, Plus, X} from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import classnames from "classnames"
import Select from 'react-select'
import { v4 as uuidv4 } from "uuid";

import _ from "fxjs/Strict"
import L from "fxjs/Lazy"

import {
  getDownPointListByUpPoint,
  getGoodsByUpDownPoints,
} from '@/redux/actions/dmGroup'

class EditSidebar extends Component {

  state = {
    show: false,
    target: this.props.data.length > 1 ? '' : this.props.data[0].target,
    dmPoints: [{ seq: uuidv4(), upPoint: '', downPoint: '', productInfo: { product: '', itemProduct: null }, iterateCount: 1, hierarchy: false, areaList: [], goodsList: [], }],
    // iterateCount: 1,
    //carNumberList: this.props.carList.map(item => ({...item, value: item.accountId, label: item.accountId })),
    areaList: [],
    goodsList: [],
    targetList: [],
    firstCheck: false,
    hours: '',
    mins: '',
    btnFocus: false,
  }

  componentDidMount() {
    this.setState({
      show: true,
      areaList: this.props.upDownPointList.map(item => ({...item, value: item.seq, label: item.shortName })),
      goodsList: this.props.goodsList.map(item => ({...item, value: item.itemName, label: item.itemName })),
      targetList: this.props.data.map(item => ({...item, value: item.target, label: item.target })),
    })
  }

  handleSubmit = () => {
    const { dmPoints, firstCheck, hours, mins } = this.state
    const { data, updateData } = this.props

    const h = parseInt(hours || 0)
    const m = parseInt(mins || 0)
    if(firstCheck) {
      if(hours === '' || mins === '') {
        alert('첫 배차 등록을 체크한 경우 시, 분단위를 모두 입력해주세요.')
        return
      }

      if(h < 0 || h > 23) {
        window.alert('시 단위는 0-23까지 입력 가능합니다.')
        return
      }


      if(m < 0 || m > 59) {
        window.alert('분 단위는 0-59까지 입력 가능합니다.')
        return
      }
    }

    for(const p of dmPoints) {
      if(p.downPoint === '' || p.upPoint === '' || p.productInfo?.product ==='' || p.productInfo?.itemProduct === null || p.iterateCount === '') {
        alert('모두 작성해 주세요.')
        return
      }
    }

    const firstTime = firstCheck ? `${h > 9 ? h : `0${h}`}:${m > 9 ? m : `0${m}`}` : null

    const nDmPoints = _.go(dmPoints,
      _.map(({seq, productInfo, ...p}) => ({...p, ...productInfo, iterateCount: parseInt(p.iterateCount)})),)

    const maxIter = _.go(nDmPoints,
      L.map(p => p.iterateCount),
      _.reduce((a, b) => a > b ? a : b)
    )

    const tmpDmPoints = []
    for(let i=0; i<maxIter; i++) {
      for(let j=0; j<nDmPoints.length; j++) {
        if(nDmPoints[j].iterateCount > 0) {
          nDmPoints[j].iterateCount--
          tmpDmPoints.push(nDmPoints[j])
        }
      }
    }

    updateData(data, { firstTime,  dmPoints: tmpDmPoints.map(({upPoint, downPoint, itemProduct, product}) => ({upPoint, downPoint, itemProduct, product}))});
  }

  onToggleAutoInput = (seq, flag) => {
    const newPoints = this.state.dmPoints.map(p => p.seq !== seq ? ({...p}) : ({
      ...p, hierarchy: flag,
      upPoint: '', downPoint: '', productInfo: { product: '', itemProduct: null },
      areaList: [], goodsList: []
    }))
    this.setState({ dmPoints: newPoints })
  }

  onChangeUpPointbyAutoInput = async (seq, upPointSeq) => {
    const downPointList = await this.props.getDownPointListByUpPoint(upPointSeq)
    const newPoints = this.state.dmPoints.map(p => p.seq !== seq ? ({...p}) : ({
      ...p,
      upPoint: upPointSeq, downPoint: '', productInfo: { product: '', itemProduct: null },
      areaList: downPointList.map(item => ({...item, value: item.downPoint, label: item.shortName })),
      goodsList: []
    }))
    this.setState({ dmPoints: newPoints })
  }

  onChangeDownPointbyAutoInput = async (seq, upPointSeq, downPointSeq) => {
    const goodsList = await this.props.getGoodsByUpDownPoints(upPointSeq, downPointSeq)
    const newPoints = this.state.dmPoints.map(p => p.seq !== seq ? ({...p}) : ({
      ...p,
      downPoint: downPointSeq, productInfo: { product: '', itemProduct: null },
      goodsList: goodsList.map(item => ({...item, value: item.itemProduct, label: `${item.itemName} (${item.carType})` })),
    }))
    this.setState({ dmPoints: newPoints })
  }

  onChangePoint = (seq, k) => v => {
    const newPoints = this.state.dmPoints.map(p => p.seq !== seq ? ({...p}) : ({...p, [k]: v}))
    this.setState({ dmPoints: newPoints })
  }

  onAddPoint = () => {
    const newPoints = [
      ...this.state.dmPoints,
      { seq: uuidv4(), upPoint: '', downPoint: '', productInfo: { product: '', itemProduct: null }, iterateCount: 1, hierarchy: false, areaList: [], goodsList: [], }
    ]
    this.setState({ dmPoints: newPoints })
  }

  onRemovePoint = seq => {
    if(this.state.dmPoints.length === 1) {
      window.alert('최소 한개의 상하차지 데이터는 필수입니다.')
      return
    }
    const newPoints = this.state.dmPoints.filter(p => p.seq !== seq)
    this.setState({ dmPoints: newPoints })
  }

  onChangeFirstCheck = (nextFlag) => {
    if(nextFlag) {
      this.setState({ firstCheck: true })
    } else {
      this.setState({
        firstCheck: false,
        hours: '',
        mins: ''
      })
    }
  }

  onChangeHours = h => {
    if(h.length >=3) return
    if(isNaN(h)) return

    this.setState({ hours: h })
  }

  onChangeMins = m => {
    if(m.length >=3) return
    if(isNaN(m)) return

    this.setState({ mins: m })
  }

  render() {
    const {
      show, target, products, dmPoints, areaList, goodsList, targetList,
      firstCheck, hours, mins,
    } = this.state
    let { handleSidebar } = this.props

    return (
      <div
        style={{height: `${window.innerHeight}px`}}
        className={classnames("data-list-sidebar", { show })}>
        <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
          <h4>일일 배차 다중배차</h4>
          <X className="cursor-pointer" size={20} onClick={() => handleSidebar(false)} />
        </div>
        <PerfectScrollbar
          className="data-list-fields px-2 mt-1"
          options={{ wheelPropagation: true }}
        >
          <div className="d-flex justify-content-end align-items-center mb-1">
            <Button
              className="ml-1"
              color="primary"
              outline
              onClick={this.onAddPoint}
            >
              추가
            </Button>
          </div>

          <FormGroup>
            <Label for="data-upPoint">선택 차량 리스트</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택 차량 리스트'}
              options={targetList}
              //isClearable={true}
              value={targetList[0]}
              onChange={() => {}}
              id={`data-target-list`}
            />
          </FormGroup>
          <FormGroup check className={'mt-1 mb-1'}>
            <Input
              type="checkbox" name="check" id={`auto-input-check`} checked={firstCheck}
              onChange={() => this.onChangeFirstCheck(!firstCheck)}
            />
            <Label for={`auto-input-check`} check>첫 배차 입력</Label>
            {firstCheck ? <Label for="data-principal">- 첫 배차 시간 (00:00 - 23:59)</Label> : null}
          </FormGroup>
          {firstCheck ?
            <FormGroup className={'mb-1'}>
              <div className={'d-flex'}>
                <Input
                  className={'width-15-per'}
                  type="number"
                  min="1"
                  value={hours}
                  placeholder="시"
                  onChange={e => this.onChangeHours(e.target.value)}
                  id="data-time-hours"
                />
                <span className={'padding-0d7-rem'}>:</span>
                <Input
                  className={'width-15-per'}
                  type="number"
                  min="1"
                  value={mins}
                  placeholder="분"
                  onChange={e => this.onChangeMins(e.target.value)}
                  id="data-time-mins"
                />
              </div>
            </FormGroup> : null}
          <div>
          {dmPoints.map((p, idx) => (
            <Card key={`${p.seq}`} outline color={'black'}>
              <CardBody className={'p-1'}>
                <div className="d-flex justify-content-between mb-1">
                  <FormGroup check>
                    <Input type="checkbox" name="check" id={`auto-input-check-${p.seq}`} checked={p.hierarchy} onChange={() => {
                      this.onToggleAutoInput(p.seq, !p.hierarchy)
                    }}/>
                    <Label for={`auto-input-check-${p.seq}`} check>자동입력</Label>
                  </FormGroup>
                  <X className="cursor-pointer" size={20} onClick={() => this.onRemovePoint(p.seq)} />
                </div>
                {!p.hierarchy ?
                <Fragment>
                  <FormGroup>
                    <Label for="data-upPoint">상차지</Label>
                    <Select
                      className="React"
                      classNamePrefix="select"
                      name="clear"
                      placeholder={'선택'}
                      options={areaList}
                      //isClearable={true}
                      defaultValue={areaList.find(a => a.value === p.upPoint)}
                      onChange={value => this.onChangePoint(p.seq, 'upPoint')(value?.seq)}
                      id={`data-upPoint-${p.seq}`}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="data-downPoint">하차지</Label>
                    <Select
                      className="React"
                      classNamePrefix="select"
                      name="clear"
                      placeholder={'선택'}
                      options={areaList}
                      //isClearable={true}
                      defaultValue={areaList.find(a => a.value === p.downPoint)}
                      onChange={value => this.onChangePoint(p.seq, 'downPoint')(value?.seq)}
                      id={`data-downPoint-${p.seq}`}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="data-products">운송물품</Label>
                    <Select
                      className="React"
                      classNamePrefix="select"
                      name="clear"
                      placeholder={'선택'}
                      options={goodsList}
                      defaultValue={goodsList.find(c => c.itemName === p.productInfo.product)}
                      onChange={(value) => {
                        this.onChangePoint(p.seq, 'productInfo')({
                          product: value?.itemName,
                          itemProduct: value?.seq
                        })
                      }}
                      id={`data-products-${p.seq}`}
                    />
                  </FormGroup>
                </Fragment>
                  :
                <Fragment>
                  <FormGroup>
                    <Label for="data-upPoint">상차지</Label>
                    <Select
                      className="React"
                      classNamePrefix="select"
                      name="clear"
                      placeholder={'선택'}
                      options={areaList}
                      //isClearable={true}
                      defaultValue={areaList.find(a => a.value === p.upPoint)}
                      onChange={value => this.onChangeUpPointbyAutoInput(p.seq, value?.value)}
                      id={`data-upPoint-${p.seq}`}
                    />
                  </FormGroup>
                  {areaList
                    .filter(d => d.value === p.upPoint)
                    .map(d => (
                      <FormGroup key={d.value}>
                        <Label for="data-downPoint">하차지</Label>
                        <Select
                          className="React"
                          classNamePrefix="select"
                          name="clear"
                          placeholder={'선택'}
                          options={p.areaList}
                          defaultValue={p.areaList.find(a => a.value === p.downPoint)}
                          onChange={value => this.onChangeDownPointbyAutoInput(p.seq, p.upPoint, value?.value)}
                          id={`data-downPoint-${p.seq}`}
                        />
                      </FormGroup>
                    ))}
                  {p.areaList
                    .filter(d => d.value === p.downPoint)
                    .map(d => (
                      <FormGroup key={d.value}>
                        <Label for="data-products">운송물품</Label>
                        <Select
                          className="React"
                          classNamePrefix="select"
                          name="clear"
                          placeholder={'선택'}
                          options={p.goodsList}
                          defaultValue={p.goodsList.find(c => c.itemName === p.productInfo.product)}
                          onChange={(value) => {
                            this.onChangePoint(p.seq, 'productInfo')({
                              product: value?.itemName,
                              itemProduct: value?.value
                            })
                          }}
                          id={`data-products-${p.seq}`}
                        />
                      </FormGroup>
                    ))
                  }
                </Fragment>}
                <FormGroup>
                  <Label for="data-iterate-count">반복횟수</Label>
                  <Input
                    type="number"
                    min="1"
                    value={p.iterateCount}
                    placeholder="반복횟수"
                    onChange={e => this.onChangePoint(p.seq, 'iterateCount')(e.target.value)}
                    id={`data-iterate-count-${p.seq}`}
                  />
                </FormGroup>
              </CardBody>
            </Card>))}
          </div>

        </PerfectScrollbar>
        <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
          <Button
            // color="primary"
            className={`ok-btn fc ${this.state.btnFocus ? 'focusing' : ''}`}
            onFocus={() => this.setState({ btnFocus: true })}
            onBlur={() => this.setState({ btnFocus: false })}
            onClick={() => this.handleSubmit(this.state)}
          >
            등록
          </Button>
          <Button
            className="ml-1"
            color="danger"
            outline
            onClick={() => handleSidebar(false)}
          >
            취소
          </Button>
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  {
    getDownPointListByUpPoint,
    getGoodsByUpDownPoints,
  },
)(EditSidebar)

EditSidebar.propTypes = {
  data: PropTypes.array,
  updateData: PropTypes.func,
  handleSidebar: PropTypes.func,
}
