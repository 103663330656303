import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // localStorage
import storage from 'redux-persist/lib/storage/session' // sessionStorage
import customizer from './customizer/'
import auth from './auth/'
import navbar from './navbar/Index'
import customer from './customer'
import drivingFees from './drivingFees'
import carArea from './carArea'
import dmGroup from './dmGroup'
import dmGroupExpress from './dmGroupExpress'
import searchCarDispatching from './carDispatching'
import goods from './goods'
import callHistory from './callHistory'
import alert from './alert'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth'],
}

const rootReducer = combineReducers({
  customizer,
  auth,
  navbar,
  customer,
  drivingFees,
  carArea,
  dmGroup,
  dmGroupExpress,
  searchCarDispatching,
  goods,
  callHistory,
  alert,
})

export default persistReducer(persistConfig, rootReducer)
