import apiRequest from "@/configs/axiosConfig";
import { dmGroupTypes } from "@/redux/reducers/dmGroup";
import { goodsTypes } from '@/redux/reducers/goods'
import {carAreaTypes} from "@/redux/reducers/carArea";

export const fetchDmGroupList = (params) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/dm/group',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
        const numberingList = list.map((item, idx) => ({...item, rowNum: idx + 1}))
        dispatch({
          type: dmGroupTypes.FETCH_DM_GROUP_LIST,
          payload: { list: numberingList, params, totalRecords: list.length }
        })
        dispatch(changeListOption(params))
        return response
      })
  }
}

export const fetchTargetList = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/dm/group2',
      rootState: getState(),
      dispatch,
    })
      .then(response => response.data.response)
  }
}

export const changeListOption = (params) => {
  return (dispatch) => dispatch({
    type: dmGroupTypes.CHANGE_LIST_OPTION,
    payload: { params }
  })
}

export const applyCarDispatching = ({ groupSeq, firstTime, dmPoints, registerType }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/dm/group/daily',
      data: { groupSeq, firstTime, dmPoints, registerType },
      rootState: getState(),
      dispatch,
    })
  }
}

export const DeleteCarDispatchingList = ({ groupSeq }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'delete',
      url: '/api/dm/group',
      data: [...groupSeq] ,
      rootState: getState(),
      dispatch,
    })
  }
}

export const createDmGroup = ({ target }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/dm/group',
      data: { target },
      rootState: getState(),
      dispatch,
    })
  }
}

export const createDmDaily = ({ targets, downPoint, products, ton, upPoint, volume, groupSeq, iterateCount, itemProduct, registerType='BASIC' }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/dm/daily',
      data: { targets, downPoint, products, ton, upPoint, volume, groupSeq, iterateCount, itemProduct, registerType },
      rootState: getState(),
      dispatch,
    })
  }
}

export const createEmergency = ({ groupSeq, target, downPoint, products, itemProduct, upPoint, iterateCount, price }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/dm/emergency',
      data: { downPointAddress: downPoint, products, itemProduct, upPointAddress: upPoint, groupSeq, targetSeq: target, iterateCount, price },
      rootState: getState(),
      dispatch,
    })
  }
}

export const filterList = value => {
  return dispatch => dispatch({ type: dmGroupTypes.FILTER_LIST, value })
}

/**
 * goods actions 에 있는건데 다른곳에서 사용하려고 import 하면 오류가 발생한다.
 * 이유는 모르겠다.
 * 코드 복사해 와서 해결
 */
export const fetchGoodsListTemp = (params) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/goods',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
        dispatch({
          type: goodsTypes.FETCH_GOODS_LIST,
          payload: { list, params, totalRecords: list.length },
        })
        dispatch(changeListOption(params))
        return response
      })
  }
}

export const fetchSelectOptionCustomerList = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/point/box',
      rootState: getState(),
      dispatch,
    })
  }
}

export const fetchDmGroupDetail = (groupId) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: `/api/dm/${groupId}/daily`,
      rootState: getState(),
      dispatch,
    })
  }
}

export const deleteDmDaily = (dailySeq = []) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'delete',
      url: `/api/dm/daily`,
      data: dailySeq ,
      rootState: getState(),
      dispatch,
    })
  }
}

export const changeOrderDmDaily = (dailySeq = []) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: '/api/dm/daily/order',
      data: { dailySeq },
      rootState: getState(),
      dispatch,
    })
  }
}

export const updateDailyCar = ({ dailySeq, downPoint, products, upPoint, itemProduct }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: '/api/dm/daily',
      data: { dailySeq, downPoint, products, upPoint, itemProduct },
      rootState: getState(),
      dispatch,
    })
  }
}


export const updateFirstDispatching = ({groupSeq, time}) => (dispatch, getState) => apiRequest({
  method: 'post',
  url: '/api/dm/group/first',
  data: { groupSeq, time },
  rootState: getState(),
  dispatch,
})

export const deleteFirstDispatching = (groupSeq) => (dispatch, getState) => apiRequest({
  method: 'delete',
  url: `/api/dm/group/${groupSeq}/first`,
  rootState: getState(),
  dispatch,
})

/** DmGroup Auto Input Mode **/
export const getDownPointListByUpPoint = (upPointSeq) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: `/api/point/up/${upPointSeq}`,
      rootState: getState(),
      dispatch,
    })
      .then(res => res.data.response)
  }
}

export const getGoodsByUpDownPoints = (upPointSeq, downPointSeq) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: `/api/point/up/${upPointSeq}/down/${downPointSeq}`,
      rootState: getState(),
      dispatch,
    })
      .then(res => res.data.response)
  }
}


/** For loadSelectBoxData() **/
export const getCarAreaForSelect = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/users',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
        return list.map((item, idx) => ({...item, rowNum: idx + 1}))
      })
  }
}

export const getGoodsListForSelect = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/goods',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        return response.data.response
      })
  }
}

export const getCustomerListForSelect = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/point/box',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        return response.data.response
      })
  }
}

export const updateForcedArrival = (dailySeq) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: `/api/force/daily/${dailySeq}/arrival`,
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        return response.data.response
      })
  }
}

/** For Grid **/

export const fetchDmGroupListForGrid = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/dm/group',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = (response.data.response || [])
          .map((a, idx) => ({
          ...a,
          startTime: a.startTime === null ? '-' : a.startTime.split(' ')[1],
          upPoint: (a.upPoint || ' ').split('_')[0] === '긴급배차' ? '긴급배차_상차지' : a.upPoint,
          downPoint: (a.downPoint || ' ').split('_')[0] === '긴급배차' ? '긴급배차_하차지' : a.downPoint
        }))

        const dailyZero = list.filter(d => d.dailyTotalSize === 0)
        const dailyRest = list.filter(d => d.dailyTotalSize !== 0).sort((a, b) => a.dailyTotalSize > b.dailyTotalSize ? 1 : -1)
        return [...dailyRest, ...dailyZero]
      })
  }
}

export const fetchDmGroupListForGrid2 = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/dm/group',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = (response.data.response || [])
          .map((a, idx) => ({
            ...a,
            startTime: a.startTime === null ? '-' : a.startTime.split(' ')[1],
            upPoint: (a.upPoint || ' ').split('_')[0] === '긴급배차' ? '긴급배차_상차지' : a.upPoint,
            downPoint: (a.downPoint || ' ').split('_')[0] === '긴급배차' ? '긴급배차_하차지' : a.downPoint
          }))

        const dailyZero = list.filter(d => d.dailyTotalSize === 0)
        const dailyRest = list.filter(d => d.dailyTotalSize !== 0).sort((a, b) => a.dailyTotalSize > b.dailyTotalSize ? 1 : -1)
        dispatch({
          type: dmGroupTypes.SET_GRID_ROW_DATA,
          payload: { gridRowData: [...dailyRest, ...dailyZero] }
        })
      })
  }
}

export const fetchDmGroupDetailForGrid = (groupId) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: `/api/dm/${groupId}/daily`,
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
          .sort((a, b) => a.dispatchSequence - b.dispatchSequence)
          .map((item,idx) => ({
            ...item,
            rowNum: idx+1,
            id: String(item.seq),
            upPointName: item.upPointName.split('_')[0] === '긴급배차' ? '긴급배차_상차지' : item.upPointName,
            downPointName: item.downPointName.split('_')[0] === '긴급배차' ? '긴급배차_하차지' : item.downPointName,
          }))
        return ({[groupId]: list })
      })
  }
}

/** CHECKBOX LIST FOR GRID **/
export const addCheckBoxItem = (item) => {
  return (dispatch) => dispatch({
    type: dmGroupTypes.ADD_CHECKBOX_LIST,
    payload: { item }
  })
}

export const removeCheckedBoxItem = (item) => {
  return (dispatch) => dispatch({
    type: dmGroupTypes.REMOVE_CHECKBOX_LIST,
    payload: { item }
  })
}

export const removeCheckedBoxListByTargetSeq = (targetSeq) => {
  return (dispatch) => dispatch({
    type: dmGroupTypes.REMOVE_CHECKBOX_LIST_BY_TARGET_SEQ,
    payload: { targetSeq }
  })
}

export const resetCheckBoxList = () => {
  return (dispatch) => dispatch({
    type: dmGroupTypes.RESET_CHECKBOX_LIST,
  })
}

/**
 * Reservation
 * **/

/** 예약 배차 리스트 **/
export const fetchDmReservationListForGrid = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/dm/reservation',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = (response.data.response || [])
          .map((a, idx) => ({
            ...a,
            startTime: a.startTime === null ? '-' : a.startTime.split(' ')[1],
            upPoint: (a.upPoint || ' ').split('_')[0] === '긴급배차' ? '긴급배차_상차지' : a.upPoint,
            downPoint: (a.downPoint || ' ').split('_')[0] === '긴급배차' ? '긴급배차_하차지' : a.downPoint
          }))

        const dailyZero = list.filter(d => d.dailyTotalSize === 0)
        const dailyRest = list.filter(d => d.dailyTotalSize !== 0).sort((a, b) => a.dailyTotalSize > b.dailyTotalSize ? 1 : -1)
        return [...dailyRest, ...dailyZero]
      })
  }
}

/** 예약 배차 리스트2 **/
export const fetchDmReservationListForGrid2 = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/dm/reservation',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = (response.data.response || [])
          .map((a, idx) => ({
            ...a,
            startTime: a.startTime === null ? '-' : a.startTime.split(' ')[1],
            upPoint: (a.upPoint || ' ').split('_')[0] === '긴급배차' ? '긴급배차_상차지' : a.upPoint,
            downPoint: (a.downPoint || ' ').split('_')[0] === '긴급배차' ? '긴급배차_하차지' : a.downPoint
          }))

        const dailyZero = list.filter(d => d.dailyTotalSize === 0)
        const dailyRest = list.filter(d => d.dailyTotalSize !== 0).sort((a, b) => a.dailyTotalSize > b.dailyTotalSize ? 1 : -1)
        dispatch({
          type: dmGroupTypes.SET_GRID_RESERVATION_DATA,
          payload: { gridReservationData: [...dailyRest, ...dailyZero] }
        })
      })
  }
}

/** 예약 배차 상세 **/
export const fetchDmReservationDetail = (groupId) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: `/api/dm/${groupId}/reservation`,
      rootState: getState(),
      dispatch,
    })
  }
}

/** 예약 배차 상세2 **/
export const fetchDmReservationDetailForGrid = (groupId) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: `/api/dm/${groupId}/reservation`,
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
          .sort((a, b) => a.dispatchSequence - b.dispatchSequence)
          .map((item,idx) => ({
            ...item,
            rowNum: idx+1,
            id: String(item.seq),
            upPointName: item.upPointName.split('_')[0] === '긴급배차' ? '긴급배차_상차지' : item.upPointName,
            downPointName: item.downPointName.split('_')[0] === '긴급배차' ? '긴급배차_하차지' : item.downPointName,
          }))
        return ({[groupId]: list })
      })
  }
}

/** 예약 배차(그룹 다중배차) **/
export const reserveCarDispatching = ({ groupSeq, firstTime, dmPoints, registerType }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/dm/group/daily/reservation',
      data: { groupSeq, firstTime, dmPoints, registerType },
      rootState: getState(),
      dispatch,
    })
  }
}

/** 예약배차 첫 배차 삭제 **/
export const deleteReservationFirstDispatching = (groupSeq) => (dispatch, getState) => apiRequest({
  method: 'delete',
  url: `/api/dm/group/reservation/${groupSeq}/first`,
  rootState: getState(),
  dispatch,
})

/** 예약배차 첫 배차 **/
export const updateReservationFirstDispatching = ({groupSeq, time}) => (dispatch, getState) => apiRequest({
  method: 'post',
  url: '/api/dm/group/reservation/first',
  data: { groupSeq, time },
  rootState: getState(),
  dispatch,
})

/** 예약 배차 순서 변경 **/
export const changeOrderDmReservation = (dailySeq = []) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: '/api/dm/reservation/order',
      data: { dailySeq },
      rootState: getState(),
      dispatch,
    })
  }
}

/** 예약 배차 수정 **/
export const updateReservationCar = ({ dailySeq, downPoint, products, upPoint, itemProduct }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: '/api/dm/reservation',
      data: { dailySeq, downPoint, products, upPoint, itemProduct },
      rootState: getState(),
      dispatch,
    })
  }
}

/** 예약 배차 삭제 **/
export const deleteDmReservation = (dailySeq = []) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'delete',
      url: `/api/dm/reservation`,
      data: dailySeq ,
      rootState: getState(),
      dispatch,
    })
  }
}

/** 예약배차 강제 적용**/
export const applyReservationDirectly = () => (dispatch, getState) => apiRequest({
  method: 'post',
  url: '/api/dm/reservation/force',
  rootState: getState(),
  dispatch,
})

/**
 * Memo
 * **/

/** 배차 메모 리스트 **/
export const fetchDmMemoListForGrid = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/dm/admin/memo',
      rootState: getState(),
      dispatch,
    })
      .then(res => res.data.response)
  }
}

export const updateDmMemo = (dto) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: '/api/dm/admin/memo',
      rootState: getState(),
      dispatch,
      data: dto,
    })
      .then(res => res.data.response)
  }
}


/** RESERVATION CHECKBOX LIST FOR GRID **/
export const addReservationCheckBoxItem = (item) => {
  return (dispatch) => dispatch({
    type: dmGroupTypes.ADD_RESERVATION_CHECKBOX_LIST,
    payload: { item }
  })
}

export const removeReservationCheckedBoxItem = (item) => {
  return (dispatch) => dispatch({
    type: dmGroupTypes.REMOVE_RESERVATION_CHECKBOX_LIST,
    payload: { item }
  })
}

export const removeReservationCheckedBoxListByTargetSeq = (targetSeq) => {
  return (dispatch) => dispatch({
    type: dmGroupTypes.REMOVE_RESERVATION_CHECKBOX_LIST_BY_TARGET_SEQ,
    payload: { targetSeq }
  })
}

export const resetReservationCheckBoxList = () => {
  return (dispatch) => dispatch({
    type: dmGroupTypes.RESET_RESERVATION_CHECKBOX_LIST,
  })
}
