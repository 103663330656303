import axios from "axios"
import apiRequest from "@/configs/axiosConfig";
export const loadSuggestions = () => {
  return dispatch => {
    axios.get("/api/search/bookmarks/data").then(response => {
      dispatch({
        type: "MAYBE_UPDATE_SUGGESTIONS",
        suggestions: response.data.searchResult
      })
    })
  }
}

export const updateStarred = object => {
  return dispatch => {
    axios
      .post("api/update/bookmarks", {
        obj: object
      })
      .then(() => {
        dispatch({
          type: "UPDATE_STARRED",
          object
        })
        dispatch(loadSuggestions())
      })
  }
}

/** Mobile 화면용 데이터 업데이트 **/
export const updateTitle = ({ main='', sub=''}) => {
  return dispatch => {
    dispatch({
      type: "UPDATE_TITLE",
      title: { main, sub },
    })
  }
}

/** 알람(Notification) **/
export const fetchAlarmList = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/alarm/bell',
      rootState: getState(),
      dispatch,
    })
      .then(res => res.data.response)
  }
}

export const checksumAlarm = (alarmSeq) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: `/api/alarm/${alarmSeq}/bell`,
      rootState: getState(),
      dispatch,
    })
      .then(res => res.data.response)
  }
}
