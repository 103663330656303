import produce from 'immer'
import { DEFAULT_ROWS_PER_PAGE, TYPE_KEY } from '@/views/pages/CarDispatching/AllCarDispatching/DataListConfig'

export const carDispatchingTypes = {
  FETCH_CAR_DISPATCHING_LIST: 'carDispatching/FETCH_CAR_DISPATCHING_LIST',
  CHANGE_LIST_OPTION: 'carDispatching/CHANGE_LIST_OPTION',
  UPDATE_CAR_DISPATCHING: 'carDispatching/UPDATE_CAR_DISPATCHING',
  FILTER_LIST: 'carDispatching/FILTER_LIST',
  SEARCH_DAILY: 'carDispatching/SEARCH_DAILY',
  RESET_LIST: 'carDispatching/RESET_LIST',
}

const initialState = {
  list: [], // [{address, companySeq, devMemo, enabled, memo, remove, seq, shortName, writer}, ...]
  displayList: [],
  params: null,
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: []
}

const searchCarDispatching = (state = initialState, action) => {
  switch (action.type) {
    case carDispatchingTypes.FETCH_CAR_DISPATCHING_LIST:
    case carDispatchingTypes.SEARCH_DAILY:
      return produce(state, draftState => {
        const { list, totalRecords, params } = action.payload
        const { page = 1, perPage = DEFAULT_ROWS_PER_PAGE } = params
        draftState.displayList = list.slice(page - 1, perPage)
        draftState.list = list
        draftState.totalRecords = totalRecords
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / (params.perPage || DEFAULT_ROWS_PER_PAGE))
      })
    case carDispatchingTypes.CHANGE_LIST_OPTION:
      return produce(state, draftState => {
        const { list, totalRecords } = state
        const { params } = action.payload
        const { page = 1, perPage = DEFAULT_ROWS_PER_PAGE } = params
        const perPageNum = Number(perPage)
        const startIdx = (page - 1) * perPageNum
        const endIdx = startIdx + perPageNum
        draftState.displayList = list.slice(startIdx, endIdx)
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / perPage)
        draftState.sortIndex = [startIdx + 1, endIdx > totalRecords ? totalRecords : endIdx]
      })
    case carDispatchingTypes.UPDATE_CAR_DISPATCHING:
      return produce(state, draftState => {
        //const { target, client, downPoint, products, ton, upPoint, volume } = action.payload
        //const dailyCar = draftState.displayList.find(item => item.target === target)
        //dailyCar.client = client
        //dailyCar.downPoint = downPoint
        //dailyCar.products = products
        //dailyCar.ton = ton
        //dailyCar.upPoint = upPoint
        //dailyCar.volume = volume
      })
    case carDispatchingTypes.RESET_LIST:
      return produce(state, draftState => {
        draftState.displayList = []
        draftState.sortIndex = [1, 0]
        draftState.totalRecords = 0
        draftState.totalPages = 0

        draftState.params = null
        draftState.filteredData = []
      })
    case carDispatchingTypes.FILTER_LIST:
      return produce(state, draftState => {
        const { type, value } = action.payload
        if (!value) {
          draftState.filteredData = draftState.displayList
        } else {
          draftState.filteredData = state.list
            .filter(item => {
              let startsWithCondition = false
              let includesCondition = false
              switch (type.value) {
                case TYPE_KEY.carNumber:
                  startsWithCondition = item.target.toLowerCase().startsWith(value.toLowerCase())
                  includesCondition = item.target.toLowerCase().includes(value.toLowerCase())
                  break
                case TYPE_KEY.upPoint:
                  startsWithCondition = item.upPointName.toLowerCase().startsWith(value.toLowerCase())
                  includesCondition = item.upPointName.toLowerCase().includes(value.toLowerCase())
                  break
                case TYPE_KEY.downPoint:
                  startsWithCondition = item.downPointName.toLowerCase().startsWith(value.toLowerCase())
                  includesCondition = item.downPointName.toLowerCase().includes(value.toLowerCase())
                  break
                case TYPE_KEY.period:
                  break
                default:
                  break
              }
              if (startsWithCondition) {
                return startsWithCondition
              } else if (!startsWithCondition && includesCondition) {
                return includesCondition
              } else {
                return null
              }
            })
            .slice((state.params.page || 1) - 1, (state.params.perPage || DEFAULT_ROWS_PER_PAGE))
        }
      })
    default:
      return state
  }
}

export default searchCarDispatching
