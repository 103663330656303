import React from "react"
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import DataTable from "react-data-table-component"
import classnames from "classnames"
import { history } from '@/history'
import ReactPaginate from "react-paginate"

import _ from "fxjs/Strict"
import L from "fxjs/Lazy"

import {
  Input,
  Badge,
  Button,
  Spinner,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap"
import {
  ChevronDown,
  Check,
  ChevronLeft,
  ChevronRight,
  Clock,
} from "react-feather"
import Checkbox from "@/components/@vuexy/checkbox/CheckboxesVuexy"
import { getStatusColor } from "@/libs/statusColor";
import './dmGroup.scss'

import EditSidebar from './EditSidebar'
import AddNewSidebar from './AddNewSidebar'
import FirstDispatchSidebar from './FirstDispatchSidebar'
import MultiEditSidebar from './MultiEdit'

import {
  fetchDmGroupList,
  createDmGroup,
  createDmDaily,
  applyCarDispatching,
  filterList,
  changeListOption,
  fetchGoodsListTemp,
  fetchSelectOptionCustomerList,
  updateFirstDispatching,
  DeleteCarDispatchingList,
  getCarAreaForSelect,
  getGoodsListForSelect,
  getCustomerListForSelect,
  updateDailyCar,
  deleteFirstDispatching,
} from '@/redux/actions/dmGroup'
import { fetchCarArea } from '@/redux/actions/carArea'

import "@/assets/scss/plugins/extensions/react-paginate.scss"
import '@/assets/scss/pages/data-list.scss'
import DailyEditSidebar from "@/views/pages/DmGroup/Detail/DailyEditSidebar";

export const DEFAULT_ROWS_PER_PAGE = 10

const selectedStyle = {
  rows: {
    selectedHighlighStyle: {
      backgroundColor: "rgba(115,103,240,.05)",
      color: "#7367F0 !important",
      boxShadow: "0 0 1px 0 #7367F0 !important",
      "&:hover": {
        transform: "translateY(0px) !important"
      }
    }
  }
}

const CustomHeader = props => {
  return (
    <div className="data-list-header d-flex justify-content-between flex-wrap width-100-per mb-1">
      <div className="actions-left d-flex flex-wrap">
        <Button
          className="add-new-btn mr-1"
          color="primary"
          onClick={() => props.handleCurrentData()}
          outline>
          <Check size={15} />
          <span className="align-middle dm-group">다중배차</span>
        </Button>
        <Button
          className="add-new-btn mr-1"
          color="primary"
          onClick={props.handleMultiEdit}
          outline>
          <Check size={15} />
          <span className="align-middle dm-group">다중수정</span>
        </Button>
        <Button
          className="add-new-btn mr-1"
          color="primary"
          onClick={() => props.handleFirstDispatch()}
          outline>
          <Check size={15} />
          <span className="align-middle dm-group">첫배차</span>
        </Button>
        <Button
          className="add-new-btn"
          color="primary"
          onClick={() => props.handleCancelDispatch()}
          outline>
          <span className="align-middle dm-group">배차삭제</span>
        </Button>
      </div>
      <div className="actions-right d-flex flex-wrap mt-sm-0 mt-2">
        <div className="filter-section mr-1">
          <Input type="text" placeholder="검색어를 입력하세요." onChange={e => props.handleFilter(e)}/>
        </div>
      </div>
    </div>
  )
}

class DataListConfig extends React.Component{
  static getDerivedStateFromProps(props, state) {
    if (
      props.dmGroup.displayList.length !== state.data.length ||
      state.currentPage !== props.parsedFilter.page
    ) {
      return {
        data: props.dmGroup.displayList,
        allData: props.dmGroup.filteredData,
        totalPages: props.dmGroup.totalPages,
        currentPage: parseInt(props.parsedFilter.page) - 1,
        rowsPerPage: parseInt(props.parsedFilter.perPage),
        totalRecords: props.dmGroup.totalRecords,
        sortIndex: props.dmGroup.sortIndex,
      }
    }

    // Return null if the state hasn't changed
    return null
  }

  state = {
    apiLoaded: false,
    data: [],
    allData: [],
    totalPages: 0,
    currentPage: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    totalRecords: 0,
    sortIndex: [],
    columns: [
      {
        name: "No",
        selector: "rowNum",
        sortable: true,
        width: "50px",
        cell: row => (
          <span id={`row-num-${row.seq}`} className={`width-100-per text-align-center row-font-size`}>{row.rowNum}</span>
        )
      },
      {
        name: "차량번호",
        selector: "target",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.target} className={`text-truncate text-bold-500 mb-0 ${row.seq} row-font-size`}>
            {`${row.target}`}
          </p>
        )
      },
      {
        name: "소속",
        selector: "affiliation",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.target} className={`text-truncate text-bold-500 mb-0 ${row.seq} row-font-size`}>
            {`${row.affiliation ? row.affiliation : '-'}`}
          </p>
        )
      },
      {
        name: "상차지",
        selector: "upPoint",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.upPoint} className="text-truncate text-bold-500 mb-0 row-font-size">
            {`${row.upPoint ? row.upPoint : ''} `}
            {!!row.first ?
              <span
                className={'color-red cursor-pointer'}
                onClick={() => this.onDeleteFirstDispatching(row.seq)}
              >
                {`(${row.firstTime})`}
              </span> : null}
          </p>
        )
      },
      {
        name: "하차지",
        selector: "downPoint",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.downPoint} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.downPoint}
          </p>
        )
      },
      {
        name: "운송물품",
        selector: "products",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.products} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.products}
          </p>
        )
      },
      {
        name: "출발시간",
        selector: "startTime",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.startTime} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.startTime}
          </p>
        )
      },
      {
        name: "상태",
        selector: "status",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.status} className={classnames("text-truncate text-bold-500 mb-0 daily-status row-font-size", { 'driving-cell': row.status === '운행' })}>
            <Badge
              color={`light-${getStatusColor(row.status)}`}
              className="badge-md"
              style={{'fontWeight': '900'}}
            >
              {row.status}
            </Badge>
          </p>
        )
      },
      {
        name: "배차횟수",
        selector: "dailyTotalSize",
        sortable: true,
        center: true,
        cell: row => (
          <p
            title={row.dailyTotalSize}
            className="text-truncate text-bold-500 mb-0 underline-text-red row-font-size"
            onClick={() => this.goDetail(row)}
          >
            {row.dailyTotalSize}
          </p>
        )
      },
    ],
    value: '',
    isShow: {
      edit: false,
      addNew: false,
      firstDispatch: false,
      multiEdit: false,
    },
    currentData: null,
    selected: [],
    prevSelected: [],
    upDownPointList: [],
    carListS: [],
    goodsListS: [],
    clicked: false,
  }

  async componentDidMount() {
    await this.fetchDmGroupList()
    this.handleRowsPerPage(this.state.totalRecords)
    this.setState({ apiLoaded: true })
    setTimeout(() => {
      this.changeRowSize()
    }, 0)
  }

  componentDidUpdate() {
    this.changeTableAttr()
  }

  changeRowSize = () => {
    const $els = document.querySelectorAll('[role="row"]')
    $els.forEach($el => $el.setAttribute("style", "min-height: 30px;"));
    this.changeHeaderFontSize();
  }

  changeHeaderFontSize = () => {
    const $els = document.querySelectorAll('.rdt_TableCol_Sortable')
    $els.forEach($el => $el.setAttribute("style", "font-size: 12px;"));
  }

  addClassShadowRow() {
    Array.from(document.getElementsByClassName('daily-status')).forEach((ele) => {
      ele.parentElement.parentElement.classList.remove('shadow-row')
    })
    setTimeout(() => {
      Array.from(document.getElementsByClassName('driving-cell')).forEach((ele) => {
        ele.parentElement.parentElement.classList.add('shadow-row')
      })
    }, 0)
  }

  fetchDmGroupList = async () => {
    await this.props.fetchDmGroupList(this.props.parsedFilter)
    clearInterval(this.timer)
    this.timer = setInterval(async () => {
      await this.props.fetchDmGroupList(this.props.parsedFilter)
      // this.addClassShadowRow()
    }, 10000)
    // this.addClassShadowRow()
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  handleFilter = e => {
    this.setState({ value: e.target.value })
    this.props.filterList(e.target.value)
  }

  handleRowsPerPage = value => {
    let { parsedFilter, changeListOption } = this.props
    // let page = parsedFilter.page !== undefined ? parsedFilter.page : 1
    // history.replace(`/dmGroup/list?page=${page}&perPage=${value}`)
    history.replace(`/dmGroup/list?page=1&perPage=${value}`)
    this.setState({ rowsPerPage: value, currentPage: 0 })
    changeListOption({ page: 1, perPage: value })
    this.changeRowSize()
  }

  handlePagination = page => {
    let { parsedFilter, changeListOption } = this.props
    let perPage = parsedFilter.perPage !== undefined ? parsedFilter.perPage : DEFAULT_ROWS_PER_PAGE
    history.replace(`/dmGroup/list?page=${page.selected + 1}&perPage=${perPage}`)
    changeListOption({ page: page.selected + 1, perPage: perPage })
    this.setState({ currentPage: page.selected })
    // this.addClassShadowRow()
  }

  handleCurrentData = async () => {
    const { selected } = this.state
    if (!selected.length) return alert('차량을 선택하세요.')

    this.setState({ currentData: selected })
    await this.loadSelectBoxData()
    this.handleEditSidebar(true)
  }

  handleFirstDispatch = () => {
    const { selected } = this.state
    if (!selected.length) return alert('차량을 선택하세요.')

    this.handleFirstDispatchSidebar(true)
  }

  handleCancelDispatch = async () => {
    const { selected } = this.state
    if (!selected.length) return alert('차량을 선택하세요.')

    if (window.confirm('삭제 하겠습니까?')) {
      const groupSeq = selected.map(s => s.seq)
      this.setState({ apiLoaded: false })
      await this.props.DeleteCarDispatchingList({groupSeq})
      window.alert('삭제되었습니다.')
      await this.props.fetchDmGroupList(this.props.parsedFilter)
      this.setState({ apiLoaded: true })
    }
  }

  highlightingSelected = (selected) => {
    _.go(selected,
      L.map(s => s.seq),
      L.map(seq => document.getElementById(`row-num-${seq}`)),
      _.each($el => $el.parentElement.parentElement.classList.add('prev-selected-row'))
    )
  }

  applyCarDispatching = async (rows, { firstTime, dmPoints }) => {
    this.setState({ clicked: true, apiLoaded: false })
    if (!window.confirm('배차처리 하겠습니까?')) {
      this.setState({ clicked: false, apiLoaded: true })
      return
    }

    try {
      const { selected } = this.state

      const groupSeq = rows.map(r => r.seq)
      const data = { groupSeq, firstTime, dmPoints, registerType: 'BASIC' }

      await this.props.applyCarDispatching(data)
      await this.fetchDmGroupList()
      this.handleEditSidebarAndDeselect(false)
      this.highlightingSelected(selected)
    } catch(e) {
      this.setState({ clicked: false, apiLoaded: true })
    }
  }

  updateFirstDispatching = ({time}) => {
    this.setState({ clicked: true })
    if(time.length === 0) {
      alert('시간을 입력해주세요.')
      this.setState({ clicked: false })
      return
    }

    if (!window.confirm('첫배차 시간을 등록 하시겠습니까?')) {
      this.setState({ clicked: false })
      return
    }

    const { selected } = this.state
    const groupSeq = selected.map(s => s.seq)

    this.props.updateFirstDispatching({ groupSeq, time: time[0] })
      .then(res => {
        alert('첫 배차가 등록되었습니다.')
        this.handleFirstDispatchSidebar(false)
        this.props.fetchDmGroupList(this.props.parsedFilter)
      })
      .catch(e => {
        this.setState({ clicked: false })
        console.log(e)
      })
  }

  handleHideSidebar = (boolean) => {
    this.setState({ isShow: { addNew: boolean, edit: boolean, firstDispatch: boolean, multiEdit: boolean } })
  }

  handleEditSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, edit: boolean }, clicked: false, apiLoaded: true })
  }

  handleMultiEditSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, multiEdit: boolean } })
  }

  updateMultiDispatching = async (rows, { downPoint, products, upPoint, itemProduct }) => {
    if(window.confirm('수정하시겠습니까?')) {
      const { selected } = this.state
      const dailySeq = rows.map(r => r.firstDailySeq)
      const data = { dailySeq, downPoint, products, upPoint, itemProduct }
      await this.props.updateDailyCar(data)
      await this.fetchDmGroupList()
      this.handleMultiEditSidebarAndDeselect(false)
      this.highlightingSelected(selected)
    }
  }

  handleMultiEdit = async () => { /** 다중수정 사이드 **/
    const { selected } = this.state
    if (!selected.length) return alert('차량을 선택하세요.')

    const hasNullItem = selected.filter(s => (s.upPoint === null || s.downPoint === null))
    if(hasNullItem.length > 0) {
      window.alert('배차가 없는 건은 수정 할 수 없습니다.')
      return
    }

    // TODO 긴급배차 예외처리. (ok)
    const hasEmergency = selected.filter(s => (s.upPoint === '긴급배차_상차지' || s.downPoint === '긴급배차_하차지'))
    if(hasEmergency.length > 0) {
        window.alert('긴급배차건은 수정 할 수 없습니다.')
        return
    }

    this.setState({ currentData: selected })
    await this.loadSelectBoxData()
    this.handleMultiEditSidebar(true)
  }

  handleEditSidebarAndDeselect = (boolean) => {
    const { isShow, selected } = this.state
    this.setState({ isShow: { ...isShow, edit: boolean }, clicked: false, apiLoaded: true, selected: [], prevSelected: selected.map(s => s.seq) })
  }

  handleMultiEditSidebarAndDeselect = (boolean) => {
    const { isShow, selected } = this.state
    this.setState({ isShow: { ...isShow, multiEdit: boolean }, clicked: false, apiLoaded: true, selected: [], prevSelected: selected.map(s => s.seq) })
  }

  handleFirstDispatchSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, firstDispatch: boolean }, clicked: false })
  }

  async loadSelectBoxData () {
    const [upDownPointList, carListS, goodsListS ] = await Promise.all([
      this.props.getCustomerListForSelect(),
      this.props.getCarAreaForSelect(),
      this.props.getGoodsListForSelect()
    ])

    this.setState(({ upDownPointList, carListS, goodsListS }))

  }

  handleAddNewSidebar = async (boolean) => {
    const { isShow } = this.state
    await this.loadSelectBoxData()
    this.setState({ isShow: { ...isShow, addNew: boolean } })
  }

  createDmGroup = async ({ target, client, downPoint, products, ton, upPoint, volume, iterateCount }) => {
    //this.setState({ apiLoaded: false })
    const gData = { target, client, downPoint, products, ton, upPoint, volume, iterateCount }
    const res = await this.props.createDmGroup(gData)
    const groupSeq = res.data.response
    const data = { targets: [target], downPoint, products, ton, upPoint, volume, groupSeq, iterateCount }
    await this.props.createDmDaily(data)
    await this.fetchDmGroupList()
    //this.setState({ apiLoaded: true })
    this.setState({ selected: [] })
    this.handleAddNewSidebar(false)
  }

  goDetail = (row) => {
    history.push(`/dmGroup/detail/group/${row.seq}/target/${row.targetSeq}`)
  }

  onDeleteFirstDispatching = async (seq) => {
    if(window.confirm('첫배차를 삭제하시겠습니까?')) {
      const res = await this.props.deleteFirstDispatching(seq)
      if(res) {
        window.alert('삭제되었습니다.')
        await this.fetchDmGroupList()
      }
    }
  }

  removeClassPrevSelected = () => {
    const $els = document.querySelectorAll('.prev-selected-row')
    $els.forEach($el => $el.classList.remove('prev-selected-row'))
  }

  changeTableAttr = () => {
    this.changeRowSize()
    this.addClassShadowRow()
    this.removeClassPrevSelected()
  }

  onSortData = (data=[]) => {
    const newData = data.map(a => ({
      ...a,
      startTime: a.startTime === null ? '-' : a.startTime.split(' ')[1],
      upPoint: (a.upPoint || ' ').split('_')[0] === '긴급배차' ? '긴급배차_상차지' : a.upPoint,
      downPoint: (a.downPoint || ' ').split('_')[0] === '긴급배차' ? '긴급배차_하차지' : a.downPoint
    }))

    const dailyZero = newData.filter(d => d.dailyTotalSize === 0)
    const dailyRest = newData.filter(d => d.dailyTotalSize !== 0).sort((a, b) => a.dailyTotalSize > b.dailyTotalSize ? 1 : -1)
    return [...dailyRest, ...dailyZero]
  }

  render(){
    let {
      apiLoaded, columns, data: d, allData: ad, isShow, currentData, rowsPerPage,
      totalRecords, sortIndex, totalPages, value, selected,
      carListS, goodsListS, upDownPointList,
    } = this.state

    const allData = this.onSortData(ad)
    const data = this.onSortData(d)

    const selectedMap = selected
      .map(s => ({[s.seq]: s}))
      .reduce((a, b) => ({...a, ...b}), {})

    const dataList = (value.length ? allData : data).map((d, idx) => ({...d, rowNum: idx+1}))

    return !apiLoaded ? (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    ) : (
      <div className="list-grid-style-small m-2">

        <CustomHeader
          handleSidebar={this.handleAddNewSidebar}
          handleFilter={this.handleFilter}
          handleRowsPerPage={this.handleRowsPerPage}
          handleAddNewSidebar={this.handleAddNewSidebar}
          handleCurrentData={this.handleCurrentData}
          handleFirstDispatch={this.handleFirstDispatch}
          handleCancelDispatch={this.handleCancelDispatch}
          handleMultiEdit={this.handleMultiEdit}
          rowsPerPage={rowsPerPage}
          total={totalRecords}
          index={sortIndex}
        />
        <div onClick={this.changeTableAttr}>
          <DataTable
            columns={columns}
            // data={value.length ? allData : data}
            data={dataList}
            noHeader
            selectableRows
            responsive
            //pointerOnHover
            selectableRowsHighlight
            selectableRowSelected={row => !!selectedMap[row.seq]}
            onSelectedRowsChange={data => this.setState({ selected: data.selectedRows, prevSelected: [] })}
            customStyles={selectedStyle}
            sortIcon={<ChevronDown />}
            selectableRowsComponent={Checkbox}
            selectableRowsComponentProps={{
              color: "primary",
              icon: <Check className="vx-icon" size={12} />,
              label: "",
              size: "sm"
            }}
            noDataComponent={<div style={{padding: '24px'}}>데이터가 없습니다.</div>}
          />
        </div>

        <div className="data-list list-view">
          { isShow.edit && (
            <EditSidebar // 다중배차
              data={currentData}
              handleSidebar={this.handleEditSidebar}
              updateData={this.applyCarDispatching}
              upDownPointList={upDownPointList}
              carList={carListS}
              goodsList={goodsListS}
            />
          )}
          { isShow.addNew && upDownPointList.length && carListS.length && (
            <AddNewSidebar
              handleSidebar={this.handleAddNewSidebar}
              createData={this.createDmGroup}
              upDownPointList={upDownPointList}
              carList={carListS}
              goodsList={goodsListS}
              popupTitle="그룹 등록"
            />
          )}
          { isShow.firstDispatch && (
            <FirstDispatchSidebar
              // data={currentData}
              handleSidebar={this.handleFirstDispatchSidebar}
              updateData={this.updateFirstDispatching}
            />
          )}
          { isShow.multiEdit && (
            <MultiEditSidebar // 다중수정
              data={currentData}
              handleSidebar={this.handleMultiEditSidebar}
              updateData={this.updateMultiDispatching}
              upDownPointList={upDownPointList}
              carList={carListS}
              goodsList={goodsListS}
            />
          )}
          <div
            className={classnames("data-list-overlay", {
              show: isShow.edit || isShow.addNew || isShow.firstDispatch || isShow.multiEdit
            })}
            onClick={() => this.handleHideSidebar(false)}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    dmGroup: state.dmGroup,
    // carList: state.carArea.list,
    // goodsList: state.goods.list,
  }),
  {
    fetchDmGroupList,
    createDmGroup,
    createDmDaily,
    applyCarDispatching,
    filterList,
    changeListOption,
    fetchCarArea,
    fetchSelectOptionCustomerList,
    fetchGoodsListTemp,
    updateFirstDispatching,
    DeleteCarDispatchingList,
    getCarAreaForSelect,
    getGoodsListForSelect,
    getCustomerListForSelect,
    updateDailyCar,
    deleteFirstDispatching,
  },
)(DataListConfig)

DataListConfig.propTypes = {
  parsedFilter: PropTypes.object
}
