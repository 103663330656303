import apiRequest from '@/configs/axiosConfig'
import { callHistoryTypes } from '@/redux/reducers/callHistory'

export const fetchCallHistory = (params) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/notification',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
        const numberingList = list.map((item, idx) => ({...item, rowNum: idx + 1}))
        dispatch({
          type: callHistoryTypes.FETCH_CALL_HISTORY_LIST,
          payload: { list:numberingList, params, totalRecords: list.length },
        })
        dispatch(changeListOption(params))
        return response
      })
  }
}

export const fetchCallHistoryGrid = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/notification',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
        const numberingList = list.map((item, idx) => ({...item, rowNum: idx + 1}))
        return numberingList
      })
  }
}

export const changeListOption = (params) => {
  return (dispatch) => dispatch({
    type: callHistoryTypes.CHANGE_LIST_OPTION,
    payload: { params },
  })
}

// export const changeStatusCarArea = ({ seq, enabled }) => {
//   return (dispatch, getState) => {
//     return apiRequest({
//       method: 'put',
//       url: `/api/point/${seq}/status`,
//       rootState: getState(),
//       dispatch,
//     })
//       .then(response => {
//         if (response.data.response === true) {
//           dispatch({
//             type: customerTypes.CHANGE_STATUS,
//             payload: { seq, enabled },
//           })
//         }
//         return response
//       })
//   }
// }

export const addNewCarManage = ({ name, phone, principal, ton, volume }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/user/join/driver',
      data: { name, phone, principal, ton, volume },
      rootState: getState(),
      dispatch,
    })
  }
}

export const updateCarArea = ({ seq, name, phone, ton, volume }) => {
  return (dispatch, getState) => {
    alert('수정!!!!')
    //return apiRequest({
    //  method: 'put',
    //  url: '/api/users',
    //  data: { seq, name, phone, ton, volume },
    //  rootState: getState(),
    //  dispatch,
    //})
    //  .then(response => {
    //    if (response.data.response === true) {
    //      dispatch({
    //        type: callHistoryTypes.UPDATE_CAR,
    //        payload: { seq, name, phone, ton, volume },
    //      })
    //    }
    //    return response
    //  })
  }
}

export const deleteCallHistory = (seq) => {
  return (dispatch, getState) => {
    alert('deleteCallHistory')
    //return apiRequest({
    //  method: 'delete',
    //  url: `/api/users/${seq}`,
    //  rootState: getState(),
    //  dispatch,
    //})
  }
}

export const filterList = value => {
  return dispatch => dispatch({ type: callHistoryTypes.FILTER_LIST, value })
}

export const changeEnabled = (seq, adminCheck) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: `/api/notification/${seq}/check`,
      data: { adminCheck },
      rootState: getState(),
      dispatch,
    }).then(response => {
      if (response.data.response === true) {
        dispatch({
          type: callHistoryTypes.CHANGE_STATUS,
          payload: { seq, adminCheck },
        })
      }
      return response
    })
  }
}

export const changeEnabledGrid = (seq, adminCheck) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: `/api/notification/${seq}/check`,
      data: { adminCheck },
      rootState: getState(),
      dispatch,
    })
      .then(res => res)
      .catch(err => err)
  }
}
