import produce from 'immer'
import { DEFAULT_ROWS_PER_PAGE } from '@/views/pages/Customer/DataListConfig'

export const customerTypes = {
  FETCH_CUSTOMER_LIST: 'customer/FETCH_CUSTOMER_LIST',
  CHANGE_LIST_OPTION: 'customer/CHANGE_LIST_OPTION',
  CHANGE_STATUS: 'customer/CHANGE_STATUS',
  UPDATE_MEMO: 'customer/UPDATE_MEMO',
  FILTER_LIST: 'customer/FILTER_LIST',
}

const initialState = {
  list: [], // [{address, companySeq, devMemo, enabled, memo, remove, seq, shortName, writer}, ...]
  displayList: [],
  params: null,
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: []
}

const customer = (state = initialState, action) => {
  switch (action.type) {
    case customerTypes.FETCH_CUSTOMER_LIST:
      return produce(state, draftState => {
        const { list, totalRecords, params } = action.payload
        const { page = 1, perPage = DEFAULT_ROWS_PER_PAGE } = params
        draftState.displayList = list.slice(page - 1, perPage)
        draftState.list = list
        draftState.totalRecords = totalRecords
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / (params.perPage || DEFAULT_ROWS_PER_PAGE))
      })
    case customerTypes.CHANGE_LIST_OPTION:
      return produce(state, draftState => {
        const { list, totalRecords } = state
        const { params } = action.payload
        const { page = 1, perPage = DEFAULT_ROWS_PER_PAGE } = params
        const perPageNum = Number(perPage)
        const startIdx = (page - 1) * perPageNum
        const endIdx = startIdx + perPageNum
        draftState.displayList = list.slice(startIdx, endIdx)
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / perPage)
        draftState.sortIndex = [startIdx + 1, endIdx > totalRecords ? totalRecords : endIdx]
      })
    case customerTypes.CHANGE_STATUS:
      return produce(state, draftState => {
        const { seq, enabled } = action.payload
        draftState.displayList.find(item => item.seq === seq).enabled = enabled
      })
    case customerTypes.UPDATE_MEMO:
      return produce(state, draftState => {
        const { seq, memo } = action.payload
        draftState.displayList.find(item => item.seq === seq).memo = memo
      })
    case customerTypes.FILTER_LIST:
      return produce(state, draftState => {
        const { value } = action
        if (!value) {
          draftState.filteredData = draftState.displayList
        } else {
          // draftState.filteredData = state.list
          draftState.filteredData = state.displayList
            .filter(item => {
              const status = item.enabled ? '활성' : '비활성'
              let startsWithCondition =
                (item.shortName || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.address || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.companyName || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.companyOwnerName || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.companyPersonName || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.taxAddress || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.companyTel || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.companyFax || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.companyType || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.companyEmail || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.address || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (status || '').toLowerCase().startsWith(value.toLowerCase())

              let includesCondition =
                (item.shortName || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.address || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.companyName || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.companyOwnerName || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.companyPersonName || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.taxAddress || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.companyTel || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.companyFax || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.companyType || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.companyEmail || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.address || '').toLowerCase().includes(value.toLowerCase())

              if (startsWithCondition) {
                return startsWithCondition
              } else if (!startsWithCondition && includesCondition) {
                return includesCondition
              } else {
                return null
              }
            })
            .slice((state.params.page || 1) - 1, (state.params.perPage || DEFAULT_ROWS_PER_PAGE))
        }
      })
    default:
      return state
  }
}

export default customer
