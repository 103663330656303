import produce from 'immer'
import { DEFAULT_ROWS_PER_PAGE } from '@/views/pages/CallHistory/DataListConfig'

export const callHistoryTypes = {
  FETCH_CALL_HISTORY_LIST: 'callHistory/FETCH_CALL_HISTORY_LIST',
  CHANGE_LIST_OPTION: 'callHistory/CHANGE_LIST_OPTION',
  CHANGE_STATUS: 'callHistory/CHANGE_STATUS',
  UPDATE_CAR: 'callHistory/UPDATE_CAR',
  FILTER_LIST: 'callHistory/FILTER_LIST',
}

const initialState = {
  list: [], // [{accountId, accountType, companyName, companyTel, createdAt, name, phone, ton, volume}, ...]
  displayList: [],
  params: null,
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: []
}

const carArea = (state = initialState, action) => {
  switch (action.type) {
    case callHistoryTypes.FETCH_CALL_HISTORY_LIST:
      return produce(state, draftState => {
        const { list, totalRecords, params } = action.payload
        const { page = 1, perPage = DEFAULT_ROWS_PER_PAGE } = params
        draftState.displayList = list.slice(page - 1, perPage)
        draftState.list = list
        draftState.totalRecords = totalRecords
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / (params.perPage || DEFAULT_ROWS_PER_PAGE))
      })
    case callHistoryTypes.CHANGE_LIST_OPTION:
      return produce(state, draftState => {
        const { list, totalRecords } = state
        const { params } = action.payload
        const { page = 1, perPage = DEFAULT_ROWS_PER_PAGE } = params
        const perPageNum = Number(perPage)
        const startIdx = (page - 1) * perPageNum
        const endIdx = startIdx + perPageNum
        draftState.displayList = list.slice(startIdx, endIdx)
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / perPage)
        draftState.sortIndex = [startIdx + 1, endIdx > totalRecords ? totalRecords : endIdx]
      })
    case callHistoryTypes.CHANGE_STATUS:
      return produce(state, draftState => {
        const { seq, adminCheck } = action.payload
        draftState.displayList.find(item => item.seq === seq).adminCheck = adminCheck
      })
    case callHistoryTypes.UPDATE_CAR:
      return produce(state, draftState => {
        const { seq, name, phone, ton, volume } = action.payload
        const item = draftState.displayList.find(item => item.seq === seq)
        item.name = name
        item.phone = phone
        item.ton = ton
        item.volume = volume
      })
    case callHistoryTypes.FILTER_LIST:
      return produce(state, draftState => {
        const { value } = action
        if (!value) {
          draftState.filteredData = draftState.displayList
        } else {
          draftState.filteredData = state.list
            .filter(item => {
              let startsWithCondition =
                (item.accountId || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.phone || '').toLowerCase().startsWith(value.toLowerCase()) ||
                (item.message || '').toLowerCase().startsWith(value.toLowerCase())

              let includesCondition =
                (item.accountId || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.phone || '').toLowerCase().includes(value.toLowerCase()) ||
                (item.message || '').toLowerCase().includes(value.toLowerCase())

              if (startsWithCondition) {
                return startsWithCondition
              } else if (!startsWithCondition && includesCondition) {
                return includesCondition
              } else {
                return null
              }
            })
            .slice((state.params.page || 1) - 1, (state.params.perPage || DEFAULT_ROWS_PER_PAGE))
        }
      })
    default:
      return state
  }
}

export default carArea
