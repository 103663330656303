import apiRequest from '@/configs/axiosConfig'
import { carAreaTypes } from '@/redux/reducers/carArea'
//import {customerTypes} from "@/redux/reducers/customer";

export const fetchCarArea = (params) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/users',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
        const numberingList = list.map((item, idx) => ({...item, rowNum: idx + 1}))
        dispatch({
          type: carAreaTypes.FETCH_CAR_AREA_LIST,
          payload: { list: numberingList, params, totalRecords: list.length },
        })
        dispatch(changeListOption(params))
        return response
      })
  }
}

export const fetchCarAreaGrid = (params) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/users',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = response.data.response
        const numberingList = list.map((item, idx) => ({...item, rowNum: idx + 1}))
        return numberingList
      })
  }
}

export const changeListOption = (params) => {
  return (dispatch) => dispatch({
    type: carAreaTypes.CHANGE_LIST_OPTION,
    payload: { params },
  })
}

// export const changeStatusCarArea = ({ seq, enabled }) => {
//   return (dispatch, getState) => {
//     return apiRequest({
//       method: 'put',
//       url: `/api/point/${seq}/status`,
//       rootState: getState(),
//       dispatch,
//     })
//       .then(response => {
//         if (response.data.response === true) {
//           dispatch({
//             type: customerTypes.CHANGE_STATUS,
//             payload: { seq, enabled },
//           })
//         }
//         return response
//       })
//   }
// }

export const addNewCarManage = ({ name, affiliation, phone, principal, ton, volume, carYear, carType, carType2, target, carBrand }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/user/join/driver',
      data: { name, affiliation, phone, principal, ton, volume, carYear, carType, target, carType2, carBrand },
      rootState: getState(),
      dispatch,
    }).then(() => {
      window.alert("등록 되었습니다.");
      window.location.reload()
    })
  }
}

export const addNewCarManageGrid = ({ name, affiliation, phone, principal, ton, volume, carYear, carType, carType2, target, carBrand }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/user/join/driver',
      data: { name, affiliation, phone, principal, ton, volume, carYear, carType, target, carType2, carBrand },
      rootState: getState(),
      dispatch,
    }).then((response) => response.data.response)
  }
}

export const updateCarArea = ({ seq, affiliation, name, phone, ton, volume, carYear, carType, carType2, password, pwdChecked, carBrand }) => {
  return (dispatch, getState) => {
    // console.log({ seq, affiliation, name, phone, ton, volume, carYear, carType})
    return apiRequest({
      method: 'put',
      url: '/api/users',
      data: { seq, affiliation, name, phone, ton, volume, carYear, carType, carType2, password: password.trim(), pwdChecked, carBrand },
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        if (response.data.response === true) {
          dispatch({
            type: carAreaTypes.UPDATE_CAR,
            payload: { seq, affiliation, name, phone, ton, volume, carYear, carType, carBrand },
          })
          window.alert("수정 되었습니다.");
        }
        return response
      })
  }
}

export const updateCarAreaGrid = ({ seq, affiliation, name, phone, ton, volume, carYear, carType, carType2, password, pwdChecked, carBrand }) => {
  return (dispatch, getState) => {
    // console.log({ seq, affiliation, name, phone, ton, volume, carYear, carType})
    return apiRequest({
      method: 'put',
      url: '/api/users',
      data: { seq, affiliation, name, phone, ton, volume, carYear, carType, carType2, password: password.trim(), pwdChecked, carBrand },
      rootState: getState(),
      dispatch,
    })
      .then(response => response.data.response)
      .catch(res => res)
  }
}

export const updateMemoArea = ({ seq, memo }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: '/api/users/memo',
      data: { seq, memo },
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        if (response.data.response === true) {
          dispatch({
            type: carAreaTypes.UPDATE_MEMO,
            payload: { seq, memo },
          })
          window.alert('수정 되었습니다.');
        }
        return response
      })
  }
}

export const updateMemoAreaGrid = ({ seq, memo }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: '/api/users/memo',
      data: { seq, memo },
      rootState: getState(),
      dispatch,
    })
      .then(response => response.data.response)
  }
}

export const deleteCar = (seq) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'delete',
      url: `/api/users/${seq}`,
      rootState: getState(),
      dispatch,
    }).then(()=>{
      window.alert("삭제 되었습니다.");
    })
  }
}

export const deleteCarGrid = (seq) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'delete',
      url: `/api/users/${seq}`,
      rootState: getState(),
      dispatch,
    }).then(res => res.data.response)
  }
}

export const filterList = value => {
  return dispatch => dispatch({ type: carAreaTypes.FILTER_LIST, value })
}

export const changeEnabled = (seq, enabled) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: `/api/users/${seq}/status`,
      data: { enabled },
      rootState: getState(),
      dispatch,
    }).then(response => {
      if (response.data.response === true) {
        dispatch({
          type: carAreaTypes.CHANGE_STATUS,
          payload: { seq, enabled },
        })
      }
      return response
    })
  }
}

export const changeEnabledGrid = (seq, enabled) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'put',
      url: `/api/users/${seq}/status`,
      data: { enabled },
      rootState: getState(),
      dispatch,
    }).then(res => res)
  }
}

export const exportDriverExcel = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/excel/driver/download',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        return response.data.response
      })
  }
}
