import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import { X } from "react-feather"
import { Label, Input, FormGroup, Button } from "reactstrap"

import { addPhoneHypen } from '@/libs/phoneLib'

class AddNewSidebar extends Component {

  state = {
    show: false,
    shortName: '',
    address: '',
    memo: '',
    companyName: '',
    companyTel: '',
    taxAddress: '',
    companyEmail: '',
    companyOwnerName: '',
    companyPersonName: '',
    companyFax: '',
    companyType: '',
  }

  componentDidMount() {
    this.setState({ show: true })
  }

  render() {
    let { show, shortName, address, // memo,
      companyName ,companyTel, companyEmail, taxAddress,
      companyOwnerName, companyPersonName, companyFax, companyType
    } = this.state
    let { handleSidebar, createCustomer } = this.props
    return (
      <div
        style={{height: `${window.innerHeight}px`}}
        className={classnames("data-list-sidebar", { show })}>
        <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
          <h4>등록</h4>
          <X className="cursor-pointer" size={20} onClick={() => handleSidebar(false)} />
        </div>
        <PerfectScrollbar
          className="data-list-fields px-2 mt-2"
          options={{ wheelPropagation: false }}
        >
          <FormGroup className={'mb-1'}>
            <Label for="data-name">간편회사명 <span className={'required-mark'}>*</span></Label>
            <Input
              type="text"
              value={shortName}
              placeholder="구분"
              onChange={e => this.setState({ shortName: e.target.value })}
              id="data-name"
            />
          </FormGroup>
          <FormGroup className={'mb-1'}>
            <Label for="data-companyName">회사명</Label>
            <Input
              type="text"
              value={companyName}
              placeholder="회사명"
              onChange={e => this.setState({ companyName: e.target.value })}
              id="data-companyName"
            />
          </FormGroup>
          <FormGroup className={'mb-1'}>
            <Label for="data-companyOwner">대표자명</Label>
            <Input
              type="text"
              value={companyOwnerName}
              placeholder="회사명"
              onChange={e => this.setState({ companyOwnerName: e.target.value })}
              id="data-companyOwnerName"
            />
          </FormGroup>
          <FormGroup className={'mb-1'}>
            <Label for="data-companyPersonName">담당자명</Label>
            <Input
              type="text"
              value={companyPersonName}
              placeholder="회사명"
              onChange={e => this.setState({ companyPersonName: e.target.value })}
              id="data-companyPersonName"
            />
          </FormGroup>
          <FormGroup className={'mb-1'}>
            <Label for="data-taxAddress">사업자등록번호</Label>
            <Input
              type="text"
              value={taxAddress}
              placeholder="사업자번호"
              onChange={e => this.setState({ taxAddress: e.target.value })}
              id="data-taxAddress"
            />
          </FormGroup>
          <FormGroup className={'mb-1'}>
            <Label for="data-companyTel">전화번호</Label>
            <Input
              type="text"
              value={companyTel}
              placeholder="연락처"
              onChange={e => this.setState({ companyTel: addPhoneHypen(e.target.value) })}
              id="data-companyTel"
            />
          </FormGroup>
          <FormGroup className={'mb-1'}>
            <Label for="data-companyFax">팩스번호</Label>
            <Input
              type="text"
              value={companyFax}
              placeholder="연락처"
              onChange={e => this.setState({ companyFax: e.target.value })}
              id="data-companyFax"
            />
          </FormGroup>
          <FormGroup className={'mb-1'}>
            <Label for="data-companyType">사업자구분</Label>
            <Input
              type="text"
              value={companyType}
              placeholder="개인사업자 / 법인사업자"
              onChange={e => this.setState({ companyType: e.target.value })}
              id="data-companyType"
            />
          </FormGroup>
          <FormGroup className={'mb-1'}>
            <Label for="data-companyTel">메일</Label>
            <Input
              type="text"
              value={companyEmail}
              placeholder="이메일"
              onChange={e => this.setState({ companyEmail: e.target.value })}
              id="data-companyEmail"
            />
          </FormGroup>
          <FormGroup className={'mb-1'}>
            <Label for="data-address">주소</Label>
            <Input
              type="text"
              value={address}
              placeholder="주소"
              onChange={e => this.setState({ address: e.target.value })}
              id="data-address"
            />
            <Label for="data-address"><span className={'required-mark'}>*</span> 주소는 기사앱 네비게이션 사용을 위한 부분입니다.</Label>
            <Label for="data-address"><span className={'white-mark'}>*</span> 정확한 주소 입력 부탁드립니다.</Label>
          </FormGroup>
        </PerfectScrollbar>
        <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
          <Button color="primary" onClick={() => createCustomer(this.state)}>
            등록
          </Button>
          <Button
            className="ml-1"
            color="danger"
            outline
            onClick={() => handleSidebar(false)}
          >
            취소
          </Button>
        </div>
      </div>
    )
  }
}

export default AddNewSidebar

AddNewSidebar.propTypes = {
  handleSidebar: PropTypes.func,
  createCustomer: PropTypes.func,
}
