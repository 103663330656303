import apiRequest from "@/configs/axiosConfig";
import { carDispatchingTypes } from "@/redux/reducers/carDispatching";

import _ from "fxjs/Strict";
import L from "fxjs/Lazy";

export const fetchCarDispatchingList = ({ params, data: { type, value = '' } }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/dm/list',
      data: { type, value },
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = (response.data.response || []).map((d, idx) => ({...d, num: idx+1 }))
        dispatch({
          type: carDispatchingTypes.FETCH_CAR_DISPATCHING_LIST,
          payload: { list, params, totalRecords: list.length }
        })
        dispatch(changeListOption(params))
        return response
      })
  }
}

export const fetchSearchDailyCarDispatchingList = ({ params }) => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/dm/search/daily',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = (response.data.response || []).map((d, idx) => ({...d, num: idx+1 }))
        dispatch({
          type: carDispatchingTypes.SEARCH_DAILY,
          payload: { list, params, totalRecords: list.length }
        })
        dispatch(changeListOption(params))
        return response
      })
  }
}

export const fetchSearchDailyCarDispatchingListGrid = () => {
  return (dispatch, getState) => {
    return apiRequest({
      url: '/api/dm/search/daily',
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = (response.data.response || [])
          .map((d, idx) => ({...d, num: idx+1 }))
        return list
      })
  }
}

export const fetchPeriodCarDispatchingList = ({ params, period: [startDate, endDate] }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/dm/period/list',
      data: { startDate, endDate },
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = (response.data.response || [])
          .filter(d => d.status !== '삭제')
          .map((d, idx) => ({
            ...d,
            upPointName: (d.upPointName || ' ').split('_')[0] === '긴급배차' ? '긴급배차_상차지' : d.upPointName,
            downPointName: (d.downPointName || ' ').split('_')[0] === '긴급배차' ? '긴급배차_하차지' : d.downPointName,
            num: idx+1,
            // products: (d.upDownInfo?.products)
          }))

        dispatch({
          type: carDispatchingTypes.FETCH_CAR_DISPATCHING_LIST,
          payload: { list, params, totalRecords: list.length }
        })
        dispatch(changeListOption(params))
        return response
      })
  }
}

export const changeListOption = (params) => {
  return (dispatch) => dispatch({
    type: carDispatchingTypes.CHANGE_LIST_OPTION,
    payload: { params }
  })
}

export const updateCarDispatching = ({ target, editInfo: { client, downPoint, products, ton, upPoint, volume } }) => {
  return (dispatch, getState) => {
    alert('updateDailyCar 수정 api 호출하기!!!! 작업필요!!!!')
    // console.log('updateDailyCar', { target, client, downPoint, products, ton, upPoint, volume })
    return apiRequest({
      method: 'post',
      url: '/api/dm/수정수정',
      data: { target, client, downPoint, products, ton, upPoint, volume },
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        if (response.data.response === true) {
          dispatch({
            type: carDispatchingTypes.UPDATE_CAR_DISPATCHING,
            payload: { target, client, downPoint, products, ton, upPoint, volume },
          })
        }
        return response
      })
  }
}

export const addNewCarDispatching = ({ accountId, client, downPoint, products, ton, upPoint, volume }) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/dm',
      data: { accountId, client, downPoint, products, ton, upPoint, volume },
      rootState: getState(),
      dispatch,
    })
  }
}

export const filterList = ({ type, value }) => {
  return dispatch => dispatch({ type: carDispatchingTypes.FILTER_LIST, payload: { type, value } })
}

export const resetList = () => {
  return dispatch => dispatch({ type: carDispatchingTypes.RESET_LIST })
}

export const RemoveCarDispatching = ({seqList}) => (dispatch, getState) => apiRequest({
  method: 'delete',
  url: 'api/dispatch/history',
  data: { seqList },
  rootState: getState(),
  dispatch,
})

export const updateCarDispatchingDone = ({dailySeq, upPoint, downPoint, itemProduct}) => (dispatch, getState) => apiRequest({
  method: 'put',
  url: 'api/dm/force/daily',
  data: { dailySeq, upPoint, downPoint, itemProduct },
  rootState: getState(),
  dispatch,
})
  .then(response => {
    return response.data.response
  })


export const insertCarDispatchingDone = (insertData) => (dispatch, getState) => apiRequest({
  method: 'post',
  url: `/api/dm/search/daily`,
  data: insertData,
  rootState: getState(),
  dispatch,
})
  .then(response => {
    return response.data.response
  })




export const exportExcelData = ({dailySeq, startPeriod, endPeriod}) => (dispatch, getState) => apiRequest({
  method: 'post',
  url: 'api/excel/dispatch/period',
  data: { dailySeq, startPeriod, endPeriod },
  rootState: getState(),
  dispatch,
})
  .then(response => {
    return response.data.response
  })


/** For agGrid **/

export const fetchPeriodCarDispatchingListGrid = ({startDate, endDate}) => {
  return (dispatch, getState) => {
    return apiRequest({
      method: 'post',
      url: '/api/dm/period/list',
      data: { startDate, endDate },
      rootState: getState(),
      dispatch,
    })
      .then(response => {
        const list = (response.data.response || [])
          .filter(d => d.status !== '삭제')
          .map((d, idx) => ({
            ...d,
            upPointName: (d.upPointName || ' ').split('_')[0] === '긴급배차' ? '긴급배차_상차지' : d.upPointName,
            downPointName: (d.downPointName || ' ').split('_')[0] === '긴급배차' ? '긴급배차_하차지' : d.downPointName,
            rowNum: idx+1,
            products: (d.upDownInfo?.products)
          }))

        return list
      })
  }
}

/** 배차 통계 data fetch **/
export const fetchStatisticsDataByDate = ({startDate, endDate}) => (dispatch, getState) => apiRequest({
  method: 'post',
  url: 'api/insight/baecha',
  data: { startDate, endDate },
  rootState: getState(),
  dispatch,
})
  .then(response => {
    return response.data.response
  })

/** 운행이력 합산 **/
export const fetchTotalDrivingHistory = ({startDate, endDate}) => (dispatch, getState) => apiRequest({
  method: 'post',
  url: 'api/driving/history',
  data: { startDate, endDate },
  rootState: getState(),
  dispatch,
})
  .then(response => {
    return response.data.response
  })


/** 전체조회 검수 **/
export const onSetVerification = ({dailySeq, type}) => (dispatch, getState) => apiRequest({
    method: 'post',
    url: '/api/dm/verification',
    data: { dailySeq, type },
    rootState: getState(),
    dispatch,
  })
    .then(response => {
      return response.data.response
    })

