import React from "react"
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import DataTable from "react-data-table-component"
import classnames from "classnames"
import { history } from '@/history'
import ReactPaginate from "react-paginate"
import {
  Input,
  Button,
  Spinner,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem, CustomInput,
} from 'reactstrap'
import {
  Trash,
  ChevronDown,
  Plus,
  Check,
  ChevronLeft,
  ChevronRight
} from "react-feather"
import Checkbox from "@/components/@vuexy/checkbox/CheckboxesVuexy"
import EditSidebar from '@/views/pages/GoodsList/EditSidebar'
import AddNewSidebar from './AddNewSidebar'
import {
  fetchGoodsList,
  changeStatusGoods,
  updateGoods,
  addNewGoods,
  deleteGoods,
  filterList,
  changeListOption,
  changeEnabled,
  exportGoodsExcel,
} from '@/redux/actions/goods'

import "@/assets/scss/plugins/extensions/react-paginate.scss"
import '@/assets/scss/pages/data-list.scss'

export const DEFAULT_ROWS_PER_PAGE = 10
export const TYPE_KEY = {
  N: { value: 'N', label: '없음' },
  S: { value: 'S', label: '소' },
  M: { value: 'M', label: '중' },
  L: { value: 'L', label: '대' },
}
export const SELECT_OPTIONS = [
  { value: TYPE_KEY.N.value, label: TYPE_KEY.N.label },
  { value: TYPE_KEY.S.value, label: TYPE_KEY.S.label },
  { value: TYPE_KEY.M.value, label: TYPE_KEY.M.label },
  { value: TYPE_KEY.L.value, label: TYPE_KEY.L.label },
]


const selectedStyle = {
  rows: {
    selectedHighlighStyle: {
      backgroundColor: "rgba(115,103,240,.05)",
      color: "#7367F0 !important",
      boxShadow: "0 0 1px 0 #7367F0 !important",
      "&:hover": {
        transform: "translateY(0px) !important"
      }
    }
  }
}

const CustomHeader = props => {
  return (
    <div className="data-list-header d-flex justify-content-between flex-wrap width-100-per mb-1">
      <div className="actions-left d-flex flex-wrap">
        <Button
          className="add-new-btn"
          color="primary"
          onClick={() => props.handleAddNewSidebar(true)}
          outline>
          <Plus size={15} />
          <span className="align-middle">등록</span>
        </Button>
        <Button
          className="add-new-btn ml-1"
          color="primary"
          onClick={props.handleExportExcel}
          outline>
          <span className="align-middle">Excel</span>
        </Button>
      </div>
      <div className="actions-right d-flex flex-wrap mt-sm-0 mt-2">
        <div className="filter-section mr-1">
          <Input type="text" placeholder="검색어를 입력하세요." onChange={e => props.handleFilter(e)} />
        </div>
        <UncontrolledDropdown className="data-list-rows-dropdown d-md-block d-none">
          <DropdownToggle color="" className="sort-dropdown">
            <span className="align-middle mx-50">
              {`${props.index[0]} - ${props.index[1]} of ${props.total}`}
            </span>
            <ChevronDown size={15} />
          </DropdownToggle>
          <DropdownMenu tag="div" right>
            <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(10)}>
              10
            </DropdownItem>
            <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(20)}>
              20
            </DropdownItem>
            <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(props.total)}>
              모두보기
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  )
}

class DataListConfig extends React.Component{
  static getDerivedStateFromProps(props, state) {
    if (
      props.goods.displayList.length !== state.data.length ||
      state.currentPage !== props.parsedFilter.page
    ) {
      return {
        data: props.goods.displayList,
        allData: props.goods.filteredData,
        totalPages: props.goods.totalPages,
        currentPage: parseInt(props.parsedFilter.page) - 1,
        rowsPerPage: parseInt(props.parsedFilter.perPage),
        totalRecords: props.goods.totalRecords,
        sortIndex: props.goods.sortIndex
      }
    }

    // Return null if the state hasn't changed
    return null
  }

  state = {
    apiLoaded: false,
    data: [],
    allData: [],
    totalPages: 0,
    currentPage: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    totalRecords: 0,
    sortIndex: [],
    columns: [
      {
        name: "No",
        selector: "rowNum",
        sortable: true,
        width: "60px",
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.rowNum}</span>
        )
      },
      {
        name: "품명",
        // selector: "itemListName",
        selector: "itemName",
        sortable: true,
        // width: "300px",
        center: true,
      },
      {
        name: "구분",
        selector: "carType",
        sortable: true,
        center: true,
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.carType}</span>
        )
      },
      {
        name: "설명",
        selector: "itemDesc",
        sortable: true,
        center: true,
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.itemDesc}</span>
        )
      },
      {
        name: "수정날짜",
        selector: "updatedAt",
        sortable: true,
        width: "150px",
        center: true,
        cell: row => (
          <span className={'width-100-per text-align-center row-font-size'}>{row.updatedAt}</span>
        )
      },
      {
        name: "사용",
        selector: "enabled",
        sortable: false,
        width: "70px",
        cell: row => (
          <CustomInput
            type="switch"
            id={`switch-${row.seq}`}
            name="customSwitch"
            inline
            checked={row.enabled}
            onChange={this.handleChangeEnabled(row)}
          />
        )
      },
      {
        name: "수정",
        selector: "enabled",
        sortable: false,
        width: "100px",
        center: true,
        cell: row => (
          <Button.Ripple
            size="sm"
            color="primary"
            onClick={() => this.handleCurrentData(row)}
          >
            수정
          </Button.Ripple>
        )
      },
      {
        name: '삭제',
        sortable: false,
        width: "70px",
        center: true,
        cell: row => (
          <Trash
            className="cursor-pointer"
            size={24}
            onClick={() => {
              this.handleDelete(row)
            }}
          />
        )
      }
    ],
    value: '',
    isShow: {
      memo: false,
      addNew: false,
    },
    currentData: null,
    selected: [],
  }

  async componentDidMount() {
    await this.props.fetchGoodsList(this.props.parsedFilter)
    await this.handleRowsPerPage(this.state.totalRecords)
    this.setState({ apiLoaded: true })
    this.changeButtonSize()
    this.changeRowSize()
  }

  componentDidUpdate() {
    this.changeButtonSize()
    this.changeRowSize()
  }

  changeButtonSize = () => {
    const $els = document.querySelectorAll('button.btn.btn-sm')
    $els.forEach($el => $el.setAttribute("style", "padding: 0.5rem 1rem;"));
  }

  changeRowSize = () => {
    const $els = document.querySelectorAll('[role="row"]')
    $els.forEach($el => $el.setAttribute("style", "min-height: 30px;"));
    this.changeHeaderFontSize()
  }

  changeHeaderFontSize = () => {
    const $els = document.querySelectorAll('.rdt_TableCol_Sortable')
    $els.forEach($el => $el.setAttribute("style", "font-size: 12px;"));
  }

  handleDelete = async obj => {
    if (window.confirm('삭제 하겠습니까?')) {
      await this.props.deleteGoods(obj.seq)
      await this.props.fetchGoodsList(this.props.parsedFilter)
      this.props.filterList(this.state.value)
    }
  }

  handleCurrentData = obj => {
    this.setState({ currentData: obj })
    this.handleMemoSidebar(true)
  }

  handleFilter = e => {
    this.setState({ value: e.target.value })
    this.props.filterList(e.target.value)
  }

  handleRowsPerPage = value => {
    let { parsedFilter, changeListOption } = this.props
    // let page = parsedFilter.page !== undefined ? parsedFilter.page : 1
    // history.replace(`/goods/list?page=${page}&perPage=${value}`)
    history.replace(`/goods/list?page=1&perPage=${value}`)
    this.setState({ rowsPerPage: value, currentPage: 0 })
    changeListOption({ page: 1, perPage: value })
  }

  handlePagination = page => {
    let { parsedFilter, changeListOption } = this.props
    let perPage = parsedFilter.perPage !== undefined ? parsedFilter.perPage : DEFAULT_ROWS_PER_PAGE
    history.replace(`/goods/list?page=${page.selected + 1}&perPage=${perPage}`)
    changeListOption({ page: page.selected + 1, perPage: perPage })
    this.setState({ currentPage: page.selected })
  }

  handleChangeStatus = (row) => {
    const { seq, enabled } = row
    if (window.confirm(enabled ? '비활성 하시겠습니까?' : '활성화 하시겠습니까?')) {
      this.props.changeStatusGoods({ seq, enabled: !enabled })
    }
  }

  updateGoods = async (row, { goodsType, goodsName, goodsDesc, carType }) => {
    const { seq: goodsSeq } = row
    await this.props.updateGoods({ goodsSeq, goodsName, goodsDesc, carType })
    await this.props.fetchGoodsList(this.props.parsedFilter)
    this.props.filterList(this.state.value)
    this.handleMemoSidebar(false)
  }

  handleHideSidebar = (boolean) => {
    this.setState({ isShow: { addNew: boolean, memo: boolean } })
  }

  handleMemoSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, memo: boolean } })
  }

  handleAddNewSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, addNew: boolean }, apiLoaded: true })
  }

  createGoods = async ({ goodsType, goodsName, goodsDesc, carType }) => {
    await this.props.addNewGoods({ goodsName, goodsDesc: goodsDesc || ' ', carType })
    // await this.props.fetchGoodsList(this.props.parsedFilter)
    // await this.props.fetchGoodsList({page: 1, perPage: this.props.carArea.displayList.length+1})
    this.handleAddNewSidebar(false)
  }

  handleChangeEnabled = (row) => async (event) => {
    const ele = event.target
    const enabled = ele.checked
    try {
      await this.props.changeEnabled(row.seq, enabled)
      this.props.filterList(this.state.value)
    } catch (e) {
      ele.checked = !enabled
    }
  }

  handleExportExcel = async () => {
    const href = await this.props.exportGoodsExcel()
    const link = document.createElement('a')
    link.href = href
    document.body.appendChild(link)
    link.click()
  }

  render(){
    let {
      apiLoaded, columns, data, allData, isShow, currentData, rowsPerPage,
      totalRecords, sortIndex, totalPages, value
    } = this.state

    // console.log({allData, data})
    const dataList = (value.length ? allData : data).map((d, idx) => ({...d, rowNum: idx+1}))

    return !apiLoaded ? (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    ) : (
      <div className="list-grid-style-small m-2">
        <CustomHeader
          handleSidebar={this.handleAddNewSidebar}
          handleFilter={this.handleFilter}
          handleRowsPerPage={this.handleRowsPerPage}
          handleAddNewSidebar={this.handleAddNewSidebar}
          handleExportExcel={this.handleExportExcel}
          rowsPerPage={rowsPerPage}
          total={totalRecords}
          index={sortIndex}
        />
        <DataTable
          columns={columns}
          // data={value.length ? allData : data}
          data={dataList}
          noHeader
          //selectableRows
          responsive
          //pointerOnHover
          selectableRowsHighlight
          onSelectedRowsChange={data =>
            this.setState({ selected: data.selectedRows })
          }
          customStyles={selectedStyle}
          sortIcon={<ChevronDown />}
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={{
            color: "primary",
            icon: <Check className="vx-icon" size={12} />,
            label: "",
            size: "sm"
          }}
          noDataComponent={<div style={{padding: '24px'}}>데이터가 없습니다.</div>}
        />
        <ReactPaginate
          previousLabel={<ChevronLeft size={15} />}
          nextLabel={<ChevronRight size={15} />}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={totalPages}
          containerClassName="vx-pagination separated-pagination pagination-center pagination-sm mb-0 mt-2 list-style-pagination"
          activeClassName="active"
          forcePage={
            this.props.parsedFilter.page
              ? parseInt(this.props.parsedFilter.page - 1)
              : 0
          }
          onPageChange={page => this.handlePagination(page)}
        />
        <div className="data-list list-view">
          { isShow.memo && ( // 수정
            <EditSidebar
              data={currentData}
              handleSidebar={this.handleMemoSidebar}
              updateGoods={this.updateGoods}
            />
          )}
          { isShow.addNew && (
            <AddNewSidebar
              handleSidebar={this.handleAddNewSidebar}
              createGoods={this.createGoods}
            />
          )}
          <div
            className={classnames("data-list-overlay", {
              show: isShow.memo || isShow.addNew
            })}
            onClick={() => this.handleHideSidebar(false)}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    goods: state.goods
  }),
  {
    fetchGoodsList,
    changeStatusGoods,
    updateGoods,
    addNewGoods,
    deleteGoods,
    filterList,
    changeListOption,
    changeEnabled,
    exportGoodsExcel,
  },
)(DataListConfig)

DataListConfig.propTypes = {
  parsedFilter: PropTypes.object
}
