import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import { X } from "react-feather"
import { Label, Input, FormGroup, Button } from "reactstrap"
import Select from 'react-select'

import { SELECT_OPTIONS } from './DataListConfig'

export const CAR_TYPE_LIST = [
  { value: 'COUNT', label: '횟수' },
  { value: 'RUBE', label: '루베' },
]

class AddNewSidebar extends Component {

  state = {
    show: false,
    goodsType: SELECT_OPTIONS[0].value,
    goodsName: '',
    goodsDesc: ' ',
    carType: CAR_TYPE_LIST[0].value,
  }

  componentDidMount() {
    this.setState({ show: true })
  }

  render() {
    let { show, goodsType, goodsName, goodsDesc, carType } = this.state
    let { handleSidebar, createGoods } = this.props
    return (
      <div
        style={{height: `${window.innerHeight}px`}}
        className={classnames("data-list-sidebar", { show })}>
        <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
          <h4>등록</h4>
          <X className="cursor-pointer" size={20} onClick={() => handleSidebar(false)} />
        </div>
        <PerfectScrollbar
          className="data-list-fields px-2 mt-3"
          options={{ wheelPropagation: false }}
        >
          {/*<FormGroup>*/}
          {/*  <Label for="data-goodsType">품목구분(청구단위)</Label>*/}
          {/*  <Select*/}
          {/*    className="React"*/}
          {/*    classNamePrefix="select"*/}
          {/*    name="goodsType"*/}
          {/*    options={SELECT_OPTIONS}*/}
          {/*    defaultValue={SELECT_OPTIONS.find(o => o.value === goodsType)}*/}
          {/*    onChange={(value) => this.setState({ goodsType: value.value })}*/}
          {/*    id="data-goodsType"*/}
          {/*  />*/}
          {/*</FormGroup>*/}
          <FormGroup>
            <Label for="data-goodsName">품명 <span className={'required-mark'}>*</span></Label>
            <Input
              type="text"
              value={goodsName}
              placeholder="품명"
              onChange={e => this.setState({ goodsName: e.target.value })}
              id="data-goodsName"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-carType">구분 <span className={'required-mark'}>*</span></Label>
            <Select
              className="React"
              classNamePrefix="select"
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}  // important
              name="clear"
              placeholder={'선택'}
              options={CAR_TYPE_LIST}
              defaultValue={CAR_TYPE_LIST.find(c => c.value === carType)}
              onChange={(value) => {
                this.setState({
                  carType: value?.value,
                })
              }}
              id="data-carType"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-goodsDesc">설명</Label>
            <Input
              type="text"
              value={goodsDesc}
              placeholder="설명"
              onChange={e => this.setState({ goodsDesc: e.target.value })}
              id="data-goodsDesc"
            />
          </FormGroup>
        </PerfectScrollbar>
        <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
          <Button color="primary" onClick={() => createGoods(this.state)}>
            등록
          </Button>
          <Button
            className="ml-1"
            color="danger"
            outline
            onClick={() => handleSidebar(false)}
          >
            취소
          </Button>
        </div>
      </div>
    )
  }
}

export default AddNewSidebar

AddNewSidebar.propTypes = {
  handleSidebar: PropTypes.func,
  createGoods: PropTypes.func,
}
