import React, { Component } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import { X } from "react-feather"
import { Label, Input, FormGroup, Button } from "reactstrap"
import Select from "react-select"

class AddNewSidebar extends Component {

  state = {
    show: false,
    accountId: undefined,
    client: '',
    downPoint: undefined,
    upPoint: undefined,
    ton: '',
    volume: '',
    products: '',
    carNumberList: this.props.carList.map(item => ({...item, value: item.accountId, label: item.accountId })),
    areaList: this.props.upDownPointList.map(item => ({...item, value: item.seq, label: item.shortName })),
  }

  componentDidMount() {
    this.setState({ show: true })
  }

  render() {
    const { show, accountId, client, downPoint, products, ton, upPoint, volume, carNumberList, areaList } = this.state
    const { handleSidebar, createCarDispatching } = this.props

    return (
      <div
        className={classnames("data-list-sidebar", { show })}>
        <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
          <h4>등록</h4>
          <X className="cursor-pointer" size={20} onClick={() => handleSidebar(false)} />
        </div>
        <PerfectScrollbar
          className="data-list-fields px-2 mt-3"
          options={{ wheelPropagation: false }}
        >
          <FormGroup>
            <Label for="data-accountId">차량번호</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={carNumberList}
              isClearable={true}
              defaultValue={carNumberList.find(c => c.accountId === accountId)}
              onChange={(value) => this.setState({ accountId: value?.accountId })}
              id="data-accountId"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-client">거래처명</Label>
            <Input
              type="text"
              value={client}
              placeholder="거래처명"
              onChange={e => this.setState({ client: e.target.value })}
              id="data-client"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-upPoint">상차지</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={areaList}
              isClearable={true}
              defaultValue={areaList.find(a => a.seq === upPoint)}
              onChange={(value) => this.setState({ upPoint: value?.seq })}
              id="data-upPoint"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-downPoint">하차지</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={areaList}
              isClearable={true}
              defaultValue={areaList.find(a => a.seq === downPoint)}
              onChange={(value) => this.setState({ downPoint: value?.seq })}
              id="data-downPoint"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-ton">톤수</Label>
            <Input
              type="text"
              value={ton}
              placeholder="톤수"
              onChange={e => this.setState({ ton: e.target.value })}
              id="data-ton"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-volume">누배</Label>
            <Input
              type="text"
              value={volume}
              placeholder="누배"
              onChange={e => this.setState({ volume: e.target.value })}
              id="data-volume"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-products">운송물품</Label>
            <Input
              type="text"
              value={products}
              placeholder="운송물품"
              onChange={e => this.setState({ products: e.target.value })}
              id="data-products"
            />
          </FormGroup>
        </PerfectScrollbar>
        <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
          <Button color="primary" onClick={() => createCarDispatching({ accountId, client, downPoint, products, ton, upPoint, volume, carNumberList })}>
            등록
          </Button>
          <Button
            className="ml-1"
            color="danger"
            outline
            onClick={() => handleSidebar(false)}
          >
            취소
          </Button>
        </div>
      </div>
    )
  }
}

export default AddNewSidebar

AddNewSidebar.propTypes = {
  handleSidebar: PropTypes.func,
  createCarDispatching: PropTypes.func,
  upDownPointList: PropTypes.array,
  carList: PropTypes.array,
}
