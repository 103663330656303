import React, {useEffect, useState} from "react"
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import DataTable from "react-data-table-component"
import classnames from "classnames"
import moment from 'moment'
import { history } from '@/history'
import ReactPaginate from "react-paginate"
import {
  Input,
  Button,
  Spinner, Badge, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown,
} from "reactstrap"
import {
  ChevronDown,
  Check,
  ChevronLeft,
  ChevronRight,
  Search,
} from "react-feather"
import Select from 'react-select'
import Flatpickr from "react-flatpickr"
import Checkbox from "@/components/@vuexy/checkbox/CheckboxesVuexy"
import EditSidebar from './EditSidebar'
import AddNewSidebar from './AddNewSidebar'

import { getStatusColor } from '@/libs/statusColor'

import _ from "fxjs/Strict";
import L from "fxjs/Lazy";

import {
  fetchCarDispatchingList,
  fetchPeriodCarDispatchingList,
  updateCarDispatching,
  addNewCarDispatching,
  filterList,
  changeListOption,
  resetList,
  RemoveCarDispatching,
  updateCarDispatchingDone,
  exportExcelData,
} from '@/redux/actions/carDispatching'
import { fetchCarArea } from '@/redux/actions/carArea'
import { fetchCustomerList, getCustomerList } from '@/redux/actions/customer'
import { fetchGoodsList } from '@/redux/actions/drivingFees'

import "@/assets/scss/plugins/extensions/react-paginate.scss"
import "flatpickr/dist/themes/light.css";
import "@/assets/scss/plugins/forms/flatpickr/flatpickr.scss"
import '@/assets/scss/pages/data-list.scss'

export const DEFAULT_ROWS_PER_PAGE = 10

const selectedStyle = {
  rows: {
    selectedHighlighStyle: {
      backgroundColor: "rgba(115,103,240,.05)",
      color: "#7367F0 !important",
      boxShadow: "0 0 1px 0 #7367F0 !important",
      "&:hover": {
        transform: "translateY(0px) !important"
      }
    }
  }
}
export const TYPE_KEY = {
  carNumber: 'CAR_NUMBER',
  upPoint: 'UP_POINT',
  downPoint: 'DOWN_POINT',
  period: 'PERIOD',
}
const headerSearchOptions = [
  { value: TYPE_KEY.period, label: '날짜'},
  { value: TYPE_KEY.carNumber, label: '차량번호' },
  { value: TYPE_KEY.upPoint, label: '상차지' },
  { value: TYPE_KEY.downPoint, label: '하차지' },
]

const DropDownComponent = (props) => (
  <UncontrolledDropdown className="data-list-rows-dropdown d-md-block d-none">
    <DropdownToggle color="" className="sort-dropdown">
      <span className="align-middle mx-50">
        {`${props.index[0]} - ${props.index[1]} of ${props.total}`}
      </span>
      <ChevronDown size={15} />
    </DropdownToggle>
    <DropdownMenu tag="div" right>
      <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(10)}>
        10
      </DropdownItem>
      <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(20)}>
        20
      </DropdownItem>
      <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(props.total, true)}>
        모두보기
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
)

const CustomHeader = (props) => {
  const [type, setType] = useState(headerSearchOptions[0])
  const [value, setValue] = useState(props.value)
  const [input, setInput] = useState('')
  const handleKeyDown = (e) => {
    if (e.keyCode === /*엔터키*/13) {
      props.handleSearchData(input)
    }
  }

  const onChangeSearchList = (text) => {
    setInput(text)
    props.handleSearchData(text)
  }

  useEffect(() => {
    setInput(props.input)
    // setValue()
  }, [])

  return (
    <div className="data-list-header d-flex justify-content-between flex-wrap mb-1">
      <div className="actions-left d-flex flex-wrap">
        <div className={'ml-1'}>
          <Button
            className="add-new-btn"
            color="primary"
            onClick={() => props.handleModifyData()}
            outline>
            <span className="align-middle">수정</span>
          </Button>
        </div>
        <div className={'ml-1'}>
          <Button
            className="add-new-btn"
            color="primary"
            onClick={() => props.handleCancelDispatch()}
            outline>
            <span className="align-middle">삭제</span>
          </Button>
        </div>
        <div className={'ml-1'}>
          <Button
            className="add-new-btn"
            color="primary"
            onClick={() => props.handleExportExcel()}
            outline>
            <span className="align-middle">엑셀 다운</span>
          </Button>
        </div>
      </div>
      <div className="actions-right d-flex flex-wrap mt-sm-0 mt-2">
        <div className="position-relative has-icon-left">
          <Flatpickr
            className="form-control width-300"
            options={{
              mode: "range",
              defaultDate: value
            }}
            onChange={date => setValue(date.map(d => moment(d).format('YYYY-MM-DD')))}
          />
          <div className="form-control-position">
            <Search size={15} />
          </div>
        </div>
        <Button.Ripple
          className="ml-1"
          color="primary"
          onClick={() => value && props.handleFilter({ type, value })}
        >
          검색
        </Button.Ripple>
        <div className="filter-section ml-2">
          <Input
            type="text"
            value={input}
            placeholder="검색어를 입력하세요."
            // onChange={e => setInput(e.target.value)}
            onChange={e => onChangeSearchList(e.target.value)}
            // onKeyDown={handleKeyDown}
          />
        </div>
        {/*<Button.Ripple*/}
        {/*  className="ml-1"*/}
        {/*  color="primary"*/}
        {/*  onClick={() => props.handleSearchData(input)}*/}
        {/*>*/}
        {/*  검색*/}
        {/*</Button.Ripple>*/}
        {/*<DropDownComponent {...props} />*/}
      </div>
    </div>
  )
}

class DataListConfig extends React.Component{
  static getDerivedStateFromProps(props, state) {
    if (
      props.searchCarDispatching.displayList.length !== state.data.length ||
      state.currentPage !== props.parsedFilter.page
    ) {
      return {
        data: props.searchCarDispatching.displayList,
        allData: props.searchCarDispatching.filteredData,
        totalPages: props.searchCarDispatching.totalPages,
        currentPage: parseInt(props.parsedFilter.page) - 1,
        rowsPerPage: parseInt(props.parsedFilter.perPage),
        totalRecords: props.searchCarDispatching.totalRecords,
        sortIndex: props.searchCarDispatching.sortIndex,
      }
    }

    // Return null if the state hasn't changed
    return null
  }

  tableRef = React.createRef()
  state = {
    maxSize: 300,
    apiLoaded: false,
    data: [],
    filteredData: [],
    input: '',
    allData: [],
    totalPages: 0,
    currentPage: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    totalRecords: 0,
    sortIndex: [],
    columns: [
      {
        name: "No",
        selector: "num",
        width: '60px',
        center: true,
        sortable: true,
        cell: row => (
          <p title={row.num} className="text-align-center text-truncate text-bold-500 mb-0 row-font-size">
            {row.num}
          </p>
        )
      },
      {
        name: "차량번호",
        selector: "target",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.target} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.target}
          </p>
        )
      },
      {
        name: "운행일자",
        selector: "operatingDate",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.operatingDate} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.operatingDate}
          </p>
        )
      },
      {
        name: "출발시간",
        selector: "operatingStartTime",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.operatingStartTime} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.operatingStartTime}
          </p>
        )
      },
      {
        name: "도착시간",
        selector: "operatingEndTime",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.operatingEndTime} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.operatingEndTime}
          </p>
        )
      },
      {
        name: "운행시간",
        selector: "operatingTime",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.operatingTime} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.operatingTime}
          </p>
        )
      },
      {
        name: "상차지",
        selector: "upPointName",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.upPointName} className="text-truncate text-bold-500 mb-0 text-align-center width-100-per row-font-size">
            {row.upPointName}
          </p>
        )
      },
      {
        name: "하차지",
        selector: "downPointName",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.downPointName} className="text-truncate text-bold-500 mb-0 text-align-center width-100-per row-font-size">
            {row.downPointName}
          </p>
        )
      },
      {
        name: "운송물품",
        selector: "itemProductName",
        sortable: true,
        center: true,
        cell: row => (
          <p title={row.itemProductName} className="text-truncate text-bold-500 mb-0 row-font-size">
            {row.itemProductName}
          </p>
        )
      },
      {
        name: "상태",
        selector: "status",
        sortable: true,
        width: '75px',
        center: true,
        // sortable: true,
        cell: row => (
          <p title={row.status} className={classnames("text-truncate text-bold-500 mb-0 row-font-size", { 'returned-cell': row.status === '회차', 'removed-cell': row.status === '삭제' } )}>
            <Badge
              color={`light-${getStatusColor(row.status)}`}
              className="badge-md"
              style={{'fontWeight': '900'}}
            >
              {row.status}
            </Badge>
          </p>
        )
      },
      // {
      //   name: "수정",
      //   sortable: false,
      //   width: "100px",
      //   center: true,
      //   cell: row => (
      //     <Button.Ripple
      //       color="primary"
      //       size="sm"
      //       onClick={() => this.handleCurrentData(row)}
      //     >
      //       수정
      //     </Button.Ripple>
      //   )
      // },
    ],
    value: [],
    isShow: {
      edit: false,
      addNew: false,
    },
    currentData: null,
    selected: [],
    type: {},
    goodsList: [],
    upDownPointList: []
  }

  async componentDidMount() {
    // await this.search({})
    this.props.changeListOption({})
    this.props.resetList()
    this.setState({ apiLoaded: true })
    this.changeTableAttr()
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnMount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    this.changeTableAttr()
  }

  changeTableAttr = () => {
    this.changeRowSize()
    this.addClassShadowRow()
    this.changeButtonSize()
  }

  changeButtonSize = () => {
    const $els = document.querySelectorAll('button.btn.btn-sm')
    $els.forEach($el => $el.setAttribute("style", "padding: 0.5rem 1rem;"));
  }

  changeRowSize = () => {
    // console.log('changeRowSize')
    const $els = document.querySelectorAll('[role="row"]')
    $els.forEach($el => $el.setAttribute("style", "min-height: 30px;"));
    this.changeHeaderFontSize();
  }

  changeHeaderFontSize = () => {
    const $els = document.querySelectorAll('.rdt_TableCol_Sortable')
    $els.forEach($el => $el.setAttribute("style", "font-size: 12px;"));
  }

  addClassShadowRow() {
    Array.from(document.getElementsByClassName('returned-row')).forEach((ele) => {
      ele.classList.remove('returned-row')
    })
    Array.from(document.getElementsByClassName('removed-row')).forEach((ele) => {
      ele.classList.remove('removed-row')
    })
    setTimeout(() => {
      Array.from(document.getElementsByClassName('returned-cell')).forEach((ele) => {
        ele.parentElement.parentElement.classList.add('returned-row')
      })
      Array.from(document.getElementsByClassName('removed-cell')).forEach((ele) => {
        ele.parentElement.parentElement.classList.add('removed-row')
      })
    }, 0)
  }

  search = async ({ type = TYPE_KEY.period, value }) => {
    this.setState({ apiLoaded: false })
    if (type === TYPE_KEY.period) {
      await this.props.fetchPeriodCarDispatchingList({ params: this.props.parsedFilter, period: value })
      await this.handleRowsPerPage(this.state.totalRecords, true)
    } else {
      await this.props.fetchCarDispatchingList({ params: this.props.parsedFilter, data: { type, value } })
      await this.handleRowsPerPage(this.state.totalRecords, true)
    }
    // this.handlePagination({ selected: 0 })
    // this.setState({ apiLoaded: true })
    setTimeout(() => {
      this.changeRowSize()
      this.addClassShadowRow()
    }, 0)
  }

  handleDelete = obj => {
    if (window.confirm('삭제 하겠습니까?')) {
    }
  }

  handleCurrentData = async () => {
    const { selected } = this.state

    if(selected.length === 0) {
      window.alert('수정할 데이터를 선택해주세요')
      return ;
    }

    const data = this.props.searchCarDispatching.displayList
    const selectedSeq = selected.map(s => s.seq)

    const selectedUpdated = _.go(data,
      L.filter(d => selectedSeq.includes(d.seq)),
      _.take(selectedSeq.length))

    const goodsList = await this.props.fetchGoodsList()
    const upDownPointList = await this.props.getCustomerList()
    this.setState({ goodsList, upDownPointList, selected: selectedUpdated })

    this.handleEditSidebar(true)
  }

  handleFilter = ({ type, value }) => {
    this.setState({ type, value, selected: [], filteredData: [], input: '' })
    //this.props.filterList({ type, value })
    this.search({ type: type.value, value })
    // this.changeRowSize()
    // this.addClassShadowRow()
  }

  handleRowsPerPage = (value, isTotal=false) => {
    let { parsedFilter, changeListOption } = this.props
    // history.replace(`/carDispatching/list?page=1&perPage=${value}`)
    changeListOption({ page: 1, perPage: value })
    this.setState({ rowsPerPage: value, currentPage: 0, apiLoaded: true })
  }

  handlePagination = page => { // { selected: 0}
    let { parsedFilter, changeListOption } = this.props
    let perPage = parsedFilter.perPage !== undefined ? parsedFilter.perPage : DEFAULT_ROWS_PER_PAGE
    history.replace(`/carDispatching/list?page=${page.selected + 1}&perPage=${perPage}`)
    changeListOption({ page: page.selected + 1, perPage: perPage })
    this.setState({ currentPage: page.selected, apiLoaded: true })
  }

  updateCarDispatching = async (selected, { upPoint, downPoint, itemProduct }) => {
    if(upPoint === '' || downPoint === '' || itemProduct === '') {
      window.alert('모든 항목을 선택해주세요.')
      return
    }

    const dailySeq = selected.map(s => s.seq)
    const res = await this.props.updateCarDispatchingDone({dailySeq, upPoint, downPoint, itemProduct })
    if(res) {
      window.alert('수정되었습니다.')
      await this.search({ type: TYPE_KEY.period, value: this.state.value })
      this.handleSearchData(this.state.input)
      this.handleEditSidebar(false)
    }
  }

  handleHideSidebar = (boolean) => {
    this.setState({ isShow: { addNew: boolean, edit: boolean } })
  }

  handleEditSidebar = (boolean) => {
    const { isShow } = this.state
    this.setState({ isShow: { ...isShow, edit: boolean } })
  }

  handleAddNewSidebar = async (boolean) => {
    const { isShow } = this.state
    const { upDownPointList, carList } = this.props
    const params = { page: 1, perPage: DEFAULT_ROWS_PER_PAGE }

    if (!upDownPointList.length) await this.props.fetchCustomerList(params)
    if (!carList.length) await this.props.fetchCarArea(params)

    this.setState({ isShow: { ...isShow, addNew: boolean } })
  }

  createCarDispatching = async ({ accountId, client, downPoint, products, ton, upPoint, volume }) => {
    this.setState({ apiLoaded: false })
    await this.props.addNewCarDispatching({ accountId, client, downPoint, products, ton, upPoint, volume })
    await this.search({})
    this.setState({ apiLoaded: true })
    this.handleAddNewSidebar(false)
  }

  handleCancelDispatch = async () => {
    if(window.confirm('삭제하시겠습니까?')) {
      const { selected } = this.state
      const seqList = selected.map(s => s.seq)
      const res = await this.props.RemoveCarDispatching({seqList})
      if(res?.data?.success) {
        window.alert('삭제되었습니다.')
        const { type, value } = this.state
        await this.handleFilter({ type, value })
        // this.changeRowSize()
        // this.addClassShadowRow()
      }
    }

  }

  handleAddMaxLength = () => {
    const MAX_LENGTH = 200
    if(this.state.maxSize > this.state.data.length) return
    this.setState({ maxSize: this.state.maxSize + MAX_LENGTH })
  }

  handleScroll = (e) => {
    if(window.pageYOffset+window.innerHeight > this.tableRef?.current?.scrollHeight) {
      this.handleAddMaxLength()
    }
  }

  handleSearchData = (input='') => {
    const valueLc = input.toLowerCase()
    const filteredData = this.state.data.filter(item => {
      const startsWithCondition =
        (item.upPointName || '').toLowerCase().startsWith(valueLc) ||
        (item.downPointName || '').toLowerCase().startsWith(valueLc) ||
        (item.itemProductName || '').toLowerCase().startsWith(valueLc) ||
        (item.target || '').toLowerCase().startsWith(valueLc)

      const includesCondition =
        (item.upPointName || '').toLowerCase().includes(valueLc) ||
        (item.downPointName || '').toLowerCase().includes(valueLc) ||
        (item.itemProductName || '').toLowerCase().includes(valueLc) ||
        (item.target || '').toLowerCase().includes(valueLc)

      return startsWithCondition || includesCondition
    })
      .map((d, idx) => ({...d, num: idx+1}))
    // console.log({input, filteredData})
    this.setState({ filteredData, input, selected: [] })
  }

  handleExportExcel = async () => {
    const { selected, value, input, data, filteredData } = this.state

    if(value.length === 0) {
      window.alert('검색된 데이터가 없습니다.')
      return ;
    }

    if(window.confirm('엑셀 다운로드 하시겠습니까?')) {
      const dailySeq = selected.length > 0 ? selected.map(s => s.seq) : (
        input === '' ? data.map(s => s.seq) : filteredData.map(s => s.seq)
      )

      const startPeriod = value[0].split('-').reduce((a, b) => `${a}${b}`)
      const endPeriod = value[1].split('-').reduce((a, b) => `${a}${b}`)

      const res = await this.props.exportExcelData({ dailySeq, startPeriod, endPeriod })

      const link = document.createElement('a')
      link.href = res
      document.body.appendChild(link)
      link.click()
    }
  }

  render(){
    let {
      apiLoaded, columns, data, isShow, currentData, totalPages,
      totalRecords, sortIndex, selected, goodsList, upDownPointList,
      maxSize, filteredData, input,
      //allDatarowsPerPage, value
    } = this.state

    // const { carList } = this.props

    // const nData = data
    //   .filter(d => d.status !== '삭제')
    //   .map((d, idx) => ({
    //   ...d,
    //   upPointName: (d.upPointName || ' ').split('_')[0] === '긴급배차' ? '긴급배차_상차지' : d.upPointName,
    //   downPointName: (d.downPointName || ' ').split('_')[0] === '긴급배차' ? '긴급배차_하차지' : d.downPointName,
    //   num: idx+1,
    //   products: (d.upDownInfo?.products)
    // }))

    const dataList = input === '' ? data : filteredData
    const nData = _.go(dataList,
      _.take(maxSize))

    const selectedMap = selected
      .map(s => ({[s.seq]: s}))
      .reduce((a, b) => ({...a, ...b}), {})

    return !apiLoaded ? (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    ) : (
      <div className="list-grid-style-small m-2">
        <CustomHeader
          handleFilter={this.handleFilter}
          handleRowsPerPage={this.handleRowsPerPage}
          total={totalRecords}
          index={sortIndex}
          input={this.state.input}
          value={this.state.value}
          handleCancelDispatch={this.handleCancelDispatch}
          handleSearchData={this.handleSearchData}
          handleModifyData={this.handleCurrentData}
          handleExportExcel={this.handleExportExcel}
        />
        <div
          onClick={this.changeTableAttr}
          ref={this.tableRef}
        >
          <DataTable
            columns={columns}
            //data={value.length ? allData : data}
            data={nData}
            noHeader
            selectableRows
            responsive
            //pointerOnHover
            selectableRowsHighlight
            selectableRowSelected={row => !!selectedMap[row.seq]}
            onSelectedRowsChange={data => {
              this.setState({ selected: data.selectedRows })
            }}
            customStyles={selectedStyle}
            sortIcon={<ChevronDown />}
            selectableRowsComponent={Checkbox}
            selectableRowsComponentProps={{
              color: "primary",
              icon: <Check className="vx-icon" size={12} />,
              label: "",
              size: "sm"
            }}
            noDataComponent={<div style={{padding: '24px'}}>데이터가 없습니다.</div>}
          />
        </div>
        <div className="data-list list-view">
          { isShow.edit && (
            <EditSidebar
              data={selected}
              goodsList={goodsList}
              upDownPointList={upDownPointList}
              handleSidebar={this.handleEditSidebar}
              updateData={this.updateCarDispatching}
            />
          )}
          <div
            className={classnames("data-list-overlay", {
              show: isShow.edit || isShow.addNew
            })}
            onClick={() => this.handleHideSidebar(false)}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    searchCarDispatching: state.searchCarDispatching,
  }),
  {
    fetchCarDispatchingList,
    fetchPeriodCarDispatchingList,
    updateCarDispatching,
    addNewCarDispatching,
    filterList,
    changeListOption,
    fetchCarArea,
    fetchCustomerList,
    resetList,
    RemoveCarDispatching,
    fetchGoodsList,
    getCustomerList,
    updateCarDispatchingDone,
    exportExcelData,
  },
)(DataListConfig)

DataListConfig.propTypes = {
  parsedFilter: PropTypes.object
}
