import axios from 'axios'
import { alertTypes } from '@/redux/reducers/alert'

const APIS = {
  // dev: 'https://dev.sim-play.co.kr',
  dev: 'https://api.sim-play.co.kr',
  prod: 'https://api.sim-play.co.kr',
}
const API = APIS[process.env.REACT_APP_PROFILE]

const _axios = async ({
                  method = 'get', baseURL = API, url = '', data = {},
                  params = {}, headers = {},
                  rootState = {}, isAuth = true,
                  dispatch = () => {}, autoErrorHandle = true,
                }) => {

  const options = { method, baseURL, url, data, params, headers }
  if (isAuth) {
    const token = rootState.auth.login.values.loggedInUser.token
    options.headers.api_key = `bearer ${token}`
  }

  try {
    return await axios(options)
  } catch (e) {
    const { response, config={} } = e
    console.log({config})
    if (autoErrorHandle) {
      dispatch({
        type: alertTypes.SHOW_ALERT,
        payload: {
          type: 'error',
          title: 'Error',
          message: response.data?.error?.message || response.message || `[${e.response.status}] 오류가 발생했습니다.`,
          api: config.url || ''
        },
      })
    }
    throw response
  }
}

export default _axios
