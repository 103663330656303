export const authTypes = {
  // LOGIN_WITH_EMAIL: 'auth/LOGIN_WITH_EMAIL',
  // LOGIN_WITH_FB: 'auth/LOGIN_WITH_FB',
  // LOGIN_WITH_TWITTER: 'auth/LOGIN_WITH_TWITTER',
  // LOGIN_WITH_GOOGLE: 'auth/LOGIN_WITH_GOOGLE',
  // LOGIN_WITH_GITHUB: 'auth/LOGIN_WITH_GITHUB',
  LOGIN_WITH_JWT: 'auth/LOGIN_WITH_JWT',
  // LOGOUT_WITH_JWT: 'auth/LOGOUT_WITH_JWT',
  // LOGOUT_WITH_FIREBASE: 'auth/LOGOUT_WITH_FIREBASE',
  CHANGE_ROLE: 'auth/CHANGE_ROLE',
  LOGOUT: 'auth/LOGOUT',
}

const initialState = {
  userRole: 'EDITOR',
}

export const login = (state = initialState, action) => {
  switch (action.type) {
    // case authTypes.LOGIN_WITH_EMAIL: {
    //   return { ...state, values: action.payload }
    // }
    // case authTypes.LOGIN_WITH_FB: {
    //   return { ...state, values: action.payload }
    // }
    // case authTypes.LOGIN_WITH_TWITTER: {
    //   return { ...state, values: action.payload }
    // }
    // case authTypes.LOGIN_WITH_GOOGLE: {
    //   return { ...state, values: action.payload }
    // }
    // case authTypes.LOGIN_WITH_GITHUB: {
    //   return { ...state, values: action.payload }
    // }
    case authTypes.LOGIN_WITH_JWT: {
      return { ...state, values: action.payload }
    }
    // case authTypes.LOGOUT_WITH_JWT: {
    //   return { ...state, values: action.payload }
    // }
    // case authTypes.LOGOUT_WITH_FIREBASE: {
    //   return { ...state, values: action.payload }
    // }
    case authTypes.CHANGE_ROLE: {
      return { ...state, userRole: action.userRole }
    }
    case authTypes.LOGOUT: {
      return { ...state, values: undefined }
    }
    default: {
      return state
    }
  }
}
