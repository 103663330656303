import produce from 'immer'
import { DEFAULT_ROWS_PER_PAGE } from '@/views/pages/GoodsList/DataListConfig'

export const drivingFeesTypes = {
  FETCH_DRIVING_FEES_LIST: 'drivingFees/FETCH_DRIVING_FEES_LIST',
  CHANGE_LIST_OPTION: 'drivingFees/CHANGE_LIST_OPTION',
  CHANGE_STATUS: 'drivingFees/CHANGE_STATUS',
  UPDATE_DRIVING_FEES: 'drivingFees/UPDATE_DRIVING_FEES',
  FILTER_LIST: 'drivingFees/FILTER_LIST',
}

const initialState = {
  list: [], // [{rowNum, companySeq, upPoint, downPoint, itemProduct, price}, ...]
  displayList: [],
  totalRecords: 0,
  params: null,
  totalPages: 0,

  filteredData: [],
  sortIndex: []
}

const drivingFees = (state = initialState, action) => {
  switch (action.type) {
    case drivingFeesTypes.FETCH_DRIVING_FEES_LIST:
      return produce(state, draftState => {
        const { list, totalRecords, params } = action.payload

        const { page = 1, perPage = totalRecords } = params

        draftState.list = list
        draftState.displayList = list.slice(page - 1, perPage)
        draftState.totalRecords = totalRecords
        draftState.params = params
        draftState.totalPages = Math.ceil(totalRecords / perPage)
      })
    default:
      return state
  }
}

export default drivingFees
