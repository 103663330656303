import React from 'react'
import * as Icon from 'react-feather'

const navigationConfig = [
  {
    id: 'home',
    title: 'Main',
    type: 'item',
    icon: <Icon.Home size={20}/>,
    permissions: ['ADMIN', 'EDITOR'],
    navLink: '/',
  },
  {
    id: 'manage-config',
    title: '관리 설정',
    type: 'collapse', // collapse, item, groupHeader, external-link
    icon: <Icon.Settings size={20}/>,
    //badge: "primary",
    permissions: ['ADMIN'],
    children: [
      {
        id: 'manage-config-company-info',
        title: '회사정보',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        permissions: ['ADMIN'],
        navLink: '/config/companyInfo',
      },
      // {
      //   id: 'manage-config-car',
      //   title: '차량관리',
      //   type: 'item',
      //   icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
      //   permissions: ['ADMIN'],
      //   navLink: '/carArea/list',
      // },
      {
        id: 'manage-config-car-grid',
        title: '차량관리',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        permissions: ['ADMIN'],
        navLink: '/configs/carArea/grid',
      },

      // {
      //   id: 'manage-config-customer',
      //   title: '거래처 관리',
      //   type: 'item',
      //   icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
      //   //badge: "primary",
      //   permissions: ['ADMIN'],
      //   navLink: '/customer/list',
      // },
      {
        id: 'manage-config-customer-grid',
        title: '거래처 관리',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/configs/customer/grid',
      },
      // {
      //   id: 'manage-config-goods',
      //   title: '품목 관리',
      //   type: 'item',
      //   icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
      //   //badge: "primary",
      //   permissions: ['ADMIN'],
      //   navLink: '/goods/list',
      // },
      {
        id: 'manage-config-goods-grid',
        title: '품목 관리',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/configs/goods/grid',
      },
      // {
      //   id: 'manage-config-driving-fees',
      //   title: '운행 단가 관리',
      //   type: 'item',
      //   icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
      //   //badge: "primary",
      //   permissions: ['ADMIN'],
      //   navLink: '/drivingfees/list',
      // },
      {
        id: 'manage-config-driving-fees-grid',
        title: '운행 단가 관리',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/configs/drivingfees/grid',
      },
      {
        id: 'manage-config-import-excel',
        title: '설정 일괄등록',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/configs/import',
      },
    ],
  },
  {
    id: 'CarAreaList',
    title: '배차 이력 조회',
    type: 'collapse',
    icon: <Icon.List size={20}/>,
    //badge: "primary",
    permissions: ['ADMIN'],
    // navLink: '/carDispatching/list',
    children: [
      {
        id: 'CarAreaList-all',
        title: '전체 조회',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        // navLink: '/carDispatching/list',
        navLink: '/carDispatching/grid/all',
      },
      // {
      //   id: 'CarAreaList-daily',
      //   title: '일일 조회',
      //   type: 'item',
      //   icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
      //   //badge: "primary",
      //   permissions: ['ADMIN'],
      //   navLink: '/carDispatching/daily',
      // },
      {
        id: 'CarAreaList-daily-grid',
        title: '일일 조회',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/carDispatching/grid/daily',
      },
      {
        id: 'CarAreaList-total',
        title: '운행이력 합산',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/carDispatching/grid/history/total',
      },
      {
        id: 'CarAreaList-statistics',
        title: '배차 통계',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/carDispatching/statistics',
      },
    ],
  },
  {
    id: 'CarDispatch',
    title: '차량 배차',
    type: 'collapse',
    icon: <Icon.Truck size={20}/>,
    //badge: "primary",
    permissions: ['ADMIN'],
    // navLink: '/carDispatching/list',
    children: [
      {
        id: 'DmGroup',
        title: '배차 등록',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/dmGroup/list',
      },
      {
        id: 'DmGroup2',
        title: '배차 등록 2',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/dmGroup/grid/list',
      },
      {
        id: 'DmGroupReservation',
        title: '배차 예약',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/dmGroup/grid/reservation',
      },
      {
        id: 'DmGroupBaechaMemo',
        title: '배차 메모',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/dmGroup/grid/memo',
      },
      {
        id: 'Status',
        title: '배차 수량',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/status',
      },
      {
        id: 'DmGroup-Express',
        title: '용차 배차 등록',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/express/list',
      },
    ],
  },
  {
    id: 'GpsLocation',
    title: '차량 관제',
    type: 'collapse',
    icon: <Icon.Map size={20}/>,
    //badge: "primary",
    permissions: ['ADMIN'],
    // navLink: '/gps/location',
    children: [
      {
        id: 'GPS-all',
        title: '전체 조회',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/gps/location',
      },
      {
        id: 'GPS-target',
        title: '개별 조회',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/gps/target',
      },
      {
        id: 'GPS-tracking',
        title: '궤적 조회',
        type: 'item',
        icon: <Icon.Circle size={14} style={{ marginLeft: 20 }}/>,
        //badge: "primary",
        permissions: ['ADMIN'],
        navLink: '/gps/tracking',
      },
    ],
  },
  // {
  //   id: 'CallHistory',
  //   title: '호출 내역',
  //   type: 'item',
  //   icon: <Icon.PhoneCall size={20}/>,
  //   //badge: "primary",
  //   permissions: ['ADMIN'],
  //   navLink: '/callHistory/list',
  // },
  {
    id: 'CallHistory-grid',
    title: '호출 내역',
    type: 'item',
    icon: <Icon.PhoneCall size={20}/>,
    //badge: "primary",
    permissions: ['ADMIN'],
    navLink: '/callHistory/grid',
  },
  {
    id: 'AttendanceStatus',
    title: '근태 관리',
    type: 'item',
    icon: <Icon.User size={20}/>,
    //badge: "primary",
    permissions: ['ADMIN'],
    navLink: '/attendanceStatus/list',
  },

  // TODO SUPER
  {
    id: 'Super-Home',
    title: 'Home',
    type: 'item',
    icon: <Icon.CheckSquare size={20}/>,
    //badge: "primary",
    permissions: ['SUPER'],
    navLink: '/super/home',
  },
  // {
  //   id: 'Super-Authority',
  //   title: '사용권한 관리',
  //   type: 'item',
  //   icon: <Icon.CheckSquare size={20}/>,
  //   //badge: "primary",
  //   permissions: ['SUPER'],
  //   navLink: '/super/authority',
  // },
  {
    id: 'Super-Company-Add',
    title: '협력사 등록',
    type: 'item',
    icon: <Icon.CheckSquare size={20}/>,
    //badge: "primary",
    permissions: ['SUPER'],
    navLink: '/super/company/add',
  },
  {
    id: 'Super-Company',
    title: '협력사 관리',
    type: 'item',
    icon: <Icon.CheckSquare size={20}/>,
    //badge: "primary",
    permissions: ['SUPER'],
    navLink: '/super/company/list',
  },
  {
    id: 'Super-Admin-add',
    title: '관리자 등록',
    type: 'item',
    icon: <Icon.CheckSquare size={20}/>,
    //badge: "primary",
    permissions: ['SUPER'],
    navLink: '/super/admin/add',
  },
  // {
  //   id: 'Super-User',
  //   title: '계정 관리',
  //   type: 'item',
  //   icon: <Icon.CheckSquare size={20}/>,
  //   //badge: "primary",
  //   permissions: ['SUPER'],
  //   navLink: '/super/user',
  // },
]

export default navigationConfig
