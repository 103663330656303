import React, { Component } from "react"
import PropTypes from "prop-types"
import { Input, Button, FormGroup, Label } from 'reactstrap'
import { X } from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import classnames from "classnames"
import Select from "react-select";

class EditSidebar extends Component {

  state = {
    show: false,
    //accountId: this.props.data.target,
    downPoint: this.props.data.length === 1 ? this.props.data[0].downPoint : '',
    upPoint: this.props.data.length === 1 ? this.props.data[0].upPoint : '',
    products: this.props.data.length === 1 ? this.props.data[0].itemProductName : '',
    itemProduct: this.props.data.length === 1 ? this.props.data[0].itemProduct : '', // seq
    goodsList: this.props.goodsList.map(item => ({...item, value: item.seq, label: item.itemName })),
    upDownPointList: this.props.upDownPointList.map(item => ({...item, value: item.seq, label: item.shortName })),
  }

    componentDidMount() {
    this.setState({ show: true })
  }

  handleSubmit = () => {
    const { data, updateData } = this.props
    updateData(data, this.state)
  }

  render() {
    let { show, downPoint, products, upPoint, goodsList, upDownPointList } = this.state
    let { handleSidebar } = this.props

    return (
      <div
        className={classnames("data-list-sidebar", { show })}>
        <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
          <h4>상/하차지, 물품 수정</h4>
          <X className="cursor-pointer" size={20} onClick={() => handleSidebar(false)} />
        </div>
        <PerfectScrollbar
          className="data-list-fields px-2 mt-3"
          options={{ wheelPropagation: true }}
        >
          {/*<FormGroup>*/}
          {/*  <Label for="data-accountId">차량번호</Label>*/}
          {/*  <Input*/}
          {/*    type="text"*/}
          {/*    value={target}*/}
          {/*    placeholder="차량번호"*/}
          {/*    //onChange={e => this.setState({ accountId: e.target.value })}*/}
          {/*    id="data-accountId"*/}
          {/*    //readOnly*/}
          {/*    disabled*/}
          {/*  />*/}
          {/*</FormGroup>*/}
          <FormGroup>
            <Label for="data-upPoint">상차지</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={upDownPointList}
              defaultValue={upDownPointList.find(c => c.seq === upPoint)}
              onChange={(option) => this.setState({ upPoint: option?.value })}
              id="data-upPoint"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-downPoint">하차지</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={upDownPointList}
              defaultValue={upDownPointList.find(c => c.seq === downPoint)}
              onChange={(option) => this.setState({ downPoint: option?.value })}
              id="data-downPoint"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-products">운송물품</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={goodsList}
              defaultValue={goodsList.find(c => c.itemName === products)}
              onChange={(value) => this.setState({ products: value?.itemName, itemProduct: value?.seq })}
              id="data-products"
            />
          </FormGroup>
        </PerfectScrollbar>
        <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
          <Button color="primary" onClick={() => this.handleSubmit(this.state)}>
            등록
          </Button>
          <Button
            className="ml-1"
            color="danger"
            outline
            onClick={() => handleSidebar(false)}
          >
            취소
          </Button>
        </div>
      </div>
    )
  }
}

export default EditSidebar

EditSidebar.propTypes = {
  updateData: PropTypes.func,
  handleSidebar: PropTypes.func,
}
