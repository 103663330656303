import React, { Component } from "react"
import PropTypes from "prop-types"
import { Input, Button, FormGroup, Label } from 'reactstrap'
import {Check, X} from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import classnames from "classnames"
import Select from 'react-select'
import { CAR_TYPE2_LIST, CAR_TYPE_LIST } from './AddNewSidebar'

import { addPhoneHypen } from '@/libs/phoneLib'

class EditCarSidebar extends Component {

  state = {
    show: false,
    name: this.props.data.name,
    phone: addPhoneHypen(this.props.data.phone || ''),
    target: this.props.data.accountId,
    ton: this.props.data.ton,
    volume: this.props.data.volume,
    affiliation: this.props.data.affiliation,
    carType: this.props.data.carType || undefined,
    carType2: this.props.data.carType2 || undefined,
    carYear: this.props.data.carYear || undefined,
    carBrand: this.props.data.carBrand || '',
    loginId: this.props.data.loginId,
    // password: this.props.data.password,
    pwdChecked: false,
    password: '',
  }

  componentDidMount() {
    this.setState({ show: true })
  }

  handleSubmit = () => {
    const { data, updateEditCar } = this.props
    const { pwdChecked, password } = this.state
    if(pwdChecked && (!password.trim() || password.trim().length < 4)) {
      window.alert('새로운 비밀번호는 4자리 이상 입력해야 합니다.')
      return
    }
    updateEditCar(data, this.state)
  }

  render() {
    const { show, name, phone, target, ton, volume, carType, carType2, carYear, affiliation, loginId, password, pwdChecked, carBrand } = this.state
    const { handleSidebar } = this.props
    return (
      <div
        style={{height: `${window.innerHeight}px`}}
        className={classnames("data-list-sidebar", { show })}>
        <div className="data-list-sidebar-header mt-2 px-2 d-flex justify-content-between">
          <h4>수정</h4>
          <X className="cursor-pointer" size={20} onClick={() => handleSidebar(false)} />
        </div>
        <PerfectScrollbar
          className="data-list-fields px-2 mt-3"
          options={{ wheelPropagation: false }}
        >
          <FormGroup>
            <Label for="data-principal">아이디</Label>
            <Input
              type="text"
              value={loginId}
              placeholder="아이디"
              onChange={e => this.setState({ loginId: e.target.value })}
              id="data-login-id"
              disabled
            />
          </FormGroup>
          {/*<FormGroup>*/}
          {/*  <Label for="data-principal">비밀번호</Label>*/}
          {/*  <Input*/}
          {/*    type="text"*/}
          {/*    value={password}*/}
          {/*    placeholder="비밀번호"*/}
          {/*    onChange={e => this.setState({ password: e.target.value })}*/}
          {/*    id="data-password"*/}
          {/*  />*/}
          {/*</FormGroup>*/}
          <FormGroup>
            <Label for="data-principal">차량번호</Label>
            <Input
              type="text"
              value={target}
              placeholder="차량번호"
              onChange={e => this.setState({ target: e.target.value })}
              id="data-principal"
              disabled
            />
          </FormGroup>
          <FormGroup>
            {/*<Label for="data-new-password">비밀번호</Label>*/}
            <FormGroup check>
              <Input type="checkbox" name="check" id="data-new-password" checked={pwdChecked} onChange={() => this.setState({ pwdChecked: !pwdChecked })}/>
              <Label for="data-new-password" check>비밀번호 변경</Label>
            </FormGroup>
            <Input
              type="text"
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
              id="data-new-password-input"
              disabled={!pwdChecked}
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-affiliation">소속</Label>
            <Input
              type="text"
              value={affiliation}
              placeholder="소속"
              onChange={e => this.setState({ affiliation: e.target.value })}
              id="data-affiliation"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-name">이름</Label>
            <Input
              type="text"
              value={name}
              placeholder="이름"
              onChange={e => this.setState({ name: e.target.value })}
              id="data-name"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-phone">연락처</Label>
            <Input
              type="text"
              value={phone}
              placeholder="연락처"
              onChange={e => this.setState({ phone: addPhoneHypen(e.target.value) })}
              id="data-phone"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-carType">차량구분</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={CAR_TYPE2_LIST}
              //isClearable={true}
              defaultValue={CAR_TYPE2_LIST.find(c => c.value === carType2)}
              onChange={(value) => this.setState({ carType2: value?.value })}
              id="data-carType"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-carType">운송구분</Label>
            <Select
              className="React"
              classNamePrefix="select"
              name="clear"
              placeholder={'선택'}
              options={CAR_TYPE_LIST}
              //isClearable={true}
              defaultValue={CAR_TYPE_LIST.find(c => c.value === carType)}
              onChange={(value) => this.setState({ carType: value?.value })}
              id="data-carType"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-ton">톤수</Label>
            <Input
              type="text"
              value={ton}
              placeholder="톤수"
              onChange={e => this.setState({ ton: e.target.value })}
              id="data-ton"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-volume">용적(루베)</Label>
            <Input
              type="text"
              value={volume}
              placeholder="용적"
              onChange={e => this.setState({ volume: e.target.value })}
              id="data-volume"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-carYear">차량연식</Label>
            <Input
              type="text"
              value={carYear}
              placeholder="차량연식"
              onChange={e => this.setState({ carYear: e.target.value })}
              id="data-carYear"
            />
          </FormGroup>
          <FormGroup>
            <Label for="data-car-brand">모델</Label>
            <Input
              type="text"
              value={carBrand}
              placeholder="모델"
              onChange={e => this.setState({ carBrand: e.target.value })}
              id="data-car-brand"
            />
          </FormGroup>
        </PerfectScrollbar>
        <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
          <Button color="primary" onClick={() => this.handleSubmit(this.state)}>
            수정
          </Button>
          <Button
            className="ml-1"
            color="danger"
            outline
            onClick={() => handleSidebar(false)}
          >
            취소
          </Button>
        </div>
      </div>
    )
  }
}

export default EditCarSidebar

EditCarSidebar.propTypes = {
  data: PropTypes.object,
  updateEditCar: PropTypes.func,
  handleSidebar: PropTypes.func,
}
